.o-scrollbar{
	max-height: 200px;
}
/* crit:start */
.scroll-wrapper {
    overflow: hidden !important;
    padding: 0 !important;
    position: relative;
}
 
.scroll-wrapper > .scroll-content {
    border: none !important;
    box-sizing: content-box !important;
    height: auto;
    left: 0;
    margin: 0;
    max-height: none;
    max-width: none !important;
    overflow: scroll !important;
    padding: 0;
    position: relative !important;
    top: 0;
    padding-right: 10px;
    width: auto !important;
}
 
.scroll-wrapper > .scroll-content::-webkit-scrollbar {
    height: 0;
    width: 0;
}
 
.scroll-element {
    display: none;
}
.scroll-element, .scroll-element div {
    box-sizing: content-box;
}
 
.scroll-element.scroll-x.scroll-scrollx_visible,
.scroll-element.scroll-y.scroll-scrolly_visible {
    display: block;
}
 
.scroll-element .scroll-bar,
.scroll-element .scroll-arrow {
    cursor: default;
}
 
.scroll-textarea {
    border: 1px solid #cccccc;
    border-top-color: #999999;
}
.scroll-textarea > .scroll-content {
    overflow: hidden !important;
}
.scroll-textarea > .scroll-content > textarea {
    border: none !important;
    box-sizing: border-box;
    height: 100% !important;
    margin: 0;
    max-height: none !important;
    max-width: none !important;
    overflow: scroll !important;
    outline: none;
    padding: 2px;
    position: relative !important;
    top: 0;
    width: 100% !important;
}
.scroll-textarea > .scroll-content > textarea::-webkit-scrollbar {
    height: 0;
    width: 0;
}
 
 
 
 
/*************** SIMPLE OUTER SCROLLBAR ***************/
 
.o-scrollbar > .scroll-element,
.o-scrollbar > .scroll-element div
{
    border: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 1;
}
 
.o-scrollbar > .scroll-element {
    background-color: #ffffff;
}
 
.o-scrollbar > .scroll-element div {
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
}
 
.o-scrollbar > .scroll-element.scroll-x {
    bottom: 0;
    height: 12px;
    left: 0;
    width: 100%;
}
 
.o-scrollbar > .scroll-element.scroll-y {
    height: 100%;
    right: 0;
    top: 0;
    width: 12px;
}
 
.o-scrollbar > .scroll-element.scroll-x .scroll-element_outer { height: 8px; top: 2px; }
.o-scrollbar > .scroll-element.scroll-y .scroll-element_outer { left: 2px; width: 8px; }
 
.o-scrollbar > .scroll-element .scroll-element_outer { overflow: hidden; }
.o-scrollbar > .scroll-element .scroll-element_track { background-color: #eeeeee; }
 
.o-scrollbar > .scroll-element .scroll-element_outer,
.o-scrollbar > .scroll-element .scroll-element_track,
.o-scrollbar > .scroll-element .scroll-bar {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
}
 
.o-scrollbar > .scroll-element .scroll-bar { background-color: #d9d9d9; }
.o-scrollbar > .scroll-element .scroll-bar:hover { background-color: #c2c2c2; }
.o-scrollbar > .scroll-element.scroll-draggable .scroll-bar { background-color: #919191; }
 
 
/* scrollbar height/width & offset from container borders */
 
.o-scrollbar > .scroll-content.scroll-scrolly_visible { left: -12px; margin-left: 12px; }
.o-scrollbar > .scroll-content.scroll-scrollx_visible { top:  -12px; margin-top:  12px; }
 
.o-scrollbar > .scroll-element.scroll-x .scroll-bar { min-width: 10px; }
.o-scrollbar > .scroll-element.scroll-y .scroll-bar { min-height: 10px; }
 
 
/* update scrollbar offset if both scrolls are visible */
 
.o-scrollbar > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track { left: -14px; }
.o-scrollbar > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track { top: -14px; }
 
.o-scrollbar > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size { left: -14px; }
.o-scrollbar > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size { top: -14px; }

/* crit:end */