.o-richtext{
	> *:last-child:not(.lead):not(.b-lead){
		margin-bottom: 0;
		padding-bottom: 0;
	}

	h1,h2,h3,h4,h5,h6{
		margin-bottom: 10px;
		&:not(:first-child){
			margin-top: 10px;
		}
	}

	h2{
		@include h5;
	}

	h3,h4,h5{
		@include h6;
	}

	img{
		margin: 0 auto 30px;
	}

	&__img{
		margin-bottom: 30px;
		img{
			margin-bottom: 16px;
		}
	}

	ul,	ol {
		padding-left: 0;
		margin-bottom: 30px;
	}

	ul {
		list-style: none;
		li {
			position: relative;
			padding-left: 13px;
			&:before {
				@include vertical-center;
				content: '';
				width: 3px;
				height: 3px;
				background-color: currentColor;
				border-radius: 50%;
				left: 0;
			}

			&[style*="list-style: none"]:before{
				content: none;
			}
		}
	}
}
