.o-selectpager{
	.pagination{
		>li{

		}
	}
	.page-item{
		.page-link{
			background: $blue-100;
			color: $blue-700;
			min-height: 32px;
			padding:0;
			width: 32px;
			font-size: 18px;
			text-align: center;
			line-height: inherit;
		}
		.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn){
			width: 80px;
			&.monthFilter--wide {
				width: auto;
				min-width: 145px;
			}
		}
		.bootstrap-select .dropdown-toggle{
			font-size: 12px;
			background: $blue-100;
			color: $blue-700;
			font-weight: 600;
			border-radius: 2px;
			border-color: $blue-100;
			&:after{
				background-color: transparent;
				font-size: 18px;
				margin-left: 0;
				margin-right: -4px;
			}
		}
		.bootstrap-select .dropdown-menu{
			li a{
				font-size: 12px;
				&.active{
					&:hover{
						background-color: $blue-700;
					}
				}
			}
		}
	}

	&.is-white{
		.page-item{
			.page-link{
				background: white;
				border: 1px solid $blue-200;
				&:hover{
					background-color: $blue-700;
				}
			}
			.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn){
				width: 140px;
			}
			.bootstrap-select .dropdown-toggle{
				background: white;
				border: 1px solid $blue-200;
			}
		}
	}
	&.is-datepicker{
		.bootstrap-select .dropdown-toggle{
			&:after{
				content: icon('calendar');
			}
		}
	}
}