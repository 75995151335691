@media print {
    * {
        -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
        color-adjust: exact !important;                 /*Firefox*/
    }
    .c-section__top {
        display: none;
    }
    .c-section__side {
        display: none; 
    }
    .c-section__footer {
        margin-left: 0 !important;
        margin-right: 0 !important;
        width: 100% !important;
    }
    .c-section__main {
        margin-top: 0 !important;
        margin-left: 0 !important;
    }
    #procedures-stats-app, #trophons-stats-app, #probes-stats-app {
        #nav-tab2 {
            > .row {
                display: block;
                .col-7 {
                    flex: 1 0 auto;
                    max-width: 100%;
                    margin-top: 2rem;
                }
                .col-5 {
                    flex: 1 0 auto;
                    max-width: 100%;
                    &::after {
                        content: '';
                        break-after: left;
                        display: block;
                    }
                }
            }
            canvas {
                min-height: 100%;
                max-width: 100%;
                max-height: 100%;
                height: auto!important;
                width: 100%!important;
            }
        }
    }
    #operators-graph-app {
        .chart-styler {
            min-height: 0 !important;
        }
        canvas {
            min-height: 100%;
            max-width: 100%;
            max-height: 100%;
            height: auto!important;
            width: 100%!important;
        }
    }
    #settings-app {
        margin-left: 0;
        margin-top: 0;
        > h2 {
            display: none;
        }
        .c-panel__sidenav {
            display: none;
            + div {
                max-width: 100%;
                flex: 1;
            }
        }
        #tab-qr {
            .col-md-7 {
                max-width: 100%;
                flex: 1;
            }
            .o-btn {
                display: none;
            }
            .u-tac {
                > img {
                    width: 280px;
                    &:not(:first-of-type) {
                        display: none;
                    }
                }
            }
        }
    }
}