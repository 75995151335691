// Settings
// ==================================================
$hamburger-padding-x                       : 0;
$hamburger-padding-y                       : 0;
$hamburger-layer-width                     : 30px;
$hamburger-layer-height                    : 2px;
$hamburger-layer-spacing                   : 9px;
$hamburger-layer-color                     : #353535;
$hamburger-layer-border-radius             : 0;
$hamburger-hover-opacity                   : 0.7;
$hamburger-hover-transition-duration       : 0.15s;
$hamburger-hover-transition-timing-function: linear;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false;
$hamburger-hover-filter    : opacity(50%);

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (
	// 3dx,
	// 3dx-r,
	// 3dy,
	// 3dy-r,
	// arrow,
	// arrow-r,
	// arrowalt,
	// arrowalt-r,
	// boring,
	// collapse,
	// collapse-r,
	// elastic,
	// elastic-r,
	// emphatic,
	// emphatic-r,
	// slider,
	// slider-r,
	// spring,
	// spring-r,
	// stand,
	// stand-r,
	// spin,
	// spin-r,
	// squeeze,
	vortex,
	// vortex-r
);
