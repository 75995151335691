$global-alert-height:   56px;

.c-global-alert {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgb(255, 230, 208);
    z-index: 10;
    align-items: center;
    /* crit */
    &.is-active {
        display: flex;
        height: $global-alert-height;
        ~ .c-section__top {
            top: $global-alert-height;
        }
        ~ .c-section__wrapper {
            margin-top: $global-alert-height;
            .c-section__side {
                top: $global-alert-height;
            }
        }
    }
    &__icon {
        font-size: 1.4rem;
        margin-left: 0.4rem;
        margin-right: 0.8rem;
        line-height: 1;
        color: $orange;
    }
    &__container {
        padding: 0.5rem 0.8rem;
        display: flex;
        width: 100%;
    }
    &__description {
        margin-bottom: 0;
        flex: 1;
        display: inline-block;
        font-size: #{calc(15/16)}rem;
        line-height: 1.7;
    }
    &__close {
        color: $orange;
        .g-icon-close {
            font-size: 1.4rem;
        }
    }
}

