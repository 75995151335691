.c-panel{

	&__upload,
	&__blue{
		background-color: $blue-100;
		padding: 15px 17px;
		/*crit*/
	}

	&__pagination{
		padding: 15px 17px;
		/*crit*/
		.pagination{
			margin-bottom: 0;
			/*crit*/
		}
		.o-btn{
			//padding:6px 15px 6px 12px;
			min-width: 90px;
			text-align: left;
			span{
				font-size: 20px;
			}
		}
		&-prev{
			padding:6px 15px 6px 6px;
		}
		&-next{
			padding:6px 6px 6px 12px;
			span{
				float:right;
			}
		}
	}

	&__head{
		.bootstrap-select{
			.dropdown-toggle{
				border: 1px solid $blue-700;
				color: $blue-700;
				font-size: 15px;
				background: $white;
				border-radius: 4px;
				min-height: 40px;
			}
			.dropdown-item.active, 
			.dropdown-item:active{

				&:hover{
					background-color: $blue-700;
				}

			}
		}
		.o-selectpager{
			.bootstrap-select{
				.dropdown-toggle{
					min-height: inherit;
				}
			}.pagination{
				margin-bottom: 0;
			}
		}
	}

	//search
	&__search{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		padding: 20px;

		.input-group-search{
			min-width: 590px;
			input[type=text]{
				font-size: 16px;
    			padding: 16px 23px;
    			background: white;
			}
			span{
				background: $blue-700;
				color: white;
				font-size: 12px;
				border-radius: 20px;
				width: 24px;
				height: 24px;
				text-align: center;
				line-height: 24px;
				right: 16px;
				transition: .3s;
				&:hover{
					background-color: $blue-500;
				}
			}
		}
	}

	&__sidenav{
		position: relative;
		@include mq(xl){
			min-height: 650px;	
		}
		&:before{
			border-right: 1px solid $blue-200;
			content: "";
			display: inline-block;
			position: absolute;
			right: 15px;
			height: 100%;
			top:0;
		}
		
	}

}

.pagination{
	>li{
		font-size: 12px;
	}
}
.page{
	&-item{
		margin-right: 2px;
		margin-left: 2px;
		font-size: 12px;
		/*crit*/
		.page-link{
			border-radius:2px;
			border:0;
			color: $blue-700;
			font-weight: 700;
			/*crit*/
			&:hover,
			&:focus{
				background-color: $blue-700;
				color: white;
			}
			&.is-disabled{
				cursor: default;
				&:hover,
				&:focus{
					background-color: $blue-100;
					color: $blue-700;
				}
			}
		}
		&:first-child .page-link,
		&:last-child .page-link{
			border-radius:2px;	
			/*crit*/
		}
		&.active{
			.page-link{
				background-color: $blue-700;
				/*crit*/
			}
		}
	}


}

