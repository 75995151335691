// Although the template is similar to FontAwesome,
// don't use it like .fa.fa-[icon-name].
// instead just use like v-icon-[icon-name]
// e.g. .v-icon-facebook (don't need .v-icon.v-icon-facebook)

// DON'T USE "icon" as a class. Adblock Plus apparently
// has a CSS rule to hide icon-[social-icons] elements.

/* crit:start */
@font-face {
  font-family: "g-icons";
  src: url('/static/fonts/g-icons.eot?937d9a');
  src: url('/static/fonts/g-icons.eot?937d9a#iefix') format('eot'),
    url('/static/fonts/g-icons.woff2?937d9a') format('woff2'),
    url('/static/fonts/g-icons.woff?937d9a') format('woff'),
    url('/static/fonts/g-icons.ttf?937d9a') format('truetype'),
    url('/static/fonts/g-icons.svg?937d9a#g-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin iconfont {
  //use !important to prevent issues with browser extensions that change fonts
  font-family: 'g-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;

  // Better Font Rendering
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="g-icon-"], [class*=" g-icon-"], .g-icon {
  //use !important to prevent issues with browser extensions that change fonts
  @include iconfont;
}


$icon-list: (

  alert-circle: "\EA01",

  arrow-down: "\EA02",

  arrow-left: "\EA03",

  arrow-right: "\EA04",

  arrow-top-right: "\EA05",

  barcode: "\EA06",

  calendar: "\EA07",

  chemical: "\EA08",

  chev-down: "\EA09",

  chev-left: "\EA0A",

  chev-right: "\EA0B",

  chev-up: "\EA0C",

  close: "\EA0D",

  cog: "\EA0E",

  download: "\EA0F",

  drag-cursor: "\EA10",

  edit: "\EA11",

  envelope: "\EA12",

  eye: "\EA13",

  facebook: "\EA14",

  info: "\EA15",

  instagram: "\EA16",

  link-alt: "\EA17",

  link: "\EA18",

  list: "\EA19",

  lock: "\EA1A",

  mobile: "\EA1B",

  notification: "\EA1C",

  pie-chart: "\EA1D",

  pin: "\EA1E",

  plus: "\EA1F",

  question-circle: "\EA20",

  search: "\EA21",

  settings: "\EA22",

  tick: "\EA23",

  trophon: "\EA24",

  ultrasound: "\EA25",

  upload: "\EA26",

  user: "\EA27",

  warning: "\EA28",

  wifi-off: "\EA29",

  wifi: "\EA2A",

);

@each $icon, $refKey in $icon-list{
  .g-icon-#{$icon}:before{
    content: $refKey;
  }
}
/* crit:end */

//usually this use on pseudo element
@mixin icon($iconName){
  @include iconfont;
  content: icon($iconName);
}