/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */

::-moz-selection {
	background: #b3d4fc;
	text-shadow: none;
}

::selection {
	background: #b3d4fc;
	text-shadow: none;
}

/* crit:start */

*,
*:before,
*:after {
	flex: 0 1 auto;
}

html {
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-ms-overflow-style: scrollbar;
}

body {
	min-height: 100vh;
}

fieldset {
	margin: 0;
	padding: 0;
	border: 0;
}

input {
	@include fzlh(15px, 24px);
	border-radius: 0;
	/* crit */
	&[type="search"],
	&[type="text"],
	&[type="password"],
	&[type="tel"],
	&[type="number"],
	&[type="email"] {
		box-sizing: border-box;
		width: 100%;
		padding: 7px 15px;
		min-height: 40px;
		font-size: 0.8rem;
		color: #6D6E71;
		background: $blue-50;
		border:1px solid #97CFE1;
		border-radius: 2px;
		box-shadow: inset 4px 4px 2px #E0EBF5;
		/*crit*/
	}

	&:invalid {
		// remove red border on invalid input on mozilla
		box-shadow: none;
		/* crit */
	}

	&::placeholder {
		color: rgba($text, .8);
		/* crit */
	}

	&.error {
		border-color: red;
	}

	&::-ms-clear {
		display: none;
	}

	//custom input type file
	&[type=file] {
	  /*cursor: pointer;
	  width: 146px;
	  height: 32px;
	  overflow: hidden;*/
	  /*crit*/

	  &:before {
		 /* width: 146px;
		  height: 32px;
		  font-size: 16px;
		  line-height: 32px;
		  content: 'Upload';
		  display: inline-block;
		  background: $blue-700;
		  color: white;
		  border-radius: 4px;
		  text-align: center;
		  font-size: 12px;
		  font-weight: 600;*/
		  /*crit*/
		}
	}
	&::-webkit-file-upload-button {
	  /*visibility: hidden;*/
	}

}


hr{
	border:0;
	border-bottom: 1px solid $blue-200;
}

button {
	border: 0;
	background-color: transparent;
	padding: 0;
	margin: 0;
	appearance: none;
}



table:not(.u-table-unstyled) {
	width: 100%;
	td,
	th {
		padding:5px 12px;
		// &:first-child {
		// 	padding-left: 0;
		// }
	}
	th {
		font-size: 13px;
		font-weight: 700;
		color: $black;
		border-bottom: 2px solid $blue-100;

	}
	td{
		font-size: 12px;
		border-bottom: 1px solid $blue-100;
		border-left: 0;
		
	}
	tr {
		td{
			&:first-child{
				border-left: 1px solid $blue-100;
			}
			&:last-child{
				border-right: 1px solid $blue-100;
			}
		}
		&:hover{
			td{
				background-color: $blue-100;
			}
		}
	}
	thead {
		tr {
			//border-bottom: 3px solid $blue-100;
		}
	}
	&.is-last-right{
		tr{
			td:last-child{
				text-align: right;
			}
		}
	}
}

select, textarea{
	// Prevent ios default box shadow and radius
		//@include fzlh(15px, 24px);
		appearance: none;
		display: block;
		box-sizing: border-box;
		width: 100%;
		padding: 7px 12px;;
		min-height: 40px;
		font-size: 12px;
		color: #6D6E71;
		background: $blue-50;
		border:1px solid #97CFE1;
		border-radius: 2px;
		box-shadow: inset 4px 4px 2px #E0EBF5;

	&:disabled{
		opacity: .5;
	}

	&:focus{
		box-shadow: 0 0 10px 0 rgba(204, 218, 225, 0.7);
		background-color: #fff;
	}
}

select:not(.selectpicker){
	border-radius: 0;
	background: $blue-50 url('/static/img/chevron-down.svg') calc(100% - 12px) 50% no-repeat;
	background-size: 12px auto;
	border:1px solid #97CFE1;
	font-size: 12px;
	box-shadow: none;
	padding: .375rem 1.2rem .375rem .75rem;
	/*crit*/
	&::-ms-expand {
		display: none;
	}
}

textarea{
	overflow: auto;
	resize: none;
	resize: vertical;
}

label{
	font-weight: 700;
	font-size: 13px;
}

img{
	max-width: 100%;
	height: auto;
}

.dropdown{
	&-menu{
		padding-left: 8px;
		padding-right: 8px;
		border-color: $blue-200;
	}
	&-item{
		padding: 6px 8px;

		&:focus,
		&:hover{
			background-color: $blue-100;
		}
	}
}
.dropdown-toggle:after{
	border:0;
	@include iconfont;
  	content: icon('chev-down');
  	font-size: 23px;
  	/*crit*/
} 
.dropdown-divider{
	border-color: $blue-200;
}

.page{
	&-item{
		.page-link{
			&.is-disabled{
				opacity: 0.5;
				cursor: default;
			}
		}
	}
}
/* 
	overwrite BT form 
*/
.form{
	&-group{
		margin-bottom: 15px;
	}
}
.text-danger{
	color: $red;
	margin:8px 0;
	font-size: 12px;
}

input.invalid + .text-danger {
	display: inline-block;
	margin-top: 5px;
	margin-bottom: 2px;
}

//alert
.alert{
	padding:10px 16px;
	[class^="g-icon-"], 
	[class*=" g-icon-"], 
	.g-icon{
		font-size: 20px;
		margin-right: 10px;
	}
	&-warning{
		background-color: rgba(255, 130, 0, 0.1);
		color: $black;

		[class^="g-icon-"], 
		[class*=" g-icon-"], 
		.g-icon{
			color: $orange-500;
		}

		&.is-bordered{
			border-color: rgba($orange-500, 0.9);
			background: rgba($orange-500, 0.05);
			border-radius:0;
			font-size: 13px;
			padding: 16px;
		}

	}

	&-info{
		background-color: rgba($blue-500, 0.1);
		color: $blue-700;

		[class^="g-icon-"], 
		[class*=" g-icon-"], 
		.g-icon{
			color: $blue-500;
		}

		&.is-bordered{
			border-color: rgba($blue-500, 0.9);
			background: rgba($blue-500, 0.05);
			border-radius:0;
			font-size: 13px;
			padding: 16px;
		}

	}

	&-danger{
		background-color: rgba($red, 0.1);
		color: $black;

		[class^="g-icon-"], 
		[class*=" g-icon-"], 
		.g-icon{
			color: $red;
		}

		&.is-bordered{
			border-color: rgba($red, 0.9);
			background: rgba($red, 0.05);
			border-radius:0;
			font-size: 13px;
			padding: 16px;
		}

	}

	&-success{
		background-color: rgba($green-500, 0.1);
		color: $green-600;

		[class^="g-icon-"], 
		[class*=" g-icon-"], 
		.g-icon{
			color: $green-600;
		}

		&.is-bordered{
			border-color: rgba($green-500, 0.9);
			background: rgba($green-500, 0.05);
			border-radius:0;
			font-size: 13px;
			padding: 16px;
		}

	}

	
}

//badge
.badge{
	padding: 5px 10px;
	font-size: 12px;
	&-warning{
		color: $white;
		background: $orange-500;
	}
}

//input-group
.input{
	&-group{
		&-search{
			min-width: 280px;
			position: relative;
			span{
				position: absolute;
				right:12px;
				top:50%;
				transform: translateY(-50%);
				color: $blue-700;
				font-size: 22px;
				/*crit*/
			}
			&.is-autowidth{
				min-width: inherit;
			}
		}

		&-grid{
			display: flex;
			align-items: center;
			&__item{
				margin-left: 8px;
				margin-right: 8px;
				select.form-control{
					min-width: 185px;
				}
				&:last-child{
					margin-right: 0;
				}
			}
		}
		&-date{
			.dropdown-toggle{
				border:0;
				background: white;
				border: 1px solid $blue-700;
				padding: 8px 12px;
				font-size: 12px;
				border-radius: 2px;
				color: $text;
				&:after{
					@include iconfont;
					content: icon('calendar');
					border:0;
					vertical-align: middle;
					font-size: 18px;
					color: $blue-700;
				}

			}
			select{
					background-color: #fff;
				}
		}
	}
	&-icon{
		position: relative;
		.icon{
			position: absolute;
			right:10px;
			bottom:3px;
			color: $blue-800;
			font-size: 20px;
		}
	}
}
.close{
	color: $blue-800;
	text-shadow: none;
	opacity: 1;
}


//modal
.modal{
	&.overlapse{
		z-index: 2000;
		background: rgba(#000, 0.8);
	}
	&-header{
		border-bottom: 0;
		padding-left: 32px;
		padding-right: 32px;
		padding-top: 32px;
	}
	&-content{
		background-color: $blue-100;
	}
	&-body{
		padding-left: 32px;
		padding-right: 32px;
		padding-top:0;
		
		&-content{
			font-size: 12px;
			&:not(:last-child){
				margin-bottom: 25px;
			}
			a:not(.modal-body-content__head){
				font-weight: 700;
				text-decoration: underline;
				&:hover,
				&:focus{
					text-decoration:none;
				}
			}
			&__head{
				color: $black;
				&-icon{
					right:20px;
					top: 50%;
					color: $blue-700;
					font-size: 22px;
					transform: translateY(-50%);
					span{
						vertical-align: middle;
					}
				}
				&:hover{
					text-decoration: none;
				}
				&.collapsed{
					.modal-body-content__head-icon{
						span:before{
							content:icon('chev-up');
						}
					}
				}
			}
		}
		label{
			font-size: 12px;
		}
		.form-group{
			margin-bottom: 15px;
			&.no-gutters{
				padding-bottom: 6px;
				margin-bottom: 6px;

				input{
					padding: 0 12px;
					min-height: 32px;
    				height: calc(30px + 2px);
				}
				select:not(.selectpicker){
					min-height: 32px;
				}
			}
		}
	}
	&-footer{
		border-top: 0;
		padding-left: 32px;
		padding-right: 32px;
		padding-bottom: 32px;
		&-copy{
			border-bottom-right-radius: .3rem;
    		border-bottom-left-radius: .3rem;
		}
	}

	&-lg, 
	&-xl {
		@media (min-width: 1200px){
			max-width: 1000px;
		}
	}

	&-md {
		@media (min-width: 1200px){
			max-width: 700px;
		}
	}
}


//tabs
.tab-content>.tab-pane {
	// display: block;
	// overflow: hidden;
    height: 0;
	overflow: visible;
	display: none;
}
.tab-content>.tab-pane.active {
    height: auto;
    display: block;
}

.nav-pills{
	.nav-link{
		border-radius: 0;
		color: $black;
		margin-bottom: 10px;
		/*crit*/
		&.active{
			border-right: 4px solid $blue-500;
			background: none;
			color: $black;
			font-weight: 700;
			/*crit*/
		}
	}
}



//plugins

.bootstrap-tagsinput {
  max-width: 100%;
  line-height: 22px;
   cursor: text;
    padding: 7px 12px;
    min-height: 40px;
    font-size: 12px;
    font-size: .75rem;
    color: #6d6e71;
    background: #f4f8fb;
    border: 1px solid #97cfe1;
    border-radius: 2px;
    box-shadow: inset 4px 4px 2px #e0ebf5;
}
.bootstrap-tagsinput input {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  padding: 0 6px;
  margin: 0;
  width: auto;
  max-width: inherit;
  min-height: auto;
}
.bootstrap-tagsinput.form-control input::-moz-placeholder {
  color: #777;
  opacity: 1;
}
.bootstrap-tagsinput.form-control input:-ms-input-placeholder {
  color: #777;
}
.bootstrap-tagsinput.form-control input::-webkit-input-placeholder {
  color: #777;
}
.bootstrap-tagsinput input:focus {
  border: none;
  box-shadow: none;
}
.bootstrap-tagsinput .tag {
  margin-right: 2px;
  color: white;
  font-size: 12px;
  padding:5px 7px;
  margin-top: 1px;
}
.bootstrap-tagsinput .tag [data-role="remove"] {
  margin-left: 8px;
  cursor: pointer;
}
.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: "x";
  padding: 0px 2px;
}
.bootstrap-tagsinput .tag [data-role="remove"]:hover {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.bootstrap-tagsinput .tag [data-role="remove"]:hover:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

 


/* crit:end */
