.c-notification {
    padding: 15px 20px;
    border-radius: 4px;
    margin-bottom: 1rem;
    min-height: 38px;
    position: relative;
    transform: translateY(-100%);
    transition: transform 0.1s linear;
    display: none;
    &.is-active {
        transform: translateY(0);
        display: block;
    }
    // &.is-error {
    //     background-color: rgba(255, 130, 0, .05);
    //     border: 1px solid #ead7d7;
    // }
    &__message {
        font-size: 14px;
        display: block;
        margin-right: 24px;
    }
    &__close {
        position: absolute;
        top: 12px;
        right: 15px;
        background-color: transparent;
        border: 0;
        padding: 0;
        margin: 0;
        font-size: 1.4rem;
    }
    &__wrapper {
        overflow: hidden;
    }
}
