.o-collapselist {
	$_this: &; // {{ .o-collapselist__title }}
	$collapselist-toggle-size: 16px;
	$collapselist-toggle-thickness: 2px;

	&__item{
		border:1px solid $blue-200;
		margin-bottom: 15px;
	}
	&__title {
		position: relative;
		&-link {
			display: block;
			@include fzlh(15px, 22px);
			&:hover {
				text-decoration: none;
				color: $blue-700;
			}
			/* crit */
			transition: .3s;
			.g-icon-chev-down {
				transform: rotate(180deg);
				transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
			}
			&.collapsed {
				.g-icon-chev-down {
					transform: rotate(0deg);
				}
				/* crit */
			}
		}
	} // {{ .o-collapselist__icon }}
	
	&__arrow {
		font-size: 20px;
	}

	&__label{

	}

	&__icon {
		position: absolute;
		top: 50%;
		right: 15px;
		font-size: 1rem;
		transform: translateY(-50%);
	}

	&__media {
		@include mq(md) {
			width: 401px;
			margin-top: 0;
			margin-left: 60px;
		}
		&-inner {
			padding-bottom: percentage(282/421);
		}
	}
	&__content {
		@include mq(md) {
			display: flex;
		}
	} // {{ .o-collapselist__panel }}
	&__panel {}
	// {{ .o-collapselist__body }}
	&__body {
		padding: 20px 17px;
	}
}
