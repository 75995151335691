.c-styled-input {
    &__input {
        border: 1px solid $blue-700 !important;
        border-radius: 4px !important;
    }
}

.form-control {
    option {
        background-color: #f4f8fb;
    }
    &[disabled], &[readonly] {
        background-color: #f4f8fb;
        cursor: not-allowed;
        &:focus {
            box-shadow: inset 4px 4px 2px #e0ebf5;
        }
    }
    &.input-validation-error {
        + .field-validation-error {
            display: inline;
        }
    }
}

.form-group {
    input, select {
        + .text-danger { 
             display: none;
             /*crit*/
        }
        &.invalid {
            + .text-danger {
                display: inline-block;
            }
        }
    }
}