.o-tablelist {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    background-color: $blue-100;
    margin-top: 1rem;
    padding: 0.6rem;
    display: none;
    &.is-active {
        display: block;
    }
}

.o-tablelist__item {
    background-color: #FFFFFF;
    padding: 5px 8px;
    display: flex;
    align-items: center;
    + .o-tablelist__item {
        border-top: 1px solid $blue-100;
    }

}

.o-tablelist__item__col {
    line-height: 1;
    &:first-of-type {
        flex-basis: 75%;
    }
    &:last-of-type {
        margin-left: auto;
        font-size: 1rem;
    }
}