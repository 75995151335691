.ui-widget.ui-widget-content {
	border-color: $blue-300;
}

.ui-datepicker {
	.ui-datepicker-title {
		display: flex;
		justify-content: space-around;
	}
	.ui-datepicker-next,
	.ui-datepicker-prev {
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
		&.ui-state-hover {
			top: 50%;
			transform: translateY(-50%);
		}
		&.ui-datepicker-next-hover {
			right: 2px;
		}
		&.ui-datepicker-prev-hover {
			left: 2px;
		}
	}

	table:not(.u-table-unstyled) {
		border: 0;
		td {
			padding: 1px;
			border: 0;
			a {
				padding: 8px 4px;
				text-align: center;
			}
		}
		th {
			padding: 10px 1px;
			border: 0;
		}
		tr:hover td {
			background-color: #fff;
		}
	}
}

.ui-widget-header {
	border: 0;
	background: $blue-500;
	color: $white;
	border-radius: 0;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
	border: 0;
}

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
	background: none;
	text-indent: inherit;
	&:before {
		@include iconfont;
		display: inline-block;
		color: white;
		content: icon(arrow-left);
	}
	&:hover {
		background: none;
	}
}

.ui-datepicker .ui-datepicker-next span {
	&:before {
		content: icon("arrow-right");
	}
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
	border: 0;
	&:hover {
		background: none;
		border: 0;
	}
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
	background: $blue-500;
}

.ui-autocomplete {
	max-height: 100px;
	overflow-y: auto;
	/* prevent horizontal scrollbar */
	overflow-x: hidden;
}

/* IE 6 doesn't support max-height
   * we use height instead, but this forces the menu to always be this tall
   */

* html .ui-autocomplete {
	height: 100px;
}
