.o-card{
	border: 2px solid $blue-100;
	&__head{
		background-color: $blue-100;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 16px 24px;
		&-title{
			font-size: 16px;
			font-weight: 700;
		}
		&-subtitle{
			color: $blue-700;
		}
	}
	&__body{

	}
}