.o-notify{
	$_this: &;
	text-align: center;
	padding:40px;
	font-size: 15px;
	&-icon{
		width: 64px;
		height: 64px;
		border-radius: 100%;
		background: $blue-200;
		color: white;
		line-height: 64px;
    	font-size: 45px;
    	margin-bottom: 16px;
	}
	&-success{
		color: $green-500;
		#{$_this}-icon{
			background: $green-500;
		}
	}
	&-error{
		color: $red;
		#{$_this}-icon{
			background: $red;
		}
	}
	&-warning{
		color: $orange-500;
		#{$_this}-icon{
			background: $orange-500;
		}
	}
}

.o-dotted{
	width: 9px;
	height: 9px;
	vertical-align: middle;
	border-radius: 10px;
	display: inline-block;
	background: $primary;
	&.is-orange{
		background: $orange;
	}
}