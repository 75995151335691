.o-btn {
	position: relative;
	//font-size: 15px;
	font-weight: 600;
	color: $white;
	border-radius: 4px;
	padding: 8px 30px;
	border: 0;
	/* crit */
	transition: 0.3s;

	&:hover,
	&:focus {
		color: #fff;
		&:before {
			transform: none;
			opacity: 1;
		}
	}
	&--primary {
		background-color: $blue-700;
		color: #fff;
		border: 1px solid $blue-700;
		/* crit */
		&:before {
			background-color: $blue-500;
		}
		&:hover,
		&:focus {
			background-color: $blue-500;
			border: 1px solid $blue-500;
		}
	}
	&--secondary {
		background-color: transparent;
		color: $blue-700;
		border: 1px solid $blue-700;
		/* crit */
		&:before {
			background-color: $blue-700;
		}
		&:hover,
		&:focus {
			background-color: $blue-700;
			border-color: $blue-700;
			color: white;
		}
		&.is-reverse {
			border-color: $blue-500;
			color: white;
		}
	}

	&--success {
		background-color: $green-600;
		color: $white;
		border: 1px solid $green-600;
		/* crit */
		&:before {
			background-color: $green-500;
		}
		&:hover,
		&:focus {
			background-color: $green-500;
			border-color: $green-500;
			color: white;
			text-decoration: none;
		}
	}

	&--flat {
		border-color: transparent;
		color: $blue-700;
		background-color: transparent;
		&:hover,
		&:focus {
			border-color: transparent;
			color: $blue-700;
			background-color: transparent;
		}
	}

	&--icon {
		width: 40px;
		height: 40px;
		text-align: center;
		padding: 8px;
		font-size: 18px;
		background: $blue-200;
		color: $blue-700;
		/* crit */

		&:focus,
		&:hover {
			text-decoration: none;
			background: $blue-700;
			color: $white;
		}
	}

	&--small {
		font-size: 12px;
		/* crit */
	}
	&--capsule {
		border-radius: 20px;
		padding: 4px 10px;
		min-width: 175px;
		/* crit */
	}

	&--white {
		background-color: $white;
		color: $blue-700;
		/* crit */
		&:hover,
		&:focus {
			background-color: $blue-700;
			color: $white;
		}
	}

	&--orange {
		background-color: $orange;
		color: $white;
		/* crit */
		&:hover,
		&:focus {
			background-color: darken($orange, 0.1);
			color: $white;
		}
	}

	&.is-inline {
		min-width: auto;
	}
	&.is-fade {
		border-color: $blue-300;
	}

	&.is-green {
		border-color: $green;
		color: $green;
		&:hover,
		&:focus {
			border-color: $green;
			color: $green;
			background-color: #fff;
		}
	}
	&.is-red {
		border-color: $red;
		color: $red;
		&:hover,
		&:focus {
			border-color: $red;
			color: $white;
			background-color: $red;
		}
	}
	&.is-disabled {
		opacity: 0.2;
		/* crit */
		&:hover,
		&:focus {
			background-color: $white;
			color: $blue-700;
		}
	}
	&--v-center {
		.o-btn__container {
			display: inline-flex;
			align-self: center;
			vertical-align: middle;
		}
	}
	&--thin {
		padding-left: 12px;
		padding-right: 12px;
	}
	&__icon {
		font-size: 1rem;
		margin-right: 6px;
	}
	&__spinner {
		display: inline-block;
		width: 1rem;
		height: 1rem;
		border: 0.2rem solid currentColor;
		border-right-color: transparent;
		vertical-align: middle;
		animation: spinner 1s linear infinite; 
		border-radius: 50%;
		margin-right: 0.3rem;
	}
}

@keyframes spinner {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}