.c-section{
	//{{ .c-section__login  }}
	&__login{
		// min-height: 100vh;
		/*crit*/

		&-wrapper{
			background: $blue-100;
			max-width: 924px;
			margin:50px auto;
			padding: 32px;
			border-radius: 4px;
			position: relative;
			/*crit*/
		}
		&-body{
			background: #fff;

		}
		&-btn{
			&nav{

				position: absolute;
				top: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				/*crit*/

				&.is-left{
					left: 32px;
				}
				&.is-right{
					right: 32px;

				}
			}
		}
		form {
			.o-btn__spinner { display: none; }
			&.is-submitting {
				.o-btn { opacity: 0.2; }
				.o-btn__spinner { display: inline-block; }
			}
		}
	}

	// {{ .c-section__top }}
	// admin top nav
	&__top{
		background-color: $blue-700;
		color: white;
		padding-top: 8px;
		padding-bottom: 8px;
		justify-content: space-between;
		/*crit*/
		z-index: 1001;


		&-country{
			.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn){
				width: 184px;
				/*crit*/
			}
			.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show>.btn-light.dropdown-toggle{
				background: rgba($blue-800, 0.5);
				/*crit*/
			}
			.dropdown-toggle{
				border:0;
				background: rgba($blue-800, 0.5);
				/*crit*/

				&:after{
					color: white;
					opacity: 0.5;
					/*crit*/
				}
			}
			.selectpicker-label{
				color: white;
				font-weight: 700;
				/*crit*/
			}
			.dropdown-menu{
				.selectpicker-label{
					color: $text;
					/*crit*/
				}
				li{
					margin:5px 0;
					/*crit*/
				}
				li.selected{
					.selectpicker-label{
						color: white;
					}
				}
			}
		}

		&-user{

			.dropdown-toggle{
				color: white;
				font-size: 12px;
				font-weight: 700;
				/*crit*/
				&:after{

					vertical-align: middle;
					opacity: 0.5;
					/*crit*/
				}

				&:focus{
					box-shadow: none;
				}
			}
			.dropdown-item{
				font-size: 13px;
				/*crit*/
			}
		}
		&-search{
			.input-group-search{
				min-width: 574px;
				/*crit*/
				input[type=text]{
					background: rgba($blue-800, 0.5);
					box-shadow: none;
					border:0;
					color: $blue-300;
					font-weight: 700;
					&::placeholder{
						color: $blue-300;
					}
					/*crit*/
				}
				span{
					color: white;
					/*crit*/
				}
			}
		}

		&-sites{
			margin-left: -16px;
			.dropdown-toggle{
				color: white;
				font-size: 18px;
				font-weight: 700;
				text-align: left;
				padding-left: 16px;
				width: 235px;

				/*crit*/
				&:after{

					vertical-align: middle;
					opacity: 0.5;
					/*crit*/
				}

				&:focus{
					box-shadow: none;
				}
			}
			.dropdown-wrapper {
				display: inline-block;
				overflow: hidden;
				text-overflow: ellipsis;
				vertical-align: middle;
				width: calc(100% - 22px);
			}
			.dropdown-item{
				font-size: 15px;
				min-width: 305px;
				/*crit*/
			}
			&.show{
				.dropdown-toggle{
					background: rgba($blue-800, 0.5);
				}
			}

		}

		&-right{
			&-item{
				&:not(:last-child){
					border-right: 1px solid $blue-800;
					padding-right: 16px;
				}
			}
		}
	}

	&__wrapper{
		/*position: relative;
		z-index: 1;*/
		&.is-sidemenu-small{
			.c-section__side{
				width: 130px;
				/*crit*/
			}
			.c-section__main{
				margin-left: 130px;
				/*crit*/
			}
			.c-section__footer{
				margin-left: 130px;
				width: calc(100% - 130px);

			}
		}
	}

	// .c-section__side
	&__side{
		background: $blue-800;
		color: white;
		width: 240px;
		height: 100vh;
		position: fixed;
		left:0;
		top:0;
		padding-top: 58px;
		overflow-y: auto;
		/*crit*/
		&-centered{
			display: flex;
			justify-content: center;
			align-items: center;
			min-height: calc(100vh - 57px)
		}
	}

	// .c-section__main
	&__main{
		//width: 100%;
		padding: 34px;
		margin-left: 240px;
		margin-top: 58px;
		padding-bottom: 80px;
		min-height: calc(100vh - 56px - 58px);
		height: auto;
		font-size: 12px;
		/*crit*/
	}

	// .c-section__footer
	&__footer{
		margin-left: 240px;
		min-height: 56px;
		width: calc(100% - 240px);
		position: relative;
		bottom:0;
		background-color: #fff;
		z-index: 2;
		/*crit*/
		&-main{
			padding: 8px 24px;
			border-top:1px solid rgba(#6D6E71, 0.3);
			justify-content: space-between;
			align-items: center;
			min-height: 56px;
			/*crit*/
		}
		&-brand{
			img{
				height: 32px;
				/*crit*/
			}
		}
		&-copy{
			color: $blue-800;
			> div {
				display: inline-block;
				font-size: 0.8rem;
				margin-left: 10px;
				color: $text;
				span {
					display: inline-block;
					margin-left: 3px;
				}
			}
		}
	}
	&__login {
		+ .c-section__footer {
			margin-left: 0;
			width: 100%;
		}
	}

}
