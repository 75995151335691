// generate font-size and line-height based on line-height in pixel
@mixin fzlh($fz, $lh) {
	font-size: $fz;
	line-height: ($lh / $fz);
}

@mixin h1 {
	@include fzlh(40px, 48px);
}
@mixin h2 {
	@include fzlh(36px, 44px);
}
@mixin h3 {
	@include fzlh(28px, 38px);
}
@mixin h4 {
	@include fzlh(24px, 36px);
}
@mixin h5 {
	@include fzlh(18px, 28px);
}
@mixin h6 {
	@include fzlh(16px, 26px);
}

@mixin fsblockquote {
	$font-size: 23px;
	font-style: italic;
	@include fzlh($font-size, 36px);
}

@mixin fslead {
	$font-size: 21px;
	font-weight: 300;
	@include fzlh($font-size, 30px);
}

@mixin fsbodylarge {
	$font-size: 17px;
	@include fzlh($font-size, 30px);
}

@mixin fsbodysmall {
	$font-size: 15px;
	@include fzlh($font-size, 22px);
}

@mixin fssmall {
	$font-size: 13px;
	@include fzlh($font-size, 20px);
}
@mixin fstiny {
	$font-size: 12px;
	@include fzlh($font-size, 18px);
}

@mixin fsbtn {
	@include fzlh(13px, 24px);
	font-weight: 500;
}
