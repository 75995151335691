// kendo ui
.k {
	//grid
	&-grid {
		border: 0;
		&-header {
			/*background-color: $white;
            border-color:  $white;*/
			border-color: $blue-100;
			border-bottom-width: 2px !important;
			padding-right: 0 !important;
			th.k-header {
				border-width: 0;
				padding-bottom: 15px;
				padding-left: 12px;
			}
			th.k-header > .k-link {
				color: $black;
				font-weight: 700;
			}
			&-wrap {
				border-color: $white;
				border: 0;
			}
			.k-header {
				background: $white;
				padding-bottom: 10px;
			}
		}
		td {
			border-color: $blue-100;
		}
		&-content {
			overflow-y: auto;
			table .k-command-cell {
				padding: 5px 6px;
				text-align: right;
			}
			table td:last-child {
				text-align: left;
				padding-right: 8px;
				.k-button {
					margin-left: auto;
					margin-right: 0;
					&:not(.k-grid-View) {
						display: block;
					}
				}
			}
		}
	}
	//pager
	&-pager {
		&-wrap {
			background-color: $blue-100;
			border-color: $blue-100;
			padding-top: 10px;
			padding-bottom: 10px;
			.k-link {
				border: 0;
				font-size: 12px;
				line-height: inherit;
				font-weight: 600;
				background: white;
				border-radius: 2px;
				margin-right: 2px;
				padding-left: 2px;
				padding-right: 2px;
				color: $blue-700;
				&.k-pager-nav {
					background: transparent;
				}
			}
		}
		&-numbers {
			.k-state-selected {
				background: $blue-700;
				border-color: $blue-700;
				border-top: 0;
				color: white;
				font-size: 12px;
				padding-left: 2px;
				padding-right: 2px;
				border-radius: 2px;
				font-weight: 600;
			}
		}
		&-sizes,
		&-info {
			font-size: 12px;
		}
	}
	//button
	&-button {
		&-icontext {
			.k-grid tbody & {
				width: 40px;
				min-width: 40px;

				&:hover {
					background-color: #fff;
				}
			}
		}
		&.k-grid-Edit {
			border: 1px solid $blue-300;
			border-radius: 2px;
			box-shadow: none;
			background: transparent;
			overflow: hidden;
			width: 24px;
			height: 24px;
			display: inline-block;
			min-width: 20px !important;
			text-align: center;
			padding-left: 0;
			padding-right: 0;
			color: $blue-800;
			font-size: 14px;
			&:before {
				@include iconfont;
				content: icon("edit");
			}
		}
		&.k-grid-View {
			box-shadow: none;
			border: 1px solid $blue-300;
			border-radius: 2px;
			color: $blue-700;
			background-color: #fff;
			display: inline-flex;
			align-items: center;
			&:before {
				@include iconfont;
				content: icon("arrow-right");
				font-size: 1.1rem;
				margin-right: 0.3rem;
			}
			&:hover,
			&:focus {
				background-color: $blue-700;
				color: white;
				border-color: $blue-700;
			}
		}
		&.k-grid-Viewsite {
			box-shadow: none;
			border: 1px solid $blue-300;
			border-radius: 4px;
			background-color: #ffffff;
			color: $blue-700;
			padding: 3px 15px;
			font-weight: 700;
			&:hover {
				background-color: $blue-700;
				color: #ffffff;
			}
		}
	}
	&-combobox {
		display: block;
		width: 100%;
		border: 0;
		background-color: $blue-50;
		box-shadow: inset 4px 4px 2px #e0ebf5;
		.k-select {
			background-color: transparent;
			border: 0;
			background-image: url(/static/img/chevron-down.svg);
			background-position: left;
			background-size: 12px;
			background-repeat: no-repeat;
			.k-icon {
				display: none;
			}
		}
		.k-dropdown-wrap {
			background-color: transparent;
			border: 1px solid $blue-300;
			&.k-state-border-down {
				padding-bottom: 0;
				.k-i-close {
					margin-top: 0;
				}
			}
		}
		.k-input {
			box-shadow: none;
			background-color: transparent;
		}
		+ .text-danger {
			display: none;
			margin-bottom: 0;
			margin-top: 2px;
		}
		&.has-error {
			+ .text-danger {
				display: inline-block;
			}
		}
	}
	&-trophon-warning {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		fill: currentColor;
		color: #e4002b;
		font-size: 1rem;
		&__tooltip {
			display: flex;
			min-height: 24px;
			align-items: center;
			padding: 7px 0;
		}
	}
	&-tooltip {
		font-size: 0.8rem;
		border-radius: 4px;
	}
	&-link {
		&__tooltip {
			padding-right: 0;
		}
	}
	&-locked {
		font-size: 1rem;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #ff8200;
	}
}

.vue-tags-input {
	width: 100%;
	padding: 7px 12px;
	min-height: 40px;
	font-size: 12px;
	font-size: 0.75rem;
	color: #6d6e71;
	background: #f4f8fb !important;
	border: 1px solid #97cfe1;
	border-radius: 2px;
	box-shadow: inset 4px 4px 2px #e0ebf5;
	.ti-input {
		padding: 0;
		border: 0;
	}
	.ti-new-tag-input-wrapper {
		padding: 0;
		margin: 0;
	}
	.ti-new-tag-input {
		background: transparent;
		box-shadow: none;
		min-height: auto;
		padding: 4px 5px !important;
	}
	.ti-tag {
		font-size: 12px;
		background: $blue-700;
		padding: 5px 8px;
		margin: 4px 4px 2px 4px;
	}
}
