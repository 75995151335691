/*crit:start*/
.o-sidemenu{
	
	li{
		>a{
			display: block;
			padding: 8px 10px;
			color: $blue-300;
			&:hover,
			&:focus{
				border-right:4px solid $blue-500;
				background: rgba($blue-700, 0.2);
				text-decoration: none;
				font-weight: 700;
				color: white;
			}
		}
		&.active{
			>a{
				border-right:4px solid $blue-500;
				background: rgba($blue-700, 0.2);
				font-weight: 700;
				color: white;
			}
		}
	}

	&-tabs{
		li{
			margin-bottom: 10px;
			>a{
				text-align: center;
				border-radius: 4px;
				border-bottom:4px solid transparent;
				max-width: 108px;
				max-height: 94px;
				padding-top: 22px;
				padding-bottom: 22px;
				font-size: 12px;
				font-weight: 600;
				span{
					display: block;
					font-size: 34px;
				}
				&:hover,
				&:focus{
					border-right:0;
					border-bottom:4px solid $blue-500;
					background: $blue-700;
					text-decoration: none;
				}
			}
			&.active{
				>a{
					border-right:0;
					border-bottom:4px solid $blue-500;
					background: $blue-700;
					font-weight: normal;
				}
			}
		}
		
	}
	.g-icon-mobile {
		font-size: 1.6rem;
		margin-bottom: 0.5rem;
	}
}
/*crit:end*/