.o-step{
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	/*crit*/
	&__item{
		span{
			background: $blue-200;
			color: $blue-700;
			width: 32px;
			height: 32px;
			border-radius: 32px;
			font-size: 15px;
			font-weight: 700;
			text-align: center;
			line-height: 32px;
			display: inline-block;
			position: relative;
			z-index: 1;
			/*crit*/
		}
		&:not(:last-child){
			margin-right: 90px;
			/*crit*/	
			&:after{
				content:"";
				display: inline-block;
				width: 100px;
				height: 4px;
				background-color: $blue-200;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				/*crit*/
				z-index: 1;
			}
		}
		&.is-active{
			span{
				background-color: $blue-500;
				color: white;
			}
		}
	}
}