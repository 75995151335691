.o-inputbox {
    $_this: &;
    &__box {
        input:checked~&,
        .is-selected & {
            opacity: 1;
            /* crit */
        }
    }
    &__text {
        input:checked ~ & {
			color: #fff;
		}
    }
}
