@keyframes spin{
	0%{
		transform: rotate(0);
	}
	100%{
		transform: rotate(360deg);
	}
}

@keyframes appearFromBottom{
	0%{
		transform: translateY(100%);
	}
	100%{
		transform: none;
	}
}

.animate-spin{
	animation: spin 1s forwards linear infinite;
}