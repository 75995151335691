
// COLORS
$darkgray: $black;
$base-gray: hsla(203, 15.8537%, 32.1569%, 1.0000);
$gray-saturate-85: saturate($base-gray, 85%);
$button-active: $blue-500;

// TRANSITION SPEEDS
$transition-fast: 0.15s;

// ON OFF SWITCH
$switch-height: 32px;
$switch-width: 60px;
$switch-group-height: $switch-height;
$toggle-group-height: $switch-height;

// MIXINS
@mixin box-sizing($sizing: border-box){
    -moz-box-sizing: $sizing;
    -webkit-box-sizing: $sizing;
    box-sizing: $sizing;
}


@mixin transition($attr,$time,$ease){
    -webkit-transition: $attr $time $ease;
    -o-transition: $attr $time $ease;
    -moz-transition: $attr $time $ease;
    transition: $attr $time $ease;
}

@mixin switch-border-radius($radius){
    -moz-border-radius: $radius;
    -webkit-border-radius: $radius;
    border-radius: $radius;
}

/* ON/OFF Switch */
/* crit:start */
.onoffswitch {
    position: relative;
    width: $switch-width;
    display: inline-block;
    font-weight: 700;

    .onoffswitch-label {
        display: block;
        overflow: hidden;
        cursor: pointer;
        @include switch-border-radius($switch-height - 2);
        margin: 0;
    }

    .onoffswitch-inner {
        width: 200%;
        margin-left: -100%;
        @include transition(margin, $transition-fast, ease-in-out);

        &:before,
        &:after {
            float: left;
            width: 50%;
            height: $switch-group-height;
            padding: 0;
            line-height: $switch-group-height;
            font-size: 80%;
            color: $white;
            font-weight: normal;
            @include box-sizing(border-box);
        }

        &:before {
            @include iconfont;
            content: icon('tick');
            padding-left: 10px;
            background-color: $button-active;
            color: $white;
            font-weight: 700;
            font-size: 18px;
            line-height: 1.7;
        }

        &:after {
            @include iconfont;
            content: icon('close');
            padding-right: 8px;
            background-color: $blue-100;
            color: rgba($blue-700, 0.5);
            text-align: right;
            font-weight: 700;
            font-size: 18px;
            line-height: 1.7;
        }
    }

    .onoffswitch-switch {
        width: $switch-height - 8;
        height: $switch-height - 8;
        margin: 0;
        background: $white;
        box-shadow: 0 0 3px rgba(0,0,0,.3);
        @include switch-border-radius(50%);
        position: absolute;
        top: 2px + 2;
        bottom: 0;
        right: $switch-height;
        @include transition(right, $transition-fast, ease-in-out);
    }
}

.toggle-group {
    position: relative;
    height: $toggle-group-height;
    top: 50%;
    left: 0;
    display: inline-block;
    //margin-top: -$toggle-group-height/2;

    input[type=checkbox] {
        position: absolute;
        left: 20px;

        &:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-inner {
            margin-left: 0;
        }

        &:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-switch {
            right: 1px + 4;
            box-shadow: 0 0 3px rgba(0,0,0,.5);
        }

        &:focus  ~ .onoffswitch {
            outline: thin dotted #333;
            outline: 0;
        }
    }

    label {
        position: absolute;
        cursor: pointer;
        padding-left: $switch-width + 10;
        display: inline-block;
        text-align: left;
        line-height: $switch-group-height;
        width: 100%;
        z-index: 1;
        height: $switch-group-height;
        font-weight: 200;
        margin-bottom: 0;
    }
    &.is-small{
        height: $toggle-group-height - 8;
        label {
            padding-left: $switch-width + 10;
            line-height: $switch-group-height;
            height: $switch-group-height - 8;
        }
        input[type=checkbox] {
            
            &:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-switch {
                right: 1px + 2;
            }
        }
        .onoffswitch{
            width: $switch-width - 12;
            .onoffswitch-switch {
                width: $switch-height - 14;
                height: $switch-height - 14;
                top: 3px;
                right: 27px;
            }
            .onoffswitch-inner {
            
                &:before,
                &:after {
                    height: $switch-group-height - 8;
                    line-height: $switch-group-height - 8;
                }

                &:before {
                    padding-left: 8px;
                    font-size: 12px;
                    line-height: 2;
                }

                &:after {
                    padding-right: 8px;
                    font-size: 12px;
                    line-height: 2;
                }
            }
        }

        
    }

}
/* crit:end */


/* ==== Accessibility ===== */
.aural {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    width: 1px;

    &:focus {
        clip: rect(0, 0, 0, 0);
        font-size: 1em;
        height: auto;
        outline: thin dotted;
        position: static !important;
        width: auto;
        overflow: visible;
        
    }
}