.o-round-icon {
    width: 72px;
    height: 72px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 2.8rem;
    color: #FFFFFF;
    background-color: #00b140;
    border-radius: 50%;
}