@charset "UTF-8";
@keyframes spin {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@keyframes appearFromBottom {
  0% {
    transform: translateY(100%); }
  100% {
    transform: none; } }

.animate-spin {
  animation: spin 1s forwards linear infinite; }

@keyframes bs-notify-fadeOut {
  0% {
    opacity: 0.9; }
  100% {
    opacity: 0; } }

select.bs-select-hidden,
.bootstrap-select > select.bs-select-hidden,
select.selectpicker {
  display: none !important;
  /*crit*/ }

/* crit:start */
.bootstrap-select {
  width: 220px \0;
  /*IE9 and below*/
  vertical-align: middle; }
  .bootstrap-select > .dropdown-toggle {
    position: relative;
    width: 100%;
    text-align: right;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: space-between; }
    .bootstrap-select > .dropdown-toggle:after {
      margin-top: -1px; }
    .bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:active {
      color: #999; }
    .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:active {
      color: rgba(255, 255, 255, 0.5); }
  .bootstrap-select > select {
    position: absolute !important;
    bottom: 0;
    left: 50%;
    display: block !important;
    width: 0.5px !important;
    height: 100% !important;
    padding: 0 !important;
    opacity: 0 !important;
    border: none;
    z-index: 0 !important; }
    .bootstrap-select > select.mobile-device {
      top: 0;
      left: 0;
      display: block !important;
      width: 100% !important;
      z-index: 2 !important; }
  .has-error .bootstrap-select .dropdown-toggle,
  .error .bootstrap-select .dropdown-toggle,
  .bootstrap-select.is-invalid .dropdown-toggle,
  .was-validated .bootstrap-select .selectpicker:invalid + .dropdown-toggle {
    border-color: #b94a48; }
  .bootstrap-select.is-valid .dropdown-toggle,
  .was-validated .bootstrap-select .selectpicker:valid + .dropdown-toggle {
    border-color: #28a745; }
  .bootstrap-select.fit-width {
    width: auto !important; }
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 220px; }
  .bootstrap-select > select.mobile-device:focus + .dropdown-toggle,
  .bootstrap-select .dropdown-toggle:focus {
    outline: thin dotted #333333 !important;
    outline: 5px auto -webkit-focus-ring-color !important;
    outline-offset: -2px; }

.bootstrap-select.form-control {
  margin-bottom: 0;
  padding: 0;
  border: none;
  height: auto; }
  :not(.input-group) > .bootstrap-select.form-control:not([class*="col-"]) {
    width: 100%; }
  .bootstrap-select.form-control.input-group-btn {
    float: none;
    z-index: auto; }

.form-inline .bootstrap-select,
.form-inline .bootstrap-select.form-control:not([class*="col-"]) {
  width: auto; }

.bootstrap-select:not(.input-group-btn), .bootstrap-select[class*="col-"] {
  float: none;
  display: inline-block;
  margin-left: 0; }

.bootstrap-select.dropdown-menu-right, .bootstrap-select[class*="col-"].dropdown-menu-right,
.row .bootstrap-select[class*="col-"].dropdown-menu-right {
  float: right; }

.form-inline .bootstrap-select,
.form-horizontal .bootstrap-select,
.form-group .bootstrap-select {
  margin-bottom: 0; }

.form-group-lg .bootstrap-select.form-control,
.form-group-sm .bootstrap-select.form-control {
  padding: 0; }
  .form-group-lg .bootstrap-select.form-control .dropdown-toggle,
  .form-group-sm .bootstrap-select.form-control .dropdown-toggle {
    height: 100%;
    font-size: inherit;
    line-height: inherit;
    border-radius: inherit; }

.bootstrap-select.form-control-sm .dropdown-toggle,
.bootstrap-select.form-control-lg .dropdown-toggle {
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit; }

.bootstrap-select.form-control-sm .dropdown-toggle {
  padding: 0.25rem 0.5rem; }

.bootstrap-select.form-control-lg .dropdown-toggle {
  padding: 0.5rem 1rem; }

.form-inline .bootstrap-select .form-control {
  width: 100%; }

.bootstrap-select.disabled,
.bootstrap-select > .disabled {
  cursor: not-allowed; }
  .bootstrap-select.disabled:focus,
  .bootstrap-select > .disabled:focus {
    outline: none !important; }

.bootstrap-select.bs-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 0 !important;
  padding: 0 !important; }
  .bootstrap-select.bs-container .dropdown-menu {
    z-index: 1060; }

.bootstrap-select .dropdown-toggle {
  border-radius: 2px; }
  .bootstrap-select .dropdown-toggle .filter-option {
    position: static;
    top: 0;
    left: 0;
    float: left;
    height: 100%;
    width: 100%;
    text-align: left;
    overflow: hidden;
    flex: 0 1 auto; }
    .bs3.bootstrap-select .dropdown-toggle .filter-option {
      padding-right: inherit; }
    .input-group .bs3-has-addon.bootstrap-select .dropdown-toggle .filter-option {
      position: absolute;
      padding-top: inherit;
      padding-bottom: inherit;
      padding-left: inherit;
      float: none; }
      .input-group .bs3-has-addon.bootstrap-select .dropdown-toggle .filter-option .filter-option-inner {
        padding-right: inherit; }
  .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
    overflow: hidden; }
  .bootstrap-select .dropdown-toggle .filter-expand {
    width: 0 !important;
    float: left;
    opacity: 0 !important;
    overflow: hidden; }
  .bootstrap-select .dropdown-toggle .caret {
    position: absolute;
    top: 50%;
    right: 12px;
    margin-top: -2px;
    vertical-align: middle; }

.input-group .bootstrap-select.form-control .dropdown-toggle {
  border-radius: inherit; }

.bootstrap-select[class*="col-"] .dropdown-toggle {
  width: 100%; }

.bootstrap-select .dropdown-menu {
  min-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  .bootstrap-select .dropdown-menu > .inner:focus {
    outline: none !important; }
  .bootstrap-select .dropdown-menu.inner {
    position: static;
    float: none;
    border: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
    box-shadow: none; }
  .bootstrap-select .dropdown-menu li {
    position: relative; }
    .bootstrap-select .dropdown-menu li.active small {
      color: rgba(255, 255, 255, 0.5) !important; }
    .bootstrap-select .dropdown-menu li.disabled a {
      cursor: not-allowed; }
    .bootstrap-select .dropdown-menu li a {
      cursor: pointer;
      user-select: none; }
      .bootstrap-select .dropdown-menu li a.opt {
        position: relative;
        padding-left: 2.25em; }
      .bootstrap-select .dropdown-menu li a span.check-mark {
        display: none; }
      .bootstrap-select .dropdown-menu li a span.text {
        display: inline-block; }
    .bootstrap-select .dropdown-menu li small {
      padding-left: 0.5em; }
  .bootstrap-select .dropdown-menu .notify {
    position: absolute;
    bottom: 5px;
    width: 96%;
    margin: 0 2%;
    min-height: 26px;
    padding: 3px 5px;
    background: whitesmoke;
    border: 1px solid #e3e3e3;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    pointer-events: none;
    opacity: 0.9;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
    .bootstrap-select .dropdown-menu .notify.fadeOut {
      animation: 300ms linear 750ms forwards bs-notify-fadeOut; }

.bootstrap-select .no-results {
  padding: 3px;
  background: #f5f5f5;
  margin: 0 5px;
  white-space: nowrap; }

.bootstrap-select.fit-width .dropdown-toggle .filter-option {
  position: static;
  display: inline;
  padding: 0; }

.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner,
.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner-inner {
  display: inline; }

.bootstrap-select.fit-width .dropdown-toggle .bs-caret:before {
  content: '\00a0'; }

.bootstrap-select.fit-width .dropdown-toggle .caret {
  position: static;
  top: auto;
  margin-top: -1px; }

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  top: 5px; }

.bootstrap-select.show-tick .dropdown-menu li a span.text {
  margin-right: 34px; }

.bootstrap-select .bs-ok-default:after {
  content: '';
  display: block;
  width: 0.5em;
  height: 1em;
  border-style: solid;
  border-width: 0 0.26em 0.26em 0;
  transform: rotate(45deg); }

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle {
  z-index: 1061; }

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:before {
  content: '';
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(204, 204, 204, 0.2);
  position: absolute;
  bottom: -4px;
  left: 9px;
  display: none; }

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:after {
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  bottom: -4px;
  left: 10px;
  display: none; }

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:before {
  bottom: auto;
  top: -4px;
  border-top: 7px solid rgba(204, 204, 204, 0.2);
  border-bottom: 0; }

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:after {
  bottom: auto;
  top: -4px;
  border-top: 6px solid white;
  border-bottom: 0; }

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:before {
  right: 12px;
  left: auto; }

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:after {
  right: 13px;
  left: auto; }

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:before, .bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:after,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:before,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:after {
  display: block; }

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
  padding: 4px 8px; }

.bs-actionsbox {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  .bs-actionsbox .btn-group button {
    width: 50%; }

.bs-donebutton {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  .bs-donebutton .btn-group button {
    width: 100%; }

.bs-searchbox + .bs-actionsbox {
  padding: 0 8px 4px; }

.bs-searchbox .form-control {
  margin-bottom: 0;
  width: 100%;
  float: none; }

.bootstrap-select .dropdown-toggle:focus {
  outline: thin dotted #333333 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: -2px; }

.bootstrap-select.btn-group.disabled, .bootstrap-select.btn-group > .disabled {
  cursor: not-allowed;
  opacity: .7; }

.bootstrap-select.btn-group.disabled:focus,
.bootstrap-select.btn-group > .disabled:focus {
  outline: none !important; }

/* crit:end */
.bootstrap-select .selectpicker-flag {
  width: 30px;
  height: 30px;
  border-radius: 40px;
  background: #00465e;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  /*crit*/ }
  .bootstrap-select .selectpicker-flag.is-en {
    background-image: url(../img/flag-english.png); }
  .bootstrap-select .selectpicker-flag.is-us {
    background-image: url(../img/flag-us.png); }
  .bootstrap-select .selectpicker-flag.is-gr {
    background-image: url(../img/flag-german.png); }
  .bootstrap-select .selectpicker-flag.is-fr {
    background-image: url(../img/flag-french.png); }
  .bootstrap-select .selectpicker-flag.is-rs {
    background-image: url(../img/flag-russian.png); }
  .bootstrap-select .selectpicker-flag.is-sp {
    background-image: url(../img/flag-spanish.png); }

.bootstrap-select .selectpicker-label {
  font-size: 13px;
  font-weight: 700;
  /*crit*/ }

/* crit:start */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(35, 31, 32, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #00465e;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #000d12;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #5b5b5b;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.3333333333%; }

.offset-2 {
  margin-left: 16.6666666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.3333333333%; }

.offset-5 {
  margin-left: 41.6666666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.3333333333%; }

.offset-8 {
  margin-left: 66.6666666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.3333333333%; }

.offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 768px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.3333333333%; }
  .offset-sm-2 {
    margin-left: 16.6666666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.3333333333%; }
  .offset-sm-5 {
    margin-left: 41.6666666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.3333333333%; }
  .offset-sm-8 {
    margin-left: 66.6666666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.3333333333%; }
  .offset-sm-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 992px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.3333333333%; }
  .offset-md-2 {
    margin-left: 16.6666666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.3333333333%; }
  .offset-md-5 {
    margin-left: 41.6666666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.3333333333%; }
  .offset-md-8 {
    margin-left: 66.6666666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.3333333333%; }
  .offset-md-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 1200px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.3333333333%; }
  .offset-lg-2 {
    margin-left: 16.6666666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.3333333333%; }
  .offset-lg-5 {
    margin-left: 41.6666666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.3333333333%; }
  .offset-lg-8 {
    margin-left: 66.6666666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.3333333333%; }
  .offset-lg-11 {
    margin-left: 91.6666666667%; } }

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #00a5de;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 70, 94, 0.25); }
  .form-control::placeholder {
    color: #5b5b5b;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #a7a9ac;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc( 1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc( 2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #5b5b5b; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: 2.25rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: 2.25rem;
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e4002b; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(228, 0, 43, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #e4002b;
  padding-right: 2.25rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23e4002b' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23e4002b' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #e4002b;
    box-shadow: 0 0 0 0.2rem rgba(228, 0, 43, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: 2.25rem;
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #e4002b;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23e4002b' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23e4002b' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #e4002b;
    box-shadow: 0 0 0 0.2rem rgba(228, 0, 43, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #e4002b; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #e4002b; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #e4002b; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ff1844;
  background-color: #ff1844; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(228, 0, 43, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e4002b; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e4002b; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #e4002b;
  box-shadow: 0 0 0 0.2rem rgba(228, 0, 43, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 768px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 70, 94, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #00465e;
  border-color: #00465e; }
  .btn-primary:hover {
    color: #fff;
    background-color: #002a38;
    border-color: #00202b; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 98, 118, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #00465e;
    border-color: #00465e; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #00202b;
    border-color: #00171e; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 98, 118, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #5b5b5b;
  border-color: #5b5b5b; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #484848;
    border-color: #424141; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(116, 116, 116, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #5b5b5b;
    border-color: #5b5b5b; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #424141;
    border-color: #3b3b3b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(116, 116, 116, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #e4002b;
  border-color: #e4002b; }
  .btn-danger:hover {
    color: #fff;
    background-color: #be0024;
    border-color: #b10021; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 38, 75, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #e4002b;
    border-color: #e4002b; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #b10021;
    border-color: #a4001f; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(232, 38, 75, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #00465e;
  border-color: #00465e; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #00465e;
    border-color: #00465e; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 70, 94, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #00465e;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #00465e;
    border-color: #00465e; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 70, 94, 0.5); }

.btn-outline-secondary {
  color: #5b5b5b;
  border-color: #5b5b5b; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #5b5b5b;
    border-color: #5b5b5b; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(91, 91, 91, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #5b5b5b;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #5b5b5b;
    border-color: #5b5b5b; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(91, 91, 91, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #e4002b;
  border-color: #e4002b; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #e4002b;
    border-color: #e4002b; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(228, 0, 43, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #e4002b;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #e4002b;
    border-color: #e4002b; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(228, 0, 43, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #00465e;
  text-decoration: none; }
  .btn-link:hover {
    color: #000d12;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #5b5b5b;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(35, 31, 32, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 768px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #a7a9ac; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #00465e; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #5b5b5b;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #5b5b5b;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #a7a9ac;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc( 2.875rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc( 1.8125rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #5b5b5b;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #a7a9ac #a7a9ac #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #5b5b5b;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #00465e; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #00465e;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #000d12;
    text-decoration: none;
    background-color: #a7a9ac;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 70, 94, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #00465e;
  border-color: #00465e; }

.page-item.disabled .page-link {
  color: #5b5b5b;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #00465e; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #00202b; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 70, 94, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #5b5b5b; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #424141; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(91, 91, 91, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #e4002b; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #b10021; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(228, 0, 43, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #113340;
  background-color: #ccdadf;
  border-color: #b8cbd2; }
  .alert-primary hr {
    border-top-color: #a8c0c8; }
  .alert-primary .alert-link {
    color: #061318; }

.alert-secondary {
  color: #403e3f;
  background-color: #dedede;
  border-color: #d1d1d1; }
  .alert-secondary hr {
    border-top-color: #c4c4c4; }
  .alert-secondary .alert-link {
    color: #262526; }

.alert-success {
  color: #266633;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #184120; }

.alert-info {
  color: #1d636f;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #123f47; }

.alert-warning {
  color: #957313;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #68500d; }

.alert-danger {
  color: #870f26;
  background-color: #faccd5;
  border-color: #f7b8c4; }
  .alert-danger hr {
    border-top-color: #f4a1b1; }
  .alert-danger .alert-link {
    color: #590a19; }

.alert-light {
  color: #929091;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #797678; }

.alert-dark {
  color: #2c2d31;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #141416; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #231f20;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #231f20;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(35, 31, 32, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #231f20; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #a7a9ac;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #a7a9ac;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 1200px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

.modal-xl {
  max-width: 1140px; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 768px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 768px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

/* crit:start */
/* crit:end */
/* crit:start */
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 0 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }
  .hamburger.is-active:hover {
    opacity: 0.7; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #353535; }

.hamburger-box {
  width: 30px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 30px;
    height: 2px;
    background-color: #353535;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -11px; }
  .hamburger-inner::after {
    bottom: -11px; }

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex .hamburger-inner::after {
    transition-property: bottom, transform; }

.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(90deg); }

/* crit:end */
/* crit:start */
@font-face {
  font-family: "g-icons";
  src: url("/static/fonts/g-icons.eot?937d9a");
  src: url("/static/fonts/g-icons.eot?937d9a#iefix") format("eot"), url("/static/fonts/g-icons.woff2?937d9a") format("woff2"), url("/static/fonts/g-icons.woff?937d9a") format("woff"), url("/static/fonts/g-icons.ttf?937d9a") format("truetype"), url("/static/fonts/g-icons.svg?937d9a#g-icons") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="g-icon-"], [class*=" g-icon-"], .g-icon {
  font-family: 'g-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.g-icon-alert-circle:before {
  content: ""; }

.g-icon-arrow-down:before {
  content: ""; }

.g-icon-arrow-left:before {
  content: ""; }

.g-icon-arrow-right:before {
  content: ""; }

.g-icon-arrow-top-right:before {
  content: ""; }

.g-icon-barcode:before {
  content: ""; }

.g-icon-calendar:before {
  content: ""; }

.g-icon-chemical:before {
  content: ""; }

.g-icon-chev-down:before {
  content: ""; }

.g-icon-chev-left:before {
  content: ""; }

.g-icon-chev-right:before {
  content: ""; }

.g-icon-chev-up:before {
  content: ""; }

.g-icon-close:before {
  content: ""; }

.g-icon-cog:before {
  content: ""; }

.g-icon-download:before {
  content: ""; }

.g-icon-drag-cursor:before {
  content: ""; }

.g-icon-edit:before {
  content: ""; }

.g-icon-envelope:before {
  content: ""; }

.g-icon-eye:before {
  content: ""; }

.g-icon-facebook:before {
  content: ""; }

.g-icon-info:before {
  content: ""; }

.g-icon-instagram:before {
  content: ""; }

.g-icon-link-alt:before {
  content: ""; }

.g-icon-link:before {
  content: ""; }

.g-icon-list:before {
  content: ""; }

.g-icon-lock:before {
  content: ""; }

.g-icon-mobile:before {
  content: ""; }

.g-icon-notification:before {
  content: ""; }

.g-icon-pie-chart:before {
  content: ""; }

.g-icon-pin:before {
  content: ""; }

.g-icon-plus:before {
  content: ""; }

.g-icon-question-circle:before {
  content: ""; }

.g-icon-search:before {
  content: ""; }

.g-icon-settings:before {
  content: ""; }

.g-icon-tick:before {
  content: ""; }

.g-icon-trophon:before {
  content: ""; }

.g-icon-ultrasound:before {
  content: ""; }

.g-icon-upload:before {
  content: ""; }

.g-icon-user:before {
  content: ""; }

.g-icon-warning:before {
  content: ""; }

.g-icon-wifi-off:before {
  content: ""; }

.g-icon-wifi:before {
  content: ""; }

/* crit:end */
.ui-widget.ui-widget-content {
  border-color: #97cfe1; }

.ui-datepicker .ui-datepicker-title {
  display: flex;
  justify-content: space-around; }

.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-prev {
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer; }
  .ui-datepicker .ui-datepicker-next.ui-state-hover,
  .ui-datepicker .ui-datepicker-prev.ui-state-hover {
    top: 50%;
    transform: translateY(-50%); }
  .ui-datepicker .ui-datepicker-next.ui-datepicker-next-hover,
  .ui-datepicker .ui-datepicker-prev.ui-datepicker-next-hover {
    right: 2px; }
  .ui-datepicker .ui-datepicker-next.ui-datepicker-prev-hover,
  .ui-datepicker .ui-datepicker-prev.ui-datepicker-prev-hover {
    left: 2px; }

.ui-datepicker table:not(.u-table-unstyled) {
  border: 0; }
  .ui-datepicker table:not(.u-table-unstyled) td {
    padding: 1px;
    border: 0; }
    .ui-datepicker table:not(.u-table-unstyled) td a {
      padding: 8px 4px;
      text-align: center; }
  .ui-datepicker table:not(.u-table-unstyled) th {
    padding: 10px 1px;
    border: 0; }
  .ui-datepicker table:not(.u-table-unstyled) tr:hover td {
    background-color: #fff; }

.ui-widget-header {
  border: 0;
  background: #00a7e0;
  color: #fff;
  border-radius: 0; }

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
  border: 0; }

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  background: none;
  text-indent: inherit; }
  .ui-datepicker .ui-datepicker-prev span:before,
  .ui-datepicker .ui-datepicker-next span:before {
    font-family: 'g-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    color: white;
    content: ""; }
  .ui-datepicker .ui-datepicker-prev span:hover,
  .ui-datepicker .ui-datepicker-next span:hover {
    background: none; }

.ui-datepicker .ui-datepicker-next span:before {
  content: ""; }

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  border: 0; }
  .ui-datepicker .ui-datepicker-prev:hover,
  .ui-datepicker .ui-datepicker-next:hover {
    background: none;
    border: 0; }

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  background: #00a7e0; }

.ui-autocomplete {
  max-height: 100px;
  overflow-y: auto;
  /* prevent horizontal scrollbar */
  overflow-x: hidden; }

/* IE 6 doesn't support max-height
   * we use height instead, but this forces the menu to always be this tall
   */
* html .ui-autocomplete {
  height: 100px; }

.k-grid {
  border: 0; }
  .k-grid-header {
    /*background-color: $white;
            border-color:  $white;*/
    border-color: #e6f1fa;
    border-bottom-width: 2px !important;
    padding-right: 0 !important; }
    .k-grid-header th.k-header {
      border-width: 0;
      padding-bottom: 15px;
      padding-left: 12px; }
    .k-grid-header th.k-header > .k-link {
      color: #231f20;
      font-weight: 700; }
    .k-grid-header-wrap {
      border-color: #fff;
      border: 0; }
    .k-grid-header .k-header {
      background: #fff;
      padding-bottom: 10px; }
  .k-grid td {
    border-color: #e6f1fa; }
  .k-grid-content {
    overflow-y: auto; }
    .k-grid-content table .k-command-cell {
      padding: 5px 6px;
      text-align: right; }
    .k-grid-content table td:last-child {
      text-align: left;
      padding-right: 8px; }
      .k-grid-content table td:last-child .k-button {
        margin-left: auto;
        margin-right: 0; }
        .k-grid-content table td:last-child .k-button:not(.k-grid-View) {
          display: block; }

.k-pager-wrap {
  background-color: #e6f1fa;
  border-color: #e6f1fa;
  padding-top: 10px;
  padding-bottom: 10px; }
  .k-pager-wrap .k-link {
    border: 0;
    font-size: 12px;
    line-height: inherit;
    font-weight: 600;
    background: white;
    border-radius: 2px;
    margin-right: 2px;
    padding-left: 2px;
    padding-right: 2px;
    color: #00769d; }
    .k-pager-wrap .k-link.k-pager-nav {
      background: transparent; }

.k-pager-numbers .k-state-selected {
  background: #00769d;
  border-color: #00769d;
  border-top: 0;
  color: white;
  font-size: 12px;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 2px;
  font-weight: 600; }

.k-pager-sizes, .k-pager-info {
  font-size: 12px; }

.k-grid tbody .k-button-icontext {
  width: 40px;
  min-width: 40px; }
  .k-grid tbody .k-button-icontext:hover {
    background-color: #fff; }

.k-button.k-grid-Edit {
  border: 1px solid #97cfe1;
  border-radius: 2px;
  box-shadow: none;
  background: transparent;
  overflow: hidden;
  width: 24px;
  height: 24px;
  display: inline-block;
  min-width: 20px !important;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  color: #00465e;
  font-size: 14px; }
  .k-button.k-grid-Edit:before {
    font-family: 'g-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: ""; }

.k-button.k-grid-View {
  box-shadow: none;
  border: 1px solid #97cfe1;
  border-radius: 2px;
  color: #00769d;
  background-color: #fff;
  display: inline-flex;
  align-items: center; }
  .k-button.k-grid-View:before {
    font-family: 'g-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    font-size: 1.1rem;
    margin-right: 0.3rem; }
  .k-button.k-grid-View:hover, .k-button.k-grid-View:focus {
    background-color: #00769d;
    color: white;
    border-color: #00769d; }

.k-button.k-grid-Viewsite {
  box-shadow: none;
  border: 1px solid #97cfe1;
  border-radius: 4px;
  background-color: #ffffff;
  color: #00769d;
  padding: 3px 15px;
  font-weight: 700; }
  .k-button.k-grid-Viewsite:hover {
    background-color: #00769d;
    color: #ffffff; }

.k-combobox {
  display: block;
  width: 100%;
  border: 0;
  background-color: #f4f8fb;
  box-shadow: inset 4px 4px 2px #e0ebf5; }
  .k-combobox .k-select {
    background-color: transparent;
    border: 0;
    background-image: url(/static/img/chevron-down.svg);
    background-position: left;
    background-size: 12px;
    background-repeat: no-repeat; }
    .k-combobox .k-select .k-icon {
      display: none; }
  .k-combobox .k-dropdown-wrap {
    background-color: transparent;
    border: 1px solid #97cfe1; }
    .k-combobox .k-dropdown-wrap.k-state-border-down {
      padding-bottom: 0; }
      .k-combobox .k-dropdown-wrap.k-state-border-down .k-i-close {
        margin-top: 0; }
  .k-combobox .k-input {
    box-shadow: none;
    background-color: transparent; }
  .k-combobox + .text-danger {
    display: none;
    margin-bottom: 0;
    margin-top: 2px; }
  .k-combobox.has-error + .text-danger {
    display: inline-block; }

.k-trophon-warning {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  fill: currentColor;
  color: #e4002b;
  font-size: 1rem; }
  .k-trophon-warning__tooltip {
    display: flex;
    min-height: 24px;
    align-items: center;
    padding: 7px 0; }

.k-tooltip {
  font-size: 0.8rem;
  border-radius: 4px; }

.k-link__tooltip {
  padding-right: 0; }

.k-locked {
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff8200; }

.vue-tags-input {
  width: 100%;
  padding: 7px 12px;
  min-height: 40px;
  font-size: 12px;
  font-size: 0.75rem;
  color: #6d6e71;
  background: #f4f8fb !important;
  border: 1px solid #97cfe1;
  border-radius: 2px;
  box-shadow: inset 4px 4px 2px #e0ebf5; }
  .vue-tags-input .ti-input {
    padding: 0;
    border: 0; }
  .vue-tags-input .ti-new-tag-input-wrapper {
    padding: 0;
    margin: 0; }
  .vue-tags-input .ti-new-tag-input {
    background: transparent;
    box-shadow: none;
    min-height: auto;
    padding: 4px 5px !important; }
  .vue-tags-input .ti-tag {
    font-size: 12px;
    background: #00769d;
    padding: 5px 8px;
    margin: 4px 4px 2px 4px; }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before, .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */
@media print {
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .collapse {
    height: auto !important;
    display: block; }
  [data-mh] {
    height: auto !important; } }

/* crit:start */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "g-icons";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "g-icons";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/* crit:end */
@media (min-width: 992px) {
  .slick-slider {
    user-select: initial; } }

.slick-slide {
  height: auto;
  flex: 1;
  outline: 0;
  /* crit */ }
  .slick-slide .lt-ie10 {
    height: 100%;
    /* crit */ }

.slick-track {
  display: flex;
  /* crit */ }
  .slick-track:before {
    content: none;
    /* crit */ }
  .lt-ie10 .slick-track:before {
    content: '';
    /* crit */ }

.slick-loading .slick-list {
  background: #fff 50% no-repeat;
  /* crit */ }

/*crit:start*/
.o-uploadfile {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 100%;
  padding: 2px 38px 2px 22px;
  border: 2px dashed #97cfe1;
  border-radius: 3px;
  transition: 0.2s;
  background: #f4f8fb; }
  .o-uploadfile.is-active {
    background-color: rgba(255, 255, 255, 0.05); }
  .o-uploadfile.is-small {
    width: 280px; }
  .o-uploadfile.is-big {
    padding-top: 40px;
    padding-bottom: 40px;
    justify-content: center; }
  .o-uploadfile__input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100% !important;
    width: 100% !important;
    cursor: pointer !important;
    opacity: 0; }
    .o-uploadfile__input:focus {
      outline: none; }
  .o-uploadfile__label {
    font-size: 12px;
    font-weight: 300;
    line-height: 1.4;
    white-space: nowrap;
    overflow: hidden;
    color: #5B5B5B;
    font-weight: 600;
    text-overflow: ellipsis; }
  .o-uploadfile__btn {
    flex-shrink: 0;
    background-color: white;
    border: 1px solid #97cfe1;
    border-radius: 2px;
    padding: 4px 10px;
    font-size: 12px;
    text-transform: capitalize;
    margin-left: 10px; }

/*crit:end*/
.dm-uploader {
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.dm-uploader .btn {
  position: relative;
  overflow: hidden; }

.dm-uploader .btn input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  border: solid transparent;
  width: 100%;
  opacity: 0;
  cursor: pointer; }

.ajax-file-upload-statusbar {
  border: 1px solid #0ba1b5;
  margin-top: 10px;
  width: 420px;
  margin-right: 10px;
  margin: 5px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  padding: 5px 5px 5px 15px; }

.ajax-file-upload-filename {
  width: 300px;
  height: auto;
  margin: 0 5px 5px 0px; }

.ajax-file-upload-filesize {
  width: 50px;
  height: auto;
  margin: 0 5px 5px 0px;
  display: inline-block;
  vertical-align: middle; }

.ajax-file-upload-progress {
  margin: 5px 10px 5px 0px;
  position: relative;
  width: 250px;
  border: 1px solid #ddd;
  padding: 1px;
  border-radius: 3px;
  display: inline-block;
  color: #FFFFFF; }

.ajax-file-upload-bar {
  background-color: #0ba1b5;
  width: 0;
  height: 20px;
  border-radius: 3px;
  color: #FFFFFF; }

.ajax-file-upload-percent {
  position: absolute;
  display: inline-block;
  top: 3px;
  left: 48%; }

.ajax-file-upload-red {
  -moz-box-shadow: inset 0 39px 0 -24px #e67a73;
  -webkit-box-shadow: inset 0 39px 0 -24px #e67a73;
  box-shadow: inset 0 39px 0 -24px #e67a73;
  background-color: #e4685d;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  display: inline-block;
  color: #fff;
  font-family: arial;
  font-size: 13px;
  font-weight: normal;
  padding: 4px 15px;
  text-decoration: none;
  text-shadow: 0 1px 0 #b23e35;
  cursor: pointer;
  vertical-align: top;
  margin: 5px 10px 5px 0px; }

.ajax-file-upload-green {
  background-color: #77b55a;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  margin: 0;
  padding: 0;
  display: inline-block;
  color: #fff;
  font-family: arial;
  font-size: 13px;
  font-weight: normal;
  padding: 4px 15px;
  text-decoration: none;
  cursor: pointer;
  text-shadow: 0 1px 0 #5b8a3c;
  vertical-align: top;
  margin: 5px 10px 5px 0px; }

.ajax-file-upload {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: bold;
  padding: 15px 20px;
  cursor: pointer;
  line-height: 20px;
  height: 25px;
  margin: 0 10px 10px 0;
  display: inline-block;
  background: #fff;
  border: 1px solid #e8e8e8;
  color: #888;
  text-decoration: none;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -moz-box-shadow: 0 2px 0 0 #e8e8e8;
  -webkit-box-shadow: 0 2px 0 0 #e8e8e8;
  box-shadow: 0 2px 0 0 #e8e8e8;
  padding: 6px 10px 4px 10px;
  color: #fff;
  background: #2f8ab9;
  border: none;
  -moz-box-shadow: 0 2px 0 0 #13648d;
  -webkit-box-shadow: 0 2px 0 0 #13648d;
  box-shadow: 0 2px 0 0 #13648d;
  vertical-align: middle; }

.ajax-file-upload:hover {
  background: #3396c9;
  -moz-box-shadow: 0 2px 0 0 #15719f;
  -webkit-box-shadow: 0 2px 0 0 #15719f;
  box-shadow: 0 2px 0 0 #15719f; }

.ajax-upload-dragdrop {
  border: 2px dotted #A5A5C7;
  width: 420px;
  color: #DADCE3;
  text-align: left;
  vertical-align: middle;
  padding: 10px 10px 0px 10px; }

.state-hover {
  border: 2px solid #A5A5C7; }

.ajax-file-upload-container {
  margin: 20px 0px 20px 0px; }

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */
::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

/* crit:start */
*,
*:before,
*:after {
  flex: 0 1 auto; }

html {
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: scrollbar; }

body {
  min-height: 100vh; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

input {
  font-size: 15px;
  line-height: 1.6;
  border-radius: 0;
  /* crit */ }
  input[type="search"], input[type="text"], input[type="password"], input[type="tel"], input[type="number"], input[type="email"] {
    box-sizing: border-box;
    width: 100%;
    padding: 7px 15px;
    min-height: 40px;
    font-size: 0.8rem;
    color: #6D6E71;
    background: #f4f8fb;
    border: 1px solid #97CFE1;
    border-radius: 2px;
    box-shadow: inset 4px 4px 2px #E0EBF5;
    /*crit*/ }
  input:invalid {
    box-shadow: none;
    /* crit */ }
  input::placeholder {
    color: rgba(91, 91, 91, 0.8);
    /* crit */ }
  input.error {
    border-color: red; }
  input::-ms-clear {
    display: none; }
  input[type=file] {
    /*cursor: pointer;
	  width: 146px;
	  height: 32px;
	  overflow: hidden;*/
    /*crit*/ }
    input[type=file]:before {
      /* width: 146px;
		  height: 32px;
		  font-size: 16px;
		  line-height: 32px;
		  content: 'Upload';
		  display: inline-block;
		  background: $blue-700;
		  color: white;
		  border-radius: 4px;
		  text-align: center;
		  font-size: 12px;
		  font-weight: 600;*/
      /*crit*/ }
  input::-webkit-file-upload-button {
    /*visibility: hidden;*/ }

hr {
  border: 0;
  border-bottom: 1px solid #d3e5f5; }

button {
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
  appearance: none; }

table:not(.u-table-unstyled) {
  width: 100%; }
  table:not(.u-table-unstyled) td,
  table:not(.u-table-unstyled) th {
    padding: 5px 12px; }
  table:not(.u-table-unstyled) th {
    font-size: 13px;
    font-weight: 700;
    color: #231f20;
    border-bottom: 2px solid #e6f1fa; }
  table:not(.u-table-unstyled) td {
    font-size: 12px;
    border-bottom: 1px solid #e6f1fa;
    border-left: 0; }
  table:not(.u-table-unstyled) tr td:first-child {
    border-left: 1px solid #e6f1fa; }
  table:not(.u-table-unstyled) tr td:last-child {
    border-right: 1px solid #e6f1fa; }
  table:not(.u-table-unstyled) tr:hover td {
    background-color: #e6f1fa; }
  table:not(.u-table-unstyled).is-last-right tr td:last-child {
    text-align: right; }

select, textarea {
  appearance: none;
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 7px 12px;
  min-height: 40px;
  font-size: 12px;
  color: #6D6E71;
  background: #f4f8fb;
  border: 1px solid #97CFE1;
  border-radius: 2px;
  box-shadow: inset 4px 4px 2px #E0EBF5; }
  select:disabled, textarea:disabled {
    opacity: .5; }
  select:focus, textarea:focus {
    box-shadow: 0 0 10px 0 rgba(204, 218, 225, 0.7);
    background-color: #fff; }

select:not(.selectpicker) {
  border-radius: 0;
  background: #f4f8fb url("/static/img/chevron-down.svg") calc(100% - 12px) 50% no-repeat;
  background-size: 12px auto;
  border: 1px solid #97CFE1;
  font-size: 12px;
  box-shadow: none;
  padding: .375rem 1.2rem .375rem .75rem;
  /*crit*/ }
  select:not(.selectpicker)::-ms-expand {
    display: none; }

textarea {
  overflow: auto;
  resize: none;
  resize: vertical; }

label {
  font-weight: 700;
  font-size: 13px; }

img {
  max-width: 100%;
  height: auto; }

.dropdown-menu {
  padding-left: 8px;
  padding-right: 8px;
  border-color: #d3e5f5; }

.dropdown-item {
  padding: 6px 8px; }
  .dropdown-item:focus, .dropdown-item:hover {
    background-color: #e6f1fa; }

.dropdown-toggle:after {
  border: 0;
  font-family: 'g-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 23px;
  /*crit*/ }

.dropdown-divider {
  border-color: #d3e5f5; }

.page-item .page-link.is-disabled {
  opacity: 0.5;
  cursor: default; }

/* 
	overwrite BT form 
*/
.form-group {
  margin-bottom: 15px; }

.text-danger {
  color: #e4002b;
  margin: 8px 0;
  font-size: 12px; }

input.invalid + .text-danger {
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 2px; }

.alert {
  padding: 10px 16px; }
  .alert [class^="g-icon-"],
  .alert [class*=" g-icon-"],
  .alert .g-icon {
    font-size: 20px;
    margin-right: 10px; }
  .alert-warning {
    background-color: rgba(255, 130, 0, 0.1);
    color: #231f20; }
    .alert-warning [class^="g-icon-"],
    .alert-warning [class*=" g-icon-"],
    .alert-warning .g-icon {
      color: #ff8200; }
    .alert-warning.is-bordered {
      border-color: rgba(255, 130, 0, 0.9);
      background: rgba(255, 130, 0, 0.05);
      border-radius: 0;
      font-size: 13px;
      padding: 16px; }
  .alert-info {
    background-color: rgba(0, 167, 224, 0.1);
    color: #00769d; }
    .alert-info [class^="g-icon-"],
    .alert-info [class*=" g-icon-"],
    .alert-info .g-icon {
      color: #00a7e0; }
    .alert-info.is-bordered {
      border-color: rgba(0, 167, 224, 0.9);
      background: rgba(0, 167, 224, 0.05);
      border-radius: 0;
      font-size: 13px;
      padding: 16px; }
  .alert-danger {
    background-color: rgba(228, 0, 43, 0.1);
    color: #231f20; }
    .alert-danger [class^="g-icon-"],
    .alert-danger [class*=" g-icon-"],
    .alert-danger .g-icon {
      color: #e4002b; }
    .alert-danger.is-bordered {
      border-color: rgba(228, 0, 43, 0.9);
      background: rgba(228, 0, 43, 0.05);
      border-radius: 0;
      font-size: 13px;
      padding: 16px; }
  .alert-success {
    background-color: rgba(0, 177, 64, 0.1);
    color: #008531; }
    .alert-success [class^="g-icon-"],
    .alert-success [class*=" g-icon-"],
    .alert-success .g-icon {
      color: #008531; }
    .alert-success.is-bordered {
      border-color: rgba(0, 177, 64, 0.9);
      background: rgba(0, 177, 64, 0.05);
      border-radius: 0;
      font-size: 13px;
      padding: 16px; }

.badge {
  padding: 5px 10px;
  font-size: 12px; }
  .badge-warning {
    color: #fff;
    background: #ff8200; }

.input-group-search {
  min-width: 280px;
  position: relative; }
  .input-group-search span {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #00769d;
    font-size: 22px;
    /*crit*/ }
  .input-group-search.is-autowidth {
    min-width: inherit; }

.input-group-grid {
  display: flex;
  align-items: center; }
  .input-group-grid__item {
    margin-left: 8px;
    margin-right: 8px; }
    .input-group-grid__item select.form-control {
      min-width: 185px; }
    .input-group-grid__item:last-child {
      margin-right: 0; }

.input-group-date .dropdown-toggle {
  border: 0;
  background: white;
  border: 1px solid #00769d;
  padding: 8px 12px;
  font-size: 12px;
  border-radius: 2px;
  color: #5b5b5b; }
  .input-group-date .dropdown-toggle:after {
    font-family: 'g-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    border: 0;
    vertical-align: middle;
    font-size: 18px;
    color: #00769d; }

.input-group-date select {
  background-color: #fff; }

.input-icon {
  position: relative; }
  .input-icon .icon {
    position: absolute;
    right: 10px;
    bottom: 3px;
    color: #00465e;
    font-size: 20px; }

.close {
  color: #00465e;
  text-shadow: none;
  opacity: 1; }

.modal.overlapse {
  z-index: 2000;
  background: rgba(0, 0, 0, 0.8); }

.modal-header {
  border-bottom: 0;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 32px; }

.modal-content {
  background-color: #e6f1fa; }

.modal-body {
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 0; }
  .modal-body-content {
    font-size: 12px; }
    .modal-body-content:not(:last-child) {
      margin-bottom: 25px; }
    .modal-body-content a:not(.modal-body-content__head) {
      font-weight: 700;
      text-decoration: underline; }
      .modal-body-content a:not(.modal-body-content__head):hover, .modal-body-content a:not(.modal-body-content__head):focus {
        text-decoration: none; }
    .modal-body-content__head {
      color: #231f20; }
      .modal-body-content__head-icon {
        right: 20px;
        top: 50%;
        color: #00769d;
        font-size: 22px;
        transform: translateY(-50%); }
        .modal-body-content__head-icon span {
          vertical-align: middle; }
      .modal-body-content__head:hover {
        text-decoration: none; }
      .modal-body-content__head.collapsed .modal-body-content__head-icon span:before {
        content: ""; }
  .modal-body label {
    font-size: 12px; }
  .modal-body .form-group {
    margin-bottom: 15px; }
    .modal-body .form-group.no-gutters {
      padding-bottom: 6px;
      margin-bottom: 6px; }
      .modal-body .form-group.no-gutters input {
        padding: 0 12px;
        min-height: 32px;
        height: calc(30px + 2px); }
      .modal-body .form-group.no-gutters select:not(.selectpicker) {
        min-height: 32px; }

.modal-footer {
  border-top: 0;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 32px; }
  .modal-footer-copy {
    border-bottom-right-radius: .3rem;
    border-bottom-left-radius: .3rem; }

@media (min-width: 1200px) {
  .modal-lg,
  .modal-xl {
    max-width: 1000px; } }

@media (min-width: 1200px) {
  .modal-md {
    max-width: 700px; } }

.tab-content > .tab-pane {
  height: 0;
  overflow: visible;
  display: none; }

.tab-content > .tab-pane.active {
  height: auto;
  display: block; }

.nav-pills .nav-link {
  border-radius: 0;
  color: #231f20;
  margin-bottom: 10px;
  /*crit*/ }
  .nav-pills .nav-link.active {
    border-right: 4px solid #00a7e0;
    background: none;
    color: #231f20;
    font-weight: 700;
    /*crit*/ }

.bootstrap-tagsinput {
  max-width: 100%;
  line-height: 22px;
  cursor: text;
  padding: 7px 12px;
  min-height: 40px;
  font-size: 12px;
  font-size: .75rem;
  color: #6d6e71;
  background: #f4f8fb;
  border: 1px solid #97cfe1;
  border-radius: 2px;
  box-shadow: inset 4px 4px 2px #e0ebf5; }

.bootstrap-tagsinput input {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  padding: 0 6px;
  margin: 0;
  width: auto;
  max-width: inherit;
  min-height: auto; }

.bootstrap-tagsinput.form-control input::-moz-placeholder {
  color: #777;
  opacity: 1; }

.bootstrap-tagsinput.form-control input:-ms-input-placeholder {
  color: #777; }

.bootstrap-tagsinput.form-control input::-webkit-input-placeholder {
  color: #777; }

.bootstrap-tagsinput input:focus {
  border: none;
  box-shadow: none; }

.bootstrap-tagsinput .tag {
  margin-right: 2px;
  color: white;
  font-size: 12px;
  padding: 5px 7px;
  margin-top: 1px; }

.bootstrap-tagsinput .tag [data-role="remove"] {
  margin-left: 8px;
  cursor: pointer; }

.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: "x";
  padding: 0px 2px; }

.bootstrap-tagsinput .tag [data-role="remove"]:hover {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); }

.bootstrap-tagsinput .tag [data-role="remove"]:hover:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

/* crit:end */
/* crit:start */
h1, h2, h3, h4, h5, h6 {
  margin: 0; }

h1,
.b-h1 {
  font-size: 40px;
  line-height: 1.2; }

h2,
.b-h2 {
  font-size: 36px;
  line-height: 1.2222222222; }

h3,
.b-h3 {
  font-size: 28px;
  line-height: 1.3571428571; }

h4,
.b-h4 {
  font-size: 24px;
  line-height: 1.5; }

h5,
.b-h5 {
  font-size: 18px;
  line-height: 1.5555555556; }

h6,
.b-h6 {
  font-size: 16px;
  line-height: 1.625; }

.h1 {
  font-size: 40px;
  line-height: 1.2; }

.h2 {
  font-size: 36px;
  line-height: 1.2222222222; }

.h3 {
  font-size: 28px;
  line-height: 1.3571428571; }

.h4 {
  font-size: 24px;
  line-height: 1.5; }

.h5 {
  font-size: 18px;
  line-height: 1.5555555556; }

.h6 {
  font-size: 16px;
  line-height: 1.625; }

.b-fsbodylarge {
  font-size: 17px;
  line-height: 1.7647058824; }

.b-fsbodyregular {
  font-size: 16px;
  line-height: 1.5; }

.b-fsbodysmall {
  font-size: 15px;
  line-height: 1.4666666667; }

.b-fstiny {
  font-size: 12px;
  line-height: 1.5; }

.b-fssmall {
  font-size: 13px;
  line-height: 1.5384615385; }

@media (min-width: 768px) {
  .h1-sm {
    font-size: 40px;
    line-height: 1.2; }
  .h2-sm {
    font-size: 36px;
    line-height: 1.2222222222; }
  .h3-sm {
    font-size: 28px;
    line-height: 1.3571428571; }
  .h4-sm {
    font-size: 24px;
    line-height: 1.5; }
  .h5-sm {
    font-size: 18px;
    line-height: 1.5555555556; }
  .h6-sm {
    font-size: 16px;
    line-height: 1.625; }
  .b-fsbodylarge-sm {
    font-size: 17px;
    line-height: 1.7647058824; }
  .b-fsbodyregular-sm {
    font-size: 16px;
    line-height: 1.5; }
  .b-fsbodysmall-sm {
    font-size: 15px;
    line-height: 1.4666666667; }
  .b-fstiny-sm {
    font-size: 12px;
    line-height: 1.5; }
  .b-fssmall-sm {
    font-size: 13px;
    line-height: 1.5384615385; } }

@media (min-width: 992px) {
  .h1-md {
    font-size: 40px;
    line-height: 1.2; }
  .h2-md {
    font-size: 36px;
    line-height: 1.2222222222; }
  .h3-md {
    font-size: 28px;
    line-height: 1.3571428571; }
  .h4-md {
    font-size: 24px;
    line-height: 1.5; }
  .h5-md {
    font-size: 18px;
    line-height: 1.5555555556; }
  .h6-md {
    font-size: 16px;
    line-height: 1.625; }
  .b-fsbodylarge-md {
    font-size: 17px;
    line-height: 1.7647058824; }
  .b-fsbodyregular-md {
    font-size: 16px;
    line-height: 1.5; }
  .b-fsbodysmall-md {
    font-size: 15px;
    line-height: 1.4666666667; }
  .b-fstiny-md {
    font-size: 12px;
    line-height: 1.5; }
  .b-fssmall-md {
    font-size: 13px;
    line-height: 1.5384615385; } }

@media (min-width: 1200px) {
  .h1-lg {
    font-size: 40px;
    line-height: 1.2; }
  .h2-lg {
    font-size: 36px;
    line-height: 1.2222222222; }
  .h3-lg {
    font-size: 28px;
    line-height: 1.3571428571; }
  .h4-lg {
    font-size: 24px;
    line-height: 1.5; }
  .h5-lg {
    font-size: 18px;
    line-height: 1.5555555556; }
  .h6-lg {
    font-size: 16px;
    line-height: 1.625; }
  .b-fsbodylarge-lg {
    font-size: 17px;
    line-height: 1.7647058824; }
  .b-fsbodyregular-lg {
    font-size: 16px;
    line-height: 1.5; }
  .b-fsbodysmall-lg {
    font-size: 15px;
    line-height: 1.4666666667; }
  .b-fstiny-lg {
    font-size: 12px;
    line-height: 1.5; }
  .b-fssmall-lg {
    font-size: 13px;
    line-height: 1.5384615385; } }

.b-fsbtn {
  font-size: 13px;
  line-height: 1.8461538462;
  font-weight: 500; }

blockquote {
  font-style: italic;
  font-size: 23px;
  line-height: 1.5652173913;
  quotes: "�" "�" "�" "�";
  padding-top: 62px;
  position: relative;
  color: #505050; }
  @media (min-width: 992px) {
    blockquote {
      padding-left: 18px; } }
  blockquote:before {
    font-family: 'g-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgba(0, 0, 0, 0.2);
    font-size: 40px;
    max-height: 40px;
    line-height: 1;
    position: absolute;
    top: 0;
    left: 0; }

p {
  margin-bottom: 20px; }
  p:empty {
    display: none; }

.lead {
  font-weight: 300;
  font-size: 21px;
  line-height: 1.4285714286; }

small,
.small {
  font-size: 13px;
  line-height: 1.5384615385; }

a {
  color: #00769d; }

/* crit:start */
.u-btn-unstyled {
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer; }
  .u-btn-unstyled:hover, .u-btn-unstyled:focus {
    text-decoration: none;
    background-color: transparent; }
  .u-btn-unstyled:disabled {
    cursor: auto; }

.u-list-unstyled {
  /* crit:start */
  list-style-type: none;
  margin: 0;
  padding: 0;
  /* crit:end */ }

.u-input-unstyled {
  border: 0; }

/* crit:end */
/* crit:start */
.u-bd {
  border: 1px solid #e6f1fa; }

.u-bd0 {
  border: 0; }

.u-bdt {
  border-top: 1px solid #e6f1fa; }

.u-bdt0 {
  border-top: 0; }

.u-bdl {
  border-left: 1px solid #e6f1fa; }

.u-bdl0 {
  border-left: 0; }

.u-bdr {
  border-right: 1px solid #e6f1fa; }

.u-bdr0 {
  border-right: 0; }

.u-bdb {
  border-bottom: 1px solid #e6f1fa; }

.u-bdb0 {
  border-bottom: 0; }

@media (min-width: 768px) {
  .u-bd-sm {
    border: 1px solid #e6f1fa; }
  .u-bd0-sm {
    border: 0; }
  .u-bdt-sm {
    border-top: 1px solid #e6f1fa; }
  .u-bdt0-sm {
    border-top: 0; }
  .u-bdl-sm {
    border-left: 1px solid #e6f1fa; }
  .u-bdl0-sm {
    border-left: 0; }
  .u-bdr-sm {
    border-right: 1px solid #e6f1fa; }
  .u-bdr0-sm {
    border-right: 0; }
  .u-bdb-sm {
    border-bottom: 1px solid #e6f1fa; }
  .u-bdb0-sm {
    border-bottom: 0; } }

@media (min-width: 992px) {
  .u-bd-md {
    border: 1px solid #e6f1fa; }
  .u-bd0-md {
    border: 0; }
  .u-bdt-md {
    border-top: 1px solid #e6f1fa; }
  .u-bdt0-md {
    border-top: 0; }
  .u-bdl-md {
    border-left: 1px solid #e6f1fa; }
  .u-bdl0-md {
    border-left: 0; }
  .u-bdr-md {
    border-right: 1px solid #e6f1fa; }
  .u-bdr0-md {
    border-right: 0; }
  .u-bdb-md {
    border-bottom: 1px solid #e6f1fa; }
  .u-bdb0-md {
    border-bottom: 0; } }

@media (min-width: 1200px) {
  .u-bd-lg {
    border: 1px solid #e6f1fa; }
  .u-bd0-lg {
    border: 0; }
  .u-bdt-lg {
    border-top: 1px solid #e6f1fa; }
  .u-bdt0-lg {
    border-top: 0; }
  .u-bdl-lg {
    border-left: 1px solid #e6f1fa; }
  .u-bdl0-lg {
    border-left: 0; }
  .u-bdr-lg {
    border-right: 1px solid #e6f1fa; }
  .u-bdr0-lg {
    border-right: 0; }
  .u-bdb-lg {
    border-bottom: 1px solid #e6f1fa; }
  .u-bdb0-lg {
    border-bottom: 0; } }

.u-bd2 {
  border-width: 2px; }

.u-bdl0-first:first-child {
  border-left: 0; }

.u-bdt0-first:first-child {
  border-top: 0; }

.u-bdr0-last:last-child {
  border-right: 0; }

.u-bdb0-last:last-child {
  border-bottom: 0; }

.u-bdra0 {
  border-radius: 0; }

.u-bdra50p {
  border-radius: 50%; }

.u-bdra4 {
  border-radius: 4px; }

.u-link-tdn:hover, .u-link-tdn:focus {
  text-decoration: none; }

.u-link-colorstay {
  color: inherit; }
  .u-link-colorstay:hover, .u-link-colorstay:focus {
    color: inherit; }

.u-focus-outline0:focus {
  outline: 0; }

.u-curp {
  cursor: pointer; }

.u-pin {
  pointer-events: none; }

.u-m0 {
  margin: 0px; }

.u-mtb0 {
  margin-top: 0px; }

.u-myb0 {
  margin-top: 0px; }

.u-mrb0 {
  margin-right: 0px; }

.u-mxb0 {
  margin-right: 0px; }

.u-mbb0 {
  margin-bottom: 0px; }

.u-myb0 {
  margin-bottom: 0px; }

.u-mlb0 {
  margin-left: 0px; }

.u-mxb0 {
  margin-left: 0px; }

.u-mtb1 {
  margin-top: 6px; }

.u-myb1 {
  margin-top: 6px; }

.u-mrb1 {
  margin-right: 6px; }

.u-mxb1 {
  margin-right: 6px; }

.u-mbb1 {
  margin-bottom: 6px; }

.u-myb1 {
  margin-bottom: 6px; }

.u-mlb1 {
  margin-left: 6px; }

.u-mxb1 {
  margin-left: 6px; }

.u-mtb2 {
  margin-top: 12px; }

.u-myb2 {
  margin-top: 12px; }

.u-mrb2 {
  margin-right: 12px; }

.u-mxb2 {
  margin-right: 12px; }

.u-mbb2 {
  margin-bottom: 12px; }

.u-myb2 {
  margin-bottom: 12px; }

.u-mlb2 {
  margin-left: 12px; }

.u-mxb2 {
  margin-left: 12px; }

.u-mtb3 {
  margin-top: 18px; }

.u-myb3 {
  margin-top: 18px; }

.u-mrb3 {
  margin-right: 18px; }

.u-mxb3 {
  margin-right: 18px; }

.u-mbb3 {
  margin-bottom: 18px; }

.u-myb3 {
  margin-bottom: 18px; }

.u-mlb3 {
  margin-left: 18px; }

.u-mxb3 {
  margin-left: 18px; }

.u-mtb4 {
  margin-top: 24px; }

.u-myb4 {
  margin-top: 24px; }

.u-mrb4 {
  margin-right: 24px; }

.u-mxb4 {
  margin-right: 24px; }

.u-mbb4 {
  margin-bottom: 24px; }

.u-myb4 {
  margin-bottom: 24px; }

.u-mlb4 {
  margin-left: 24px; }

.u-mxb4 {
  margin-left: 24px; }

.u-mtb5 {
  margin-top: 30px; }

.u-myb5 {
  margin-top: 30px; }

.u-mrb5 {
  margin-right: 30px; }

.u-mxb5 {
  margin-right: 30px; }

.u-mbb5 {
  margin-bottom: 30px; }

.u-myb5 {
  margin-bottom: 30px; }

.u-mlb5 {
  margin-left: 30px; }

.u-mxb5 {
  margin-left: 30px; }

.u-mtb6 {
  margin-top: 36px; }

.u-myb6 {
  margin-top: 36px; }

.u-mrb6 {
  margin-right: 36px; }

.u-mxb6 {
  margin-right: 36px; }

.u-mbb6 {
  margin-bottom: 36px; }

.u-myb6 {
  margin-bottom: 36px; }

.u-mlb6 {
  margin-left: 36px; }

.u-mxb6 {
  margin-left: 36px; }

.u-mtb7 {
  margin-top: 42px; }

.u-myb7 {
  margin-top: 42px; }

.u-mrb7 {
  margin-right: 42px; }

.u-mxb7 {
  margin-right: 42px; }

.u-mbb7 {
  margin-bottom: 42px; }

.u-myb7 {
  margin-bottom: 42px; }

.u-mlb7 {
  margin-left: 42px; }

.u-mxb7 {
  margin-left: 42px; }

.u-mtb8 {
  margin-top: 48px; }

.u-myb8 {
  margin-top: 48px; }

.u-mrb8 {
  margin-right: 48px; }

.u-mxb8 {
  margin-right: 48px; }

.u-mbb8 {
  margin-bottom: 48px; }

.u-myb8 {
  margin-bottom: 48px; }

.u-mlb8 {
  margin-left: 48px; }

.u-mxb8 {
  margin-left: 48px; }

.u-mtb9 {
  margin-top: 54px; }

.u-myb9 {
  margin-top: 54px; }

.u-mrb9 {
  margin-right: 54px; }

.u-mxb9 {
  margin-right: 54px; }

.u-mbb9 {
  margin-bottom: 54px; }

.u-myb9 {
  margin-bottom: 54px; }

.u-mlb9 {
  margin-left: 54px; }

.u-mxb9 {
  margin-left: 54px; }

.u-mtb10 {
  margin-top: 60px; }

.u-myb10 {
  margin-top: 60px; }

.u-mrb10 {
  margin-right: 60px; }

.u-mxb10 {
  margin-right: 60px; }

.u-mbb10 {
  margin-bottom: 60px; }

.u-myb10 {
  margin-bottom: 60px; }

.u-mlb10 {
  margin-left: 60px; }

.u-mxb10 {
  margin-left: 60px; }

.u-p0 {
  padding: 0px; }

.u-ptb0 {
  padding-top: 0px; }

.u-pyb0 {
  padding-top: 0px; }

.u-prb0 {
  padding-right: 0px; }

.u-pxb0 {
  padding-right: 0px; }

.u-pbb0 {
  padding-bottom: 0px; }

.u-pyb0 {
  padding-bottom: 0px; }

.u-plb0 {
  padding-left: 0px; }

.u-pxb0 {
  padding-left: 0px; }

.u-ptb1 {
  padding-top: 6px; }

.u-pyb1 {
  padding-top: 6px; }

.u-prb1 {
  padding-right: 6px; }

.u-pxb1 {
  padding-right: 6px; }

.u-pbb1 {
  padding-bottom: 6px; }

.u-pyb1 {
  padding-bottom: 6px; }

.u-plb1 {
  padding-left: 6px; }

.u-pxb1 {
  padding-left: 6px; }

.u-ptb2 {
  padding-top: 12px; }

.u-pyb2 {
  padding-top: 12px; }

.u-prb2 {
  padding-right: 12px; }

.u-pxb2 {
  padding-right: 12px; }

.u-pbb2 {
  padding-bottom: 12px; }

.u-pyb2 {
  padding-bottom: 12px; }

.u-plb2 {
  padding-left: 12px; }

.u-pxb2 {
  padding-left: 12px; }

.u-ptb3 {
  padding-top: 18px; }

.u-pyb3 {
  padding-top: 18px; }

.u-prb3 {
  padding-right: 18px; }

.u-pxb3 {
  padding-right: 18px; }

.u-pbb3 {
  padding-bottom: 18px; }

.u-pyb3 {
  padding-bottom: 18px; }

.u-plb3 {
  padding-left: 18px; }

.u-pxb3 {
  padding-left: 18px; }

.u-ptb4 {
  padding-top: 24px; }

.u-pyb4 {
  padding-top: 24px; }

.u-prb4 {
  padding-right: 24px; }

.u-pxb4 {
  padding-right: 24px; }

.u-pbb4 {
  padding-bottom: 24px; }

.u-pyb4 {
  padding-bottom: 24px; }

.u-plb4 {
  padding-left: 24px; }

.u-pxb4 {
  padding-left: 24px; }

.u-ptb5 {
  padding-top: 30px; }

.u-pyb5 {
  padding-top: 30px; }

.u-prb5 {
  padding-right: 30px; }

.u-pxb5 {
  padding-right: 30px; }

.u-pbb5 {
  padding-bottom: 30px; }

.u-pyb5 {
  padding-bottom: 30px; }

.u-plb5 {
  padding-left: 30px; }

.u-pxb5 {
  padding-left: 30px; }

.u-ptb6 {
  padding-top: 36px; }

.u-pyb6 {
  padding-top: 36px; }

.u-prb6 {
  padding-right: 36px; }

.u-pxb6 {
  padding-right: 36px; }

.u-pbb6 {
  padding-bottom: 36px; }

.u-pyb6 {
  padding-bottom: 36px; }

.u-plb6 {
  padding-left: 36px; }

.u-pxb6 {
  padding-left: 36px; }

.u-ptb7 {
  padding-top: 42px; }

.u-pyb7 {
  padding-top: 42px; }

.u-prb7 {
  padding-right: 42px; }

.u-pxb7 {
  padding-right: 42px; }

.u-pbb7 {
  padding-bottom: 42px; }

.u-pyb7 {
  padding-bottom: 42px; }

.u-plb7 {
  padding-left: 42px; }

.u-pxb7 {
  padding-left: 42px; }

.u-ptb8 {
  padding-top: 48px; }

.u-pyb8 {
  padding-top: 48px; }

.u-prb8 {
  padding-right: 48px; }

.u-pxb8 {
  padding-right: 48px; }

.u-pbb8 {
  padding-bottom: 48px; }

.u-pyb8 {
  padding-bottom: 48px; }

.u-plb8 {
  padding-left: 48px; }

.u-pxb8 {
  padding-left: 48px; }

.u-ptb9 {
  padding-top: 54px; }

.u-pyb9 {
  padding-top: 54px; }

.u-prb9 {
  padding-right: 54px; }

.u-pxb9 {
  padding-right: 54px; }

.u-pbb9 {
  padding-bottom: 54px; }

.u-pyb9 {
  padding-bottom: 54px; }

.u-plb9 {
  padding-left: 54px; }

.u-pxb9 {
  padding-left: 54px; }

.u-ptb10 {
  padding-top: 60px; }

.u-pyb10 {
  padding-top: 60px; }

.u-prb10 {
  padding-right: 60px; }

.u-pxb10 {
  padding-right: 60px; }

.u-pbb10 {
  padding-bottom: 60px; }

.u-pyb10 {
  padding-bottom: 60px; }

.u-plb10 {
  padding-left: 60px; }

.u-pxb10 {
  padding-left: 60px; }

.u-mra {
  margin-right: auto; }

.u-mxa {
  margin-right: auto; }

.u-mla {
  margin-left: auto; }

.u-mxa {
  margin-left: auto; }

@media (min-width: 768px) {
  .u-m0-sm {
    margin: 0px; }
  .u-mtb0-sm {
    margin-top: 0px; }
  .u-myb0-sm {
    margin-top: 0px; }
  .u-mrb0-sm {
    margin-right: 0px; }
  .u-mxb0-sm {
    margin-right: 0px; }
  .u-mbb0-sm {
    margin-bottom: 0px; }
  .u-myb0-sm {
    margin-bottom: 0px; }
  .u-mlb0-sm {
    margin-left: 0px; }
  .u-mxb0-sm {
    margin-left: 0px; }
  .u-mtb1-sm {
    margin-top: 6px; }
  .u-myb1-sm {
    margin-top: 6px; }
  .u-mrb1-sm {
    margin-right: 6px; }
  .u-mxb1-sm {
    margin-right: 6px; }
  .u-mbb1-sm {
    margin-bottom: 6px; }
  .u-myb1-sm {
    margin-bottom: 6px; }
  .u-mlb1-sm {
    margin-left: 6px; }
  .u-mxb1-sm {
    margin-left: 6px; }
  .u-mtb2-sm {
    margin-top: 12px; }
  .u-myb2-sm {
    margin-top: 12px; }
  .u-mrb2-sm {
    margin-right: 12px; }
  .u-mxb2-sm {
    margin-right: 12px; }
  .u-mbb2-sm {
    margin-bottom: 12px; }
  .u-myb2-sm {
    margin-bottom: 12px; }
  .u-mlb2-sm {
    margin-left: 12px; }
  .u-mxb2-sm {
    margin-left: 12px; }
  .u-mtb3-sm {
    margin-top: 18px; }
  .u-myb3-sm {
    margin-top: 18px; }
  .u-mrb3-sm {
    margin-right: 18px; }
  .u-mxb3-sm {
    margin-right: 18px; }
  .u-mbb3-sm {
    margin-bottom: 18px; }
  .u-myb3-sm {
    margin-bottom: 18px; }
  .u-mlb3-sm {
    margin-left: 18px; }
  .u-mxb3-sm {
    margin-left: 18px; }
  .u-mtb4-sm {
    margin-top: 24px; }
  .u-myb4-sm {
    margin-top: 24px; }
  .u-mrb4-sm {
    margin-right: 24px; }
  .u-mxb4-sm {
    margin-right: 24px; }
  .u-mbb4-sm {
    margin-bottom: 24px; }
  .u-myb4-sm {
    margin-bottom: 24px; }
  .u-mlb4-sm {
    margin-left: 24px; }
  .u-mxb4-sm {
    margin-left: 24px; }
  .u-mtb5-sm {
    margin-top: 30px; }
  .u-myb5-sm {
    margin-top: 30px; }
  .u-mrb5-sm {
    margin-right: 30px; }
  .u-mxb5-sm {
    margin-right: 30px; }
  .u-mbb5-sm {
    margin-bottom: 30px; }
  .u-myb5-sm {
    margin-bottom: 30px; }
  .u-mlb5-sm {
    margin-left: 30px; }
  .u-mxb5-sm {
    margin-left: 30px; }
  .u-mtb6-sm {
    margin-top: 36px; }
  .u-myb6-sm {
    margin-top: 36px; }
  .u-mrb6-sm {
    margin-right: 36px; }
  .u-mxb6-sm {
    margin-right: 36px; }
  .u-mbb6-sm {
    margin-bottom: 36px; }
  .u-myb6-sm {
    margin-bottom: 36px; }
  .u-mlb6-sm {
    margin-left: 36px; }
  .u-mxb6-sm {
    margin-left: 36px; }
  .u-mtb7-sm {
    margin-top: 42px; }
  .u-myb7-sm {
    margin-top: 42px; }
  .u-mrb7-sm {
    margin-right: 42px; }
  .u-mxb7-sm {
    margin-right: 42px; }
  .u-mbb7-sm {
    margin-bottom: 42px; }
  .u-myb7-sm {
    margin-bottom: 42px; }
  .u-mlb7-sm {
    margin-left: 42px; }
  .u-mxb7-sm {
    margin-left: 42px; }
  .u-mtb8-sm {
    margin-top: 48px; }
  .u-myb8-sm {
    margin-top: 48px; }
  .u-mrb8-sm {
    margin-right: 48px; }
  .u-mxb8-sm {
    margin-right: 48px; }
  .u-mbb8-sm {
    margin-bottom: 48px; }
  .u-myb8-sm {
    margin-bottom: 48px; }
  .u-mlb8-sm {
    margin-left: 48px; }
  .u-mxb8-sm {
    margin-left: 48px; }
  .u-mtb9-sm {
    margin-top: 54px; }
  .u-myb9-sm {
    margin-top: 54px; }
  .u-mrb9-sm {
    margin-right: 54px; }
  .u-mxb9-sm {
    margin-right: 54px; }
  .u-mbb9-sm {
    margin-bottom: 54px; }
  .u-myb9-sm {
    margin-bottom: 54px; }
  .u-mlb9-sm {
    margin-left: 54px; }
  .u-mxb9-sm {
    margin-left: 54px; }
  .u-mtb10-sm {
    margin-top: 60px; }
  .u-myb10-sm {
    margin-top: 60px; }
  .u-mrb10-sm {
    margin-right: 60px; }
  .u-mxb10-sm {
    margin-right: 60px; }
  .u-mbb10-sm {
    margin-bottom: 60px; }
  .u-myb10-sm {
    margin-bottom: 60px; }
  .u-mlb10-sm {
    margin-left: 60px; }
  .u-mxb10-sm {
    margin-left: 60px; }
  .u-p0-sm {
    padding: 0px; }
  .u-ptb0-sm {
    padding-top: 0px; }
  .u-pyb0-sm {
    padding-top: 0px; }
  .u-prb0-sm {
    padding-right: 0px; }
  .u-pxb0-sm {
    padding-right: 0px; }
  .u-pbb0-sm {
    padding-bottom: 0px; }
  .u-pyb0-sm {
    padding-bottom: 0px; }
  .u-plb0-sm {
    padding-left: 0px; }
  .u-pxb0-sm {
    padding-left: 0px; }
  .u-ptb1-sm {
    padding-top: 6px; }
  .u-pyb1-sm {
    padding-top: 6px; }
  .u-prb1-sm {
    padding-right: 6px; }
  .u-pxb1-sm {
    padding-right: 6px; }
  .u-pbb1-sm {
    padding-bottom: 6px; }
  .u-pyb1-sm {
    padding-bottom: 6px; }
  .u-plb1-sm {
    padding-left: 6px; }
  .u-pxb1-sm {
    padding-left: 6px; }
  .u-ptb2-sm {
    padding-top: 12px; }
  .u-pyb2-sm {
    padding-top: 12px; }
  .u-prb2-sm {
    padding-right: 12px; }
  .u-pxb2-sm {
    padding-right: 12px; }
  .u-pbb2-sm {
    padding-bottom: 12px; }
  .u-pyb2-sm {
    padding-bottom: 12px; }
  .u-plb2-sm {
    padding-left: 12px; }
  .u-pxb2-sm {
    padding-left: 12px; }
  .u-ptb3-sm {
    padding-top: 18px; }
  .u-pyb3-sm {
    padding-top: 18px; }
  .u-prb3-sm {
    padding-right: 18px; }
  .u-pxb3-sm {
    padding-right: 18px; }
  .u-pbb3-sm {
    padding-bottom: 18px; }
  .u-pyb3-sm {
    padding-bottom: 18px; }
  .u-plb3-sm {
    padding-left: 18px; }
  .u-pxb3-sm {
    padding-left: 18px; }
  .u-ptb4-sm {
    padding-top: 24px; }
  .u-pyb4-sm {
    padding-top: 24px; }
  .u-prb4-sm {
    padding-right: 24px; }
  .u-pxb4-sm {
    padding-right: 24px; }
  .u-pbb4-sm {
    padding-bottom: 24px; }
  .u-pyb4-sm {
    padding-bottom: 24px; }
  .u-plb4-sm {
    padding-left: 24px; }
  .u-pxb4-sm {
    padding-left: 24px; }
  .u-ptb5-sm {
    padding-top: 30px; }
  .u-pyb5-sm {
    padding-top: 30px; }
  .u-prb5-sm {
    padding-right: 30px; }
  .u-pxb5-sm {
    padding-right: 30px; }
  .u-pbb5-sm {
    padding-bottom: 30px; }
  .u-pyb5-sm {
    padding-bottom: 30px; }
  .u-plb5-sm {
    padding-left: 30px; }
  .u-pxb5-sm {
    padding-left: 30px; }
  .u-ptb6-sm {
    padding-top: 36px; }
  .u-pyb6-sm {
    padding-top: 36px; }
  .u-prb6-sm {
    padding-right: 36px; }
  .u-pxb6-sm {
    padding-right: 36px; }
  .u-pbb6-sm {
    padding-bottom: 36px; }
  .u-pyb6-sm {
    padding-bottom: 36px; }
  .u-plb6-sm {
    padding-left: 36px; }
  .u-pxb6-sm {
    padding-left: 36px; }
  .u-ptb7-sm {
    padding-top: 42px; }
  .u-pyb7-sm {
    padding-top: 42px; }
  .u-prb7-sm {
    padding-right: 42px; }
  .u-pxb7-sm {
    padding-right: 42px; }
  .u-pbb7-sm {
    padding-bottom: 42px; }
  .u-pyb7-sm {
    padding-bottom: 42px; }
  .u-plb7-sm {
    padding-left: 42px; }
  .u-pxb7-sm {
    padding-left: 42px; }
  .u-ptb8-sm {
    padding-top: 48px; }
  .u-pyb8-sm {
    padding-top: 48px; }
  .u-prb8-sm {
    padding-right: 48px; }
  .u-pxb8-sm {
    padding-right: 48px; }
  .u-pbb8-sm {
    padding-bottom: 48px; }
  .u-pyb8-sm {
    padding-bottom: 48px; }
  .u-plb8-sm {
    padding-left: 48px; }
  .u-pxb8-sm {
    padding-left: 48px; }
  .u-ptb9-sm {
    padding-top: 54px; }
  .u-pyb9-sm {
    padding-top: 54px; }
  .u-prb9-sm {
    padding-right: 54px; }
  .u-pxb9-sm {
    padding-right: 54px; }
  .u-pbb9-sm {
    padding-bottom: 54px; }
  .u-pyb9-sm {
    padding-bottom: 54px; }
  .u-plb9-sm {
    padding-left: 54px; }
  .u-pxb9-sm {
    padding-left: 54px; }
  .u-ptb10-sm {
    padding-top: 60px; }
  .u-pyb10-sm {
    padding-top: 60px; }
  .u-prb10-sm {
    padding-right: 60px; }
  .u-pxb10-sm {
    padding-right: 60px; }
  .u-pbb10-sm {
    padding-bottom: 60px; }
  .u-pyb10-sm {
    padding-bottom: 60px; }
  .u-plb10-sm {
    padding-left: 60px; }
  .u-pxb10-sm {
    padding-left: 60px; }
  .u-mra-sm {
    margin-right: auto; }
  .u-mxa-sm {
    margin-right: auto; }
  .u-mla-sm {
    margin-left: auto; }
  .u-mxa-sm {
    margin-left: auto; } }

@media (min-width: 992px) {
  .u-m0-md {
    margin: 0px; }
  .u-mtb0-md {
    margin-top: 0px; }
  .u-myb0-md {
    margin-top: 0px; }
  .u-mrb0-md {
    margin-right: 0px; }
  .u-mxb0-md {
    margin-right: 0px; }
  .u-mbb0-md {
    margin-bottom: 0px; }
  .u-myb0-md {
    margin-bottom: 0px; }
  .u-mlb0-md {
    margin-left: 0px; }
  .u-mxb0-md {
    margin-left: 0px; }
  .u-mtb1-md {
    margin-top: 6px; }
  .u-myb1-md {
    margin-top: 6px; }
  .u-mrb1-md {
    margin-right: 6px; }
  .u-mxb1-md {
    margin-right: 6px; }
  .u-mbb1-md {
    margin-bottom: 6px; }
  .u-myb1-md {
    margin-bottom: 6px; }
  .u-mlb1-md {
    margin-left: 6px; }
  .u-mxb1-md {
    margin-left: 6px; }
  .u-mtb2-md {
    margin-top: 12px; }
  .u-myb2-md {
    margin-top: 12px; }
  .u-mrb2-md {
    margin-right: 12px; }
  .u-mxb2-md {
    margin-right: 12px; }
  .u-mbb2-md {
    margin-bottom: 12px; }
  .u-myb2-md {
    margin-bottom: 12px; }
  .u-mlb2-md {
    margin-left: 12px; }
  .u-mxb2-md {
    margin-left: 12px; }
  .u-mtb3-md {
    margin-top: 18px; }
  .u-myb3-md {
    margin-top: 18px; }
  .u-mrb3-md {
    margin-right: 18px; }
  .u-mxb3-md {
    margin-right: 18px; }
  .u-mbb3-md {
    margin-bottom: 18px; }
  .u-myb3-md {
    margin-bottom: 18px; }
  .u-mlb3-md {
    margin-left: 18px; }
  .u-mxb3-md {
    margin-left: 18px; }
  .u-mtb4-md {
    margin-top: 24px; }
  .u-myb4-md {
    margin-top: 24px; }
  .u-mrb4-md {
    margin-right: 24px; }
  .u-mxb4-md {
    margin-right: 24px; }
  .u-mbb4-md {
    margin-bottom: 24px; }
  .u-myb4-md {
    margin-bottom: 24px; }
  .u-mlb4-md {
    margin-left: 24px; }
  .u-mxb4-md {
    margin-left: 24px; }
  .u-mtb5-md {
    margin-top: 30px; }
  .u-myb5-md {
    margin-top: 30px; }
  .u-mrb5-md {
    margin-right: 30px; }
  .u-mxb5-md {
    margin-right: 30px; }
  .u-mbb5-md {
    margin-bottom: 30px; }
  .u-myb5-md {
    margin-bottom: 30px; }
  .u-mlb5-md {
    margin-left: 30px; }
  .u-mxb5-md {
    margin-left: 30px; }
  .u-mtb6-md {
    margin-top: 36px; }
  .u-myb6-md {
    margin-top: 36px; }
  .u-mrb6-md {
    margin-right: 36px; }
  .u-mxb6-md {
    margin-right: 36px; }
  .u-mbb6-md {
    margin-bottom: 36px; }
  .u-myb6-md {
    margin-bottom: 36px; }
  .u-mlb6-md {
    margin-left: 36px; }
  .u-mxb6-md {
    margin-left: 36px; }
  .u-mtb7-md {
    margin-top: 42px; }
  .u-myb7-md {
    margin-top: 42px; }
  .u-mrb7-md {
    margin-right: 42px; }
  .u-mxb7-md {
    margin-right: 42px; }
  .u-mbb7-md {
    margin-bottom: 42px; }
  .u-myb7-md {
    margin-bottom: 42px; }
  .u-mlb7-md {
    margin-left: 42px; }
  .u-mxb7-md {
    margin-left: 42px; }
  .u-mtb8-md {
    margin-top: 48px; }
  .u-myb8-md {
    margin-top: 48px; }
  .u-mrb8-md {
    margin-right: 48px; }
  .u-mxb8-md {
    margin-right: 48px; }
  .u-mbb8-md {
    margin-bottom: 48px; }
  .u-myb8-md {
    margin-bottom: 48px; }
  .u-mlb8-md {
    margin-left: 48px; }
  .u-mxb8-md {
    margin-left: 48px; }
  .u-mtb9-md {
    margin-top: 54px; }
  .u-myb9-md {
    margin-top: 54px; }
  .u-mrb9-md {
    margin-right: 54px; }
  .u-mxb9-md {
    margin-right: 54px; }
  .u-mbb9-md {
    margin-bottom: 54px; }
  .u-myb9-md {
    margin-bottom: 54px; }
  .u-mlb9-md {
    margin-left: 54px; }
  .u-mxb9-md {
    margin-left: 54px; }
  .u-mtb10-md {
    margin-top: 60px; }
  .u-myb10-md {
    margin-top: 60px; }
  .u-mrb10-md {
    margin-right: 60px; }
  .u-mxb10-md {
    margin-right: 60px; }
  .u-mbb10-md {
    margin-bottom: 60px; }
  .u-myb10-md {
    margin-bottom: 60px; }
  .u-mlb10-md {
    margin-left: 60px; }
  .u-mxb10-md {
    margin-left: 60px; }
  .u-p0-md {
    padding: 0px; }
  .u-ptb0-md {
    padding-top: 0px; }
  .u-pyb0-md {
    padding-top: 0px; }
  .u-prb0-md {
    padding-right: 0px; }
  .u-pxb0-md {
    padding-right: 0px; }
  .u-pbb0-md {
    padding-bottom: 0px; }
  .u-pyb0-md {
    padding-bottom: 0px; }
  .u-plb0-md {
    padding-left: 0px; }
  .u-pxb0-md {
    padding-left: 0px; }
  .u-ptb1-md {
    padding-top: 6px; }
  .u-pyb1-md {
    padding-top: 6px; }
  .u-prb1-md {
    padding-right: 6px; }
  .u-pxb1-md {
    padding-right: 6px; }
  .u-pbb1-md {
    padding-bottom: 6px; }
  .u-pyb1-md {
    padding-bottom: 6px; }
  .u-plb1-md {
    padding-left: 6px; }
  .u-pxb1-md {
    padding-left: 6px; }
  .u-ptb2-md {
    padding-top: 12px; }
  .u-pyb2-md {
    padding-top: 12px; }
  .u-prb2-md {
    padding-right: 12px; }
  .u-pxb2-md {
    padding-right: 12px; }
  .u-pbb2-md {
    padding-bottom: 12px; }
  .u-pyb2-md {
    padding-bottom: 12px; }
  .u-plb2-md {
    padding-left: 12px; }
  .u-pxb2-md {
    padding-left: 12px; }
  .u-ptb3-md {
    padding-top: 18px; }
  .u-pyb3-md {
    padding-top: 18px; }
  .u-prb3-md {
    padding-right: 18px; }
  .u-pxb3-md {
    padding-right: 18px; }
  .u-pbb3-md {
    padding-bottom: 18px; }
  .u-pyb3-md {
    padding-bottom: 18px; }
  .u-plb3-md {
    padding-left: 18px; }
  .u-pxb3-md {
    padding-left: 18px; }
  .u-ptb4-md {
    padding-top: 24px; }
  .u-pyb4-md {
    padding-top: 24px; }
  .u-prb4-md {
    padding-right: 24px; }
  .u-pxb4-md {
    padding-right: 24px; }
  .u-pbb4-md {
    padding-bottom: 24px; }
  .u-pyb4-md {
    padding-bottom: 24px; }
  .u-plb4-md {
    padding-left: 24px; }
  .u-pxb4-md {
    padding-left: 24px; }
  .u-ptb5-md {
    padding-top: 30px; }
  .u-pyb5-md {
    padding-top: 30px; }
  .u-prb5-md {
    padding-right: 30px; }
  .u-pxb5-md {
    padding-right: 30px; }
  .u-pbb5-md {
    padding-bottom: 30px; }
  .u-pyb5-md {
    padding-bottom: 30px; }
  .u-plb5-md {
    padding-left: 30px; }
  .u-pxb5-md {
    padding-left: 30px; }
  .u-ptb6-md {
    padding-top: 36px; }
  .u-pyb6-md {
    padding-top: 36px; }
  .u-prb6-md {
    padding-right: 36px; }
  .u-pxb6-md {
    padding-right: 36px; }
  .u-pbb6-md {
    padding-bottom: 36px; }
  .u-pyb6-md {
    padding-bottom: 36px; }
  .u-plb6-md {
    padding-left: 36px; }
  .u-pxb6-md {
    padding-left: 36px; }
  .u-ptb7-md {
    padding-top: 42px; }
  .u-pyb7-md {
    padding-top: 42px; }
  .u-prb7-md {
    padding-right: 42px; }
  .u-pxb7-md {
    padding-right: 42px; }
  .u-pbb7-md {
    padding-bottom: 42px; }
  .u-pyb7-md {
    padding-bottom: 42px; }
  .u-plb7-md {
    padding-left: 42px; }
  .u-pxb7-md {
    padding-left: 42px; }
  .u-ptb8-md {
    padding-top: 48px; }
  .u-pyb8-md {
    padding-top: 48px; }
  .u-prb8-md {
    padding-right: 48px; }
  .u-pxb8-md {
    padding-right: 48px; }
  .u-pbb8-md {
    padding-bottom: 48px; }
  .u-pyb8-md {
    padding-bottom: 48px; }
  .u-plb8-md {
    padding-left: 48px; }
  .u-pxb8-md {
    padding-left: 48px; }
  .u-ptb9-md {
    padding-top: 54px; }
  .u-pyb9-md {
    padding-top: 54px; }
  .u-prb9-md {
    padding-right: 54px; }
  .u-pxb9-md {
    padding-right: 54px; }
  .u-pbb9-md {
    padding-bottom: 54px; }
  .u-pyb9-md {
    padding-bottom: 54px; }
  .u-plb9-md {
    padding-left: 54px; }
  .u-pxb9-md {
    padding-left: 54px; }
  .u-ptb10-md {
    padding-top: 60px; }
  .u-pyb10-md {
    padding-top: 60px; }
  .u-prb10-md {
    padding-right: 60px; }
  .u-pxb10-md {
    padding-right: 60px; }
  .u-pbb10-md {
    padding-bottom: 60px; }
  .u-pyb10-md {
    padding-bottom: 60px; }
  .u-plb10-md {
    padding-left: 60px; }
  .u-pxb10-md {
    padding-left: 60px; }
  .u-mra-md {
    margin-right: auto; }
  .u-mxa-md {
    margin-right: auto; }
  .u-mla-md {
    margin-left: auto; }
  .u-mxa-md {
    margin-left: auto; } }

@media (min-width: 1200px) {
  .u-m0-lg {
    margin: 0px; }
  .u-mtb0-lg {
    margin-top: 0px; }
  .u-myb0-lg {
    margin-top: 0px; }
  .u-mrb0-lg {
    margin-right: 0px; }
  .u-mxb0-lg {
    margin-right: 0px; }
  .u-mbb0-lg {
    margin-bottom: 0px; }
  .u-myb0-lg {
    margin-bottom: 0px; }
  .u-mlb0-lg {
    margin-left: 0px; }
  .u-mxb0-lg {
    margin-left: 0px; }
  .u-mtb1-lg {
    margin-top: 6px; }
  .u-myb1-lg {
    margin-top: 6px; }
  .u-mrb1-lg {
    margin-right: 6px; }
  .u-mxb1-lg {
    margin-right: 6px; }
  .u-mbb1-lg {
    margin-bottom: 6px; }
  .u-myb1-lg {
    margin-bottom: 6px; }
  .u-mlb1-lg {
    margin-left: 6px; }
  .u-mxb1-lg {
    margin-left: 6px; }
  .u-mtb2-lg {
    margin-top: 12px; }
  .u-myb2-lg {
    margin-top: 12px; }
  .u-mrb2-lg {
    margin-right: 12px; }
  .u-mxb2-lg {
    margin-right: 12px; }
  .u-mbb2-lg {
    margin-bottom: 12px; }
  .u-myb2-lg {
    margin-bottom: 12px; }
  .u-mlb2-lg {
    margin-left: 12px; }
  .u-mxb2-lg {
    margin-left: 12px; }
  .u-mtb3-lg {
    margin-top: 18px; }
  .u-myb3-lg {
    margin-top: 18px; }
  .u-mrb3-lg {
    margin-right: 18px; }
  .u-mxb3-lg {
    margin-right: 18px; }
  .u-mbb3-lg {
    margin-bottom: 18px; }
  .u-myb3-lg {
    margin-bottom: 18px; }
  .u-mlb3-lg {
    margin-left: 18px; }
  .u-mxb3-lg {
    margin-left: 18px; }
  .u-mtb4-lg {
    margin-top: 24px; }
  .u-myb4-lg {
    margin-top: 24px; }
  .u-mrb4-lg {
    margin-right: 24px; }
  .u-mxb4-lg {
    margin-right: 24px; }
  .u-mbb4-lg {
    margin-bottom: 24px; }
  .u-myb4-lg {
    margin-bottom: 24px; }
  .u-mlb4-lg {
    margin-left: 24px; }
  .u-mxb4-lg {
    margin-left: 24px; }
  .u-mtb5-lg {
    margin-top: 30px; }
  .u-myb5-lg {
    margin-top: 30px; }
  .u-mrb5-lg {
    margin-right: 30px; }
  .u-mxb5-lg {
    margin-right: 30px; }
  .u-mbb5-lg {
    margin-bottom: 30px; }
  .u-myb5-lg {
    margin-bottom: 30px; }
  .u-mlb5-lg {
    margin-left: 30px; }
  .u-mxb5-lg {
    margin-left: 30px; }
  .u-mtb6-lg {
    margin-top: 36px; }
  .u-myb6-lg {
    margin-top: 36px; }
  .u-mrb6-lg {
    margin-right: 36px; }
  .u-mxb6-lg {
    margin-right: 36px; }
  .u-mbb6-lg {
    margin-bottom: 36px; }
  .u-myb6-lg {
    margin-bottom: 36px; }
  .u-mlb6-lg {
    margin-left: 36px; }
  .u-mxb6-lg {
    margin-left: 36px; }
  .u-mtb7-lg {
    margin-top: 42px; }
  .u-myb7-lg {
    margin-top: 42px; }
  .u-mrb7-lg {
    margin-right: 42px; }
  .u-mxb7-lg {
    margin-right: 42px; }
  .u-mbb7-lg {
    margin-bottom: 42px; }
  .u-myb7-lg {
    margin-bottom: 42px; }
  .u-mlb7-lg {
    margin-left: 42px; }
  .u-mxb7-lg {
    margin-left: 42px; }
  .u-mtb8-lg {
    margin-top: 48px; }
  .u-myb8-lg {
    margin-top: 48px; }
  .u-mrb8-lg {
    margin-right: 48px; }
  .u-mxb8-lg {
    margin-right: 48px; }
  .u-mbb8-lg {
    margin-bottom: 48px; }
  .u-myb8-lg {
    margin-bottom: 48px; }
  .u-mlb8-lg {
    margin-left: 48px; }
  .u-mxb8-lg {
    margin-left: 48px; }
  .u-mtb9-lg {
    margin-top: 54px; }
  .u-myb9-lg {
    margin-top: 54px; }
  .u-mrb9-lg {
    margin-right: 54px; }
  .u-mxb9-lg {
    margin-right: 54px; }
  .u-mbb9-lg {
    margin-bottom: 54px; }
  .u-myb9-lg {
    margin-bottom: 54px; }
  .u-mlb9-lg {
    margin-left: 54px; }
  .u-mxb9-lg {
    margin-left: 54px; }
  .u-mtb10-lg {
    margin-top: 60px; }
  .u-myb10-lg {
    margin-top: 60px; }
  .u-mrb10-lg {
    margin-right: 60px; }
  .u-mxb10-lg {
    margin-right: 60px; }
  .u-mbb10-lg {
    margin-bottom: 60px; }
  .u-myb10-lg {
    margin-bottom: 60px; }
  .u-mlb10-lg {
    margin-left: 60px; }
  .u-mxb10-lg {
    margin-left: 60px; }
  .u-p0-lg {
    padding: 0px; }
  .u-ptb0-lg {
    padding-top: 0px; }
  .u-pyb0-lg {
    padding-top: 0px; }
  .u-prb0-lg {
    padding-right: 0px; }
  .u-pxb0-lg {
    padding-right: 0px; }
  .u-pbb0-lg {
    padding-bottom: 0px; }
  .u-pyb0-lg {
    padding-bottom: 0px; }
  .u-plb0-lg {
    padding-left: 0px; }
  .u-pxb0-lg {
    padding-left: 0px; }
  .u-ptb1-lg {
    padding-top: 6px; }
  .u-pyb1-lg {
    padding-top: 6px; }
  .u-prb1-lg {
    padding-right: 6px; }
  .u-pxb1-lg {
    padding-right: 6px; }
  .u-pbb1-lg {
    padding-bottom: 6px; }
  .u-pyb1-lg {
    padding-bottom: 6px; }
  .u-plb1-lg {
    padding-left: 6px; }
  .u-pxb1-lg {
    padding-left: 6px; }
  .u-ptb2-lg {
    padding-top: 12px; }
  .u-pyb2-lg {
    padding-top: 12px; }
  .u-prb2-lg {
    padding-right: 12px; }
  .u-pxb2-lg {
    padding-right: 12px; }
  .u-pbb2-lg {
    padding-bottom: 12px; }
  .u-pyb2-lg {
    padding-bottom: 12px; }
  .u-plb2-lg {
    padding-left: 12px; }
  .u-pxb2-lg {
    padding-left: 12px; }
  .u-ptb3-lg {
    padding-top: 18px; }
  .u-pyb3-lg {
    padding-top: 18px; }
  .u-prb3-lg {
    padding-right: 18px; }
  .u-pxb3-lg {
    padding-right: 18px; }
  .u-pbb3-lg {
    padding-bottom: 18px; }
  .u-pyb3-lg {
    padding-bottom: 18px; }
  .u-plb3-lg {
    padding-left: 18px; }
  .u-pxb3-lg {
    padding-left: 18px; }
  .u-ptb4-lg {
    padding-top: 24px; }
  .u-pyb4-lg {
    padding-top: 24px; }
  .u-prb4-lg {
    padding-right: 24px; }
  .u-pxb4-lg {
    padding-right: 24px; }
  .u-pbb4-lg {
    padding-bottom: 24px; }
  .u-pyb4-lg {
    padding-bottom: 24px; }
  .u-plb4-lg {
    padding-left: 24px; }
  .u-pxb4-lg {
    padding-left: 24px; }
  .u-ptb5-lg {
    padding-top: 30px; }
  .u-pyb5-lg {
    padding-top: 30px; }
  .u-prb5-lg {
    padding-right: 30px; }
  .u-pxb5-lg {
    padding-right: 30px; }
  .u-pbb5-lg {
    padding-bottom: 30px; }
  .u-pyb5-lg {
    padding-bottom: 30px; }
  .u-plb5-lg {
    padding-left: 30px; }
  .u-pxb5-lg {
    padding-left: 30px; }
  .u-ptb6-lg {
    padding-top: 36px; }
  .u-pyb6-lg {
    padding-top: 36px; }
  .u-prb6-lg {
    padding-right: 36px; }
  .u-pxb6-lg {
    padding-right: 36px; }
  .u-pbb6-lg {
    padding-bottom: 36px; }
  .u-pyb6-lg {
    padding-bottom: 36px; }
  .u-plb6-lg {
    padding-left: 36px; }
  .u-pxb6-lg {
    padding-left: 36px; }
  .u-ptb7-lg {
    padding-top: 42px; }
  .u-pyb7-lg {
    padding-top: 42px; }
  .u-prb7-lg {
    padding-right: 42px; }
  .u-pxb7-lg {
    padding-right: 42px; }
  .u-pbb7-lg {
    padding-bottom: 42px; }
  .u-pyb7-lg {
    padding-bottom: 42px; }
  .u-plb7-lg {
    padding-left: 42px; }
  .u-pxb7-lg {
    padding-left: 42px; }
  .u-ptb8-lg {
    padding-top: 48px; }
  .u-pyb8-lg {
    padding-top: 48px; }
  .u-prb8-lg {
    padding-right: 48px; }
  .u-pxb8-lg {
    padding-right: 48px; }
  .u-pbb8-lg {
    padding-bottom: 48px; }
  .u-pyb8-lg {
    padding-bottom: 48px; }
  .u-plb8-lg {
    padding-left: 48px; }
  .u-pxb8-lg {
    padding-left: 48px; }
  .u-ptb9-lg {
    padding-top: 54px; }
  .u-pyb9-lg {
    padding-top: 54px; }
  .u-prb9-lg {
    padding-right: 54px; }
  .u-pxb9-lg {
    padding-right: 54px; }
  .u-pbb9-lg {
    padding-bottom: 54px; }
  .u-pyb9-lg {
    padding-bottom: 54px; }
  .u-plb9-lg {
    padding-left: 54px; }
  .u-pxb9-lg {
    padding-left: 54px; }
  .u-ptb10-lg {
    padding-top: 60px; }
  .u-pyb10-lg {
    padding-top: 60px; }
  .u-prb10-lg {
    padding-right: 60px; }
  .u-pxb10-lg {
    padding-right: 60px; }
  .u-pbb10-lg {
    padding-bottom: 60px; }
  .u-pyb10-lg {
    padding-bottom: 60px; }
  .u-plb10-lg {
    padding-left: 60px; }
  .u-pxb10-lg {
    padding-left: 60px; }
  .u-mra-lg {
    margin-right: auto; }
  .u-mxa-lg {
    margin-right: auto; }
  .u-mla-lg {
    margin-left: auto; }
  .u-mxa-lg {
    margin-left: auto; } }

.u-mlb0-first:first-child {
  margin-left: 0; }

.u-mtb0-first:first-child {
  margin-top: 0; }

.u-mrb0-last:last-child {
  margin-right: 0; }

.u-mbb0-last:last-child {
  margin-bottom: 0; }

.u-h100p {
  height: 100%; }

.u-h100vh {
  height: 100vh; }

.u-ha {
  height: auto; }

.u-w100p {
  width: 100%; }

.u-w50p {
  width: 50%; }

.u-wa {
  width: auto; }
  @media (min-width: 768px) {
    .u-wa-sm {
      width: auto; } }

.u-mwa {
  min-width: auto; }

.u-maw50p {
  max-width: 50%; }

.u-maw100p {
  max-width: 100%; }

.u-mah100p {
  max-height: 100%; }

.u-mah100vh {
  max-height: 100vh; }

.u-mih100vh {
  min-height: 100vh; }

.u-bgc-t {
  background-color: transparent; }

.u-ovh {
  overflow: hidden; }

.u-ova {
  overflow: auto; }

.u-dtb {
  border-collapse: collapse; }
  .u-dtb--fixed {
    width: 100%;
    table-layout: fixed; }

.u-dn {
  display: none; }

.u-di {
  display: inline; }

.u-dib {
  display: inline-block; }

.u-db {
  display: block; }

.u-dtb {
  display: table; }

.u-dtr {
  display: table-row; }

.u-dtc {
  display: table-cell; }

.u-df {
  display: flex; }

.u-dif {
  display: inline-flex; }

.u-dg {
  display: grid; }

@media (min-width: 768px) {
  .u-dn-sm {
    display: none; }
  .u-di-sm {
    display: inline; }
  .u-dib-sm {
    display: inline-block; }
  .u-db-sm {
    display: block; }
  .u-dtb-sm {
    display: table; }
  .u-dtr-sm {
    display: table-row; }
  .u-dtc-sm {
    display: table-cell; }
  .u-df-sm {
    display: flex; }
  .u-dif-sm {
    display: inline-flex; }
  .u-dg-sm {
    display: grid; } }

@media (min-width: 992px) {
  .u-dn-md {
    display: none; }
  .u-di-md {
    display: inline; }
  .u-dib-md {
    display: inline-block; }
  .u-db-md {
    display: block; }
  .u-dtb-md {
    display: table; }
  .u-dtr-md {
    display: table-row; }
  .u-dtc-md {
    display: table-cell; }
  .u-df-md {
    display: flex; }
  .u-dif-md {
    display: inline-flex; }
  .u-dg-md {
    display: grid; } }

@media (min-width: 1200px) {
  .u-dn-lg {
    display: none; }
  .u-di-lg {
    display: inline; }
  .u-dib-lg {
    display: inline-block; }
  .u-db-lg {
    display: block; }
  .u-dtb-lg {
    display: table; }
  .u-dtr-lg {
    display: table-row; }
  .u-dtc-lg {
    display: table-cell; }
  .u-df-lg {
    display: flex; }
  .u-dif-lg {
    display: inline-flex; }
  .u-dg-lg {
    display: grid; } }

.u-vat {
  vertical-align: top; }

.u-vam {
  vertical-align: middle; }

.u-vab {
  vertical-align: bottom; }

.u-fll {
  float: left; }

.u-flr {
  float: right; }

.u-fln {
  float: none; }

.u-tar {
  text-align: right; }
  @media (min-width: 992px) {
    .u-tar-md {
      text-align: right; } }

.u-tac {
  text-align: center; }
  @media (min-width: 768px) {
    .u-tac-sm {
      text-align: center; } }

.u-tal {
  text-align: left; }
  @media (min-width: 768px) {
    .u-tal-sm {
      text-align: left; } }
  @media (min-width: 992px) {
    .u-tal-md {
      text-align: left; } }

.u-lh1 {
  line-height: 1; }

.u-lhb {
  line-height: 24px; }

.u-posa0 {
  position: absolute 0; }

.u-posf0 {
  position: fixed 0; }

.u-poss {
  position: static; }

.u-posr {
  position: relative; }

.u-posa {
  position: absolute; }

.u-posf {
  position: fixed; }

@media (min-width: 768px) {
  .u-poss-sm {
    position: static; }
  .u-posr-sm {
    position: relative; }
  .u-posa-sm {
    position: absolute; }
  .u-posf-sm {
    position: fixed; } }

@media (min-width: 992px) {
  .u-poss-md {
    position: static; }
  .u-posr-md {
    position: relative; }
  .u-posa-md {
    position: absolute; }
  .u-posf-md {
    position: fixed; } }

@media (min-width: 1200px) {
  .u-poss-lg {
    position: static; }
  .u-posr-lg {
    position: relative; }
  .u-posa-lg {
    position: absolute; }
  .u-posf-lg {
    position: fixed; } }

.u-l0 {
  left: 0; }

.u-la {
  left: auto; }

.u-r0 {
  right: 0; }

.u-ra {
  right: auto; }

.u-t0 {
  top: 0; }

.u-ta {
  top: auto; }

.u-b0 {
  bottom: 0; }

.u-ba {
  bottom: auto; }

.u-fw300 {
  font-weight: 300; }

.u-fw400 {
  font-weight: 400; }

.u-fw500 {
  font-weight: 500; }

.u-fw600 {
  font-weight: 600; }

.u-fw700 {
  font-weight: 700; }

.u-fsi {
  font-style: italic; }

.u-fz0 {
  font-size: 0; }

.u-fz12 {
  font-size: 12px; }

.u-fz13 {
  font-size: 13px; }

.u-fz14 {
  font-size: 14px; }

.u-fz15 {
  font-size: 15px; }

.u-fz16 {
  font-size: 16px; }

.u-ttu {
  text-transform: uppercase; }

.u-ttc {
  text-transform: capitalize; }

.u-tdu {
  text-decoration: underline; }

.u-tdn {
  text-decoration: none; }

.u-fx1 {
  flex: 1 1 auto; }
  .u-fx110 {
    flex: 1 1 0%; }

.u-fxg0 {
  flex-grow: 0; }

.u-fxg1 {
  flex-grow: 1; }

.u-fxs0 {
  flex-shrink: 0; }

.u-fxs1 {
  flex-shrink: 1; }

.u-fxb0 {
  flex-basis: 0%; }

.u-fxba {
  flex-basis: auto; }

.u-fxdc {
  flex-direction: column; }

.u-fxww {
  flex-wrap: wrap; }

.u-fxac {
  align-items: center; }

.u-fxsb {
  justify-content: space-between; }

.u-z-1 {
  z-index: -1; }

.u-z0 {
  z-index: 0; }

.u-z1 {
  z-index: 1; }

.u-z2 {
  z-index: 2; }

.u-z3 {
  z-index: 3; }

.u-z4 {
  z-index: 4; }

.u-z5 {
  z-index: 5; }

.u-o0 {
  opacity: 0; }

.u-o1 {
  opacity: 1; }

.u-o-lightmuted {
  opacity: 0.6; }

.u-bgc-primary {
  background-color: #00465e; }

.u-c-primary {
  color: #00465e; }

.u-bdc-primary {
  border-color: #00465e; }

.u-bgc-secondary {
  background-color: #5b5b5b; }

.u-c-secondary {
  color: #5b5b5b; }

.u-bdc-secondary {
  border-color: #5b5b5b; }

.u-bgc-text {
  background-color: #5b5b5b; }

.u-c-text {
  color: #5b5b5b; }

.u-bdc-text {
  border-color: #5b5b5b; }

.u-bgc-highlight {
  background-color: #f8f9fa; }

.u-c-highlight {
  color: #f8f9fa; }

.u-bdc-highlight {
  border-color: #f8f9fa; }

.u-bgc-gray500 {
  background-color: #6d6e71; }

.u-c-gray500 {
  color: #6d6e71; }

.u-bdc-gray500 {
  border-color: #6d6e71; }

.u-bgc-gray600 {
  background-color: #5b5b5b; }

.u-c-gray600 {
  color: #5b5b5b; }

.u-bdc-gray600 {
  border-color: #5b5b5b; }

.u-bgc-blue50 {
  background-color: #f4f8fb; }

.u-c-blue50 {
  color: #f4f8fb; }

.u-bdc-blue50 {
  border-color: #f4f8fb; }

.u-bgc-blue100 {
  background-color: #e6f1fa; }

.u-c-blue100 {
  color: #e6f1fa; }

.u-bdc-blue100 {
  border-color: #e6f1fa; }

.u-bgc-blue200 {
  background-color: #d3e5f5; }

.u-c-blue200 {
  color: #d3e5f5; }

.u-bdc-blue200 {
  border-color: #d3e5f5; }

.u-bgc-blue300 {
  background-color: #97cfe1; }

.u-c-blue300 {
  color: #97cfe1; }

.u-bdc-blue300 {
  border-color: #97cfe1; }

.u-bgc-blue700 {
  background-color: #00769d; }

.u-c-blue700 {
  color: #00769d; }

.u-bdc-blue700 {
  border-color: #00769d; }

.u-bgc-blue800 {
  background-color: #00465e; }

.u-c-blue800 {
  color: #00465e; }

.u-bdc-blue800 {
  border-color: #00465e; }

.u-bgc-red {
  background-color: #e4002b; }

.u-c-red {
  color: #e4002b; }

.u-bdc-red {
  border-color: #e4002b; }

.u-bgc-white {
  background-color: #fff; }

.u-c-white {
  color: #fff; }

.u-bdc-white {
  border-color: #fff; }

.u-bgc-green600 {
  background-color: #008531; }

.u-c-green600 {
  color: #008531; }

.u-bdc-green600 {
  border-color: #008531; }

.u-bdc-error {
  border-color: red; }

.u-c-inherit {
  color: inherit; }

.u-bsn {
  box-shadow: none; }
  .u-bsn-important {
    box-shadow: none !important; }

/* crit:end */
/* crit:start */
.u-bgimg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  page-break-inside: avoid; }
  @media print {
    .u-bgimg__backup {
      opacity: 1; } }

.u-embed {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
  padding-bottom: 100%; }
  .u-embed__item,
  .u-embed iframe,
  .u-embed embed,
  .u-embed object,
  .u-embed video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0; }
  .u-embed--16by9 {
    padding-bottom: 56.25%; }
  .u-embed--4by3 {
    padding-bottom: 75%; }

@media (max-width: 767.98px) {
  .u-table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .u-table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .u-table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .u-table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .u-table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .u-table-responsive-lg > .table-bordered {
      border: 0; } }

.u-table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .u-table-responsive > .table-bordered {
    border: 0; }

.u-vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* crit */ }

.u-absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* crit */ }

.u-horizontal-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  /* crit */ }

.u-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; }

@media (min-width: 768px) {
  .u-collapse-xsmax {
    display: block; }
    .u-collapse-xsmax-inline-block {
      display: inline-block; } }

@media (min-width: 992px) {
  .u-collapse-smmax {
    display: block; }
    .u-collapse-smmax-inline-block {
      display: inline-block; } }

@media (min-width: 1200px) {
  .u-collapse-mdmax {
    display: block; }
    .u-collapse-mdmax-inline-block {
      display: inline-block; } }

.u-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.u-icon-wrap {
  padding: 3px;
  border: 1px solid #d3e5f5;
  border-radius: 4px; }

/* crit:end */
.o-btn {
  position: relative;
  font-weight: 600;
  color: #fff;
  border-radius: 4px;
  padding: 8px 30px;
  border: 0;
  /* crit */
  transition: 0.3s; }
  .o-btn:hover, .o-btn:focus {
    color: #fff; }
    .o-btn:hover:before, .o-btn:focus:before {
      transform: none;
      opacity: 1; }
  .o-btn--primary {
    background-color: #00769d;
    color: #fff;
    border: 1px solid #00769d;
    /* crit */ }
    .o-btn--primary:before {
      background-color: #00a7e0; }
    .o-btn--primary:hover, .o-btn--primary:focus {
      background-color: #00a7e0;
      border: 1px solid #00a7e0; }
  .o-btn--secondary {
    background-color: transparent;
    color: #00769d;
    border: 1px solid #00769d;
    /* crit */ }
    .o-btn--secondary:before {
      background-color: #00769d; }
    .o-btn--secondary:hover, .o-btn--secondary:focus {
      background-color: #00769d;
      border-color: #00769d;
      color: white; }
    .o-btn--secondary.is-reverse {
      border-color: #00a7e0;
      color: white; }
  .o-btn--success {
    background-color: #008531;
    color: #fff;
    border: 1px solid #008531;
    /* crit */ }
    .o-btn--success:before {
      background-color: #00b140; }
    .o-btn--success:hover, .o-btn--success:focus {
      background-color: #00b140;
      border-color: #00b140;
      color: white;
      text-decoration: none; }
  .o-btn--flat {
    border-color: transparent;
    color: #00769d;
    background-color: transparent; }
    .o-btn--flat:hover, .o-btn--flat:focus {
      border-color: transparent;
      color: #00769d;
      background-color: transparent; }
  .o-btn--icon {
    width: 40px;
    height: 40px;
    text-align: center;
    padding: 8px;
    font-size: 18px;
    background: #d3e5f5;
    color: #00769d;
    /* crit */ }
    .o-btn--icon:focus, .o-btn--icon:hover {
      text-decoration: none;
      background: #00769d;
      color: #fff; }
  .o-btn--small {
    font-size: 12px;
    /* crit */ }
  .o-btn--capsule {
    border-radius: 20px;
    padding: 4px 10px;
    min-width: 175px;
    /* crit */ }
  .o-btn--white {
    background-color: #fff;
    color: #00769d;
    /* crit */ }
    .o-btn--white:hover, .o-btn--white:focus {
      background-color: #00769d;
      color: #fff; }
  .o-btn--orange {
    background-color: #ff8200;
    color: #fff;
    /* crit */ }
    .o-btn--orange:hover, .o-btn--orange:focus {
      background-color: #fe8200;
      color: #fff; }
  .o-btn.is-inline {
    min-width: auto; }
  .o-btn.is-fade {
    border-color: #97cfe1; }
  .o-btn.is-green {
    border-color: #28a745;
    color: #28a745; }
    .o-btn.is-green:hover, .o-btn.is-green:focus {
      border-color: #28a745;
      color: #28a745;
      background-color: #fff; }
  .o-btn.is-red {
    border-color: #e4002b;
    color: #e4002b; }
    .o-btn.is-red:hover, .o-btn.is-red:focus {
      border-color: #e4002b;
      color: #fff;
      background-color: #e4002b; }
  .o-btn.is-disabled {
    opacity: 0.2;
    /* crit */ }
    .o-btn.is-disabled:hover, .o-btn.is-disabled:focus {
      background-color: #fff;
      color: #00769d; }
  .o-btn--v-center .o-btn__container {
    display: inline-flex;
    align-self: center;
    vertical-align: middle; }
  .o-btn--thin {
    padding-left: 12px;
    padding-right: 12px; }
  .o-btn__icon {
    font-size: 1rem;
    margin-right: 6px; }
  .o-btn__spinner {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border: 0.2rem solid currentColor;
    border-right-color: transparent;
    vertical-align: middle;
    animation: spinner 1s linear infinite;
    border-radius: 50%;
    margin-right: 0.3rem; }

@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.o-card {
  border: 2px solid #e6f1fa; }
  .o-card__head {
    background-color: #e6f1fa;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px; }
    .o-card__head-title {
      font-size: 16px;
      font-weight: 700; }
    .o-card__head-subtitle {
      color: #00769d; }

.o-collapselist__item {
  border: 1px solid #d3e5f5;
  margin-bottom: 15px; }

.o-collapselist__title {
  position: relative; }
  .o-collapselist__title-link {
    display: block;
    font-size: 15px;
    line-height: 1.4666666667;
    /* crit */
    transition: .3s; }
    .o-collapselist__title-link:hover {
      text-decoration: none;
      color: #00769d; }
    .o-collapselist__title-link .g-icon-chev-down {
      transform: rotate(180deg);
      transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .o-collapselist__title-link.collapsed {
      /* crit */ }
      .o-collapselist__title-link.collapsed .g-icon-chev-down {
        transform: rotate(0deg); }

.o-collapselist__arrow {
  font-size: 20px; }

.o-collapselist__icon {
  position: absolute;
  top: 50%;
  right: 15px;
  font-size: 1rem;
  transform: translateY(-50%); }

@media (min-width: 992px) {
  .o-collapselist__media {
    width: 401px;
    margin-top: 0;
    margin-left: 60px; } }

.o-collapselist__media-inner {
  padding-bottom: 66.9833729216%; }

@media (min-width: 992px) {
  .o-collapselist__content {
    display: flex; } }

.o-collapselist__body {
  padding: 20px 17px; }

input:checked ~ .o-inputbox__box,
.is-selected .o-inputbox__box {
  opacity: 1;
  /* crit */ }

input:checked ~ .o-inputbox__text {
  color: #fff; }

.o-lang-select {
  display: flex;
  align-items: center;
  padding: 18px;
  border-radius: 4px;
  background: #e6f1fa;
  margin-bottom: 20px;
  color: #231f20;
  /*crit*/
  transition: .3s; }
  .o-lang-select-flag {
    width: 48px;
    margin-right: 16px;
    /*crit*/ }
    .o-lang-select-flagimg {
      width: 48px;
      height: 48px;
      overflow: hidden;
      border-radius: 50px;
      /*crit*/ }
  .o-lang-select-label {
    font-weight: 600; }
  .o-lang-select.is-disabled {
    border: 1px solid #e6f1fa;
    background: white;
    cursor: default;
    /*crit*/ }
    .o-lang-select.is-disabled:hover {
      border: 1px solid #e6f1fa;
      background: white; }
  .o-lang-select:hover {
    background: #d3e5f5;
    text-decoration: none; }

.o-midform {
  max-width: 840px; }

.o-notify {
  text-align: center;
  padding: 40px;
  font-size: 15px; }
  .o-notify-icon {
    width: 64px;
    height: 64px;
    border-radius: 100%;
    background: #d3e5f5;
    color: white;
    line-height: 64px;
    font-size: 45px;
    margin-bottom: 16px; }
  .o-notify-success {
    color: #00b140; }
    .o-notify-success .o-notify-icon {
      background: #00b140; }
  .o-notify-error {
    color: #e4002b; }
    .o-notify-error .o-notify-icon {
      background: #e4002b; }
  .o-notify-warning {
    color: #ff8200; }
    .o-notify-warning .o-notify-icon {
      background: #ff8200; }

.o-dotted {
  width: 9px;
  height: 9px;
  vertical-align: middle;
  border-radius: 10px;
  display: inline-block;
  background: #00465e; }
  .o-dotted.is-orange {
    background: #ff8200; }

.o-richtext > *:last-child:not(.lead):not(.b-lead) {
  margin-bottom: 0;
  padding-bottom: 0; }

.o-richtext h1, .o-richtext h2, .o-richtext h3, .o-richtext h4, .o-richtext h5, .o-richtext h6 {
  margin-bottom: 10px; }
  .o-richtext h1:not(:first-child), .o-richtext h2:not(:first-child), .o-richtext h3:not(:first-child), .o-richtext h4:not(:first-child), .o-richtext h5:not(:first-child), .o-richtext h6:not(:first-child) {
    margin-top: 10px; }

.o-richtext h2 {
  font-size: 18px;
  line-height: 1.5555555556; }

.o-richtext h3, .o-richtext h4, .o-richtext h5 {
  font-size: 16px;
  line-height: 1.625; }

.o-richtext img {
  margin: 0 auto 30px; }

.o-richtext__img {
  margin-bottom: 30px; }
  .o-richtext__img img {
    margin-bottom: 16px; }

.o-richtext ul, .o-richtext ol {
  padding-left: 0;
  margin-bottom: 30px; }

.o-richtext ul {
  list-style: none; }
  .o-richtext ul li {
    position: relative;
    padding-left: 13px; }
    .o-richtext ul li:before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      content: '';
      width: 3px;
      height: 3px;
      background-color: currentColor;
      border-radius: 50%;
      left: 0; }
    .o-richtext ul li[style*="list-style: none"]:before {
      content: none; }

.o-round-icon {
  width: 72px;
  height: 72px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 2.8rem;
  color: #FFFFFF;
  background-color: #00b140;
  border-radius: 50%; }

.o-scrollbar {
  max-height: 200px; }

/* crit:start */
.scroll-wrapper {
  overflow: hidden !important;
  padding: 0 !important;
  position: relative; }

.scroll-wrapper > .scroll-content {
  border: none !important;
  box-sizing: content-box !important;
  height: auto;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none !important;
  overflow: scroll !important;
  padding: 0;
  position: relative !important;
  top: 0;
  padding-right: 10px;
  width: auto !important; }

.scroll-wrapper > .scroll-content::-webkit-scrollbar {
  height: 0;
  width: 0; }

.scroll-element {
  display: none; }

.scroll-element, .scroll-element div {
  box-sizing: content-box; }

.scroll-element.scroll-x.scroll-scrollx_visible,
.scroll-element.scroll-y.scroll-scrolly_visible {
  display: block; }

.scroll-element .scroll-bar,
.scroll-element .scroll-arrow {
  cursor: default; }

.scroll-textarea {
  border: 1px solid #cccccc;
  border-top-color: #999999; }

.scroll-textarea > .scroll-content {
  overflow: hidden !important; }

.scroll-textarea > .scroll-content > textarea {
  border: none !important;
  box-sizing: border-box;
  height: 100% !important;
  margin: 0;
  max-height: none !important;
  max-width: none !important;
  overflow: scroll !important;
  outline: none;
  padding: 2px;
  position: relative !important;
  top: 0;
  width: 100% !important; }

.scroll-textarea > .scroll-content > textarea::-webkit-scrollbar {
  height: 0;
  width: 0; }

/*************** SIMPLE OUTER SCROLLBAR ***************/
.o-scrollbar > .scroll-element,
.o-scrollbar > .scroll-element div {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 1; }

.o-scrollbar > .scroll-element {
  background-color: #ffffff; }

.o-scrollbar > .scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%; }

.o-scrollbar > .scroll-element.scroll-x {
  bottom: 0;
  height: 12px;
  left: 0;
  width: 100%; }

.o-scrollbar > .scroll-element.scroll-y {
  height: 100%;
  right: 0;
  top: 0;
  width: 12px; }

.o-scrollbar > .scroll-element.scroll-x .scroll-element_outer {
  height: 8px;
  top: 2px; }

.o-scrollbar > .scroll-element.scroll-y .scroll-element_outer {
  left: 2px;
  width: 8px; }

.o-scrollbar > .scroll-element .scroll-element_outer {
  overflow: hidden; }

.o-scrollbar > .scroll-element .scroll-element_track {
  background-color: #eeeeee; }

.o-scrollbar > .scroll-element .scroll-element_outer,
.o-scrollbar > .scroll-element .scroll-element_track,
.o-scrollbar > .scroll-element .scroll-bar {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px; }

.o-scrollbar > .scroll-element .scroll-bar {
  background-color: #d9d9d9; }

.o-scrollbar > .scroll-element .scroll-bar:hover {
  background-color: #c2c2c2; }

.o-scrollbar > .scroll-element.scroll-draggable .scroll-bar {
  background-color: #919191; }

/* scrollbar height/width & offset from container borders */
.o-scrollbar > .scroll-content.scroll-scrolly_visible {
  left: -12px;
  margin-left: 12px; }

.o-scrollbar > .scroll-content.scroll-scrollx_visible {
  top: -12px;
  margin-top: 12px; }

.o-scrollbar > .scroll-element.scroll-x .scroll-bar {
  min-width: 10px; }

.o-scrollbar > .scroll-element.scroll-y .scroll-bar {
  min-height: 10px; }

/* update scrollbar offset if both scrolls are visible */
.o-scrollbar > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
  left: -14px; }

.o-scrollbar > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
  top: -14px; }

.o-scrollbar > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
  left: -14px; }

.o-scrollbar > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
  top: -14px; }

/* crit:end */
.o-selectpager .page-item .page-link {
  background: #e6f1fa;
  color: #00769d;
  min-height: 32px;
  padding: 0;
  width: 32px;
  font-size: 18px;
  text-align: center;
  line-height: inherit; }

.o-selectpager .page-item .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 80px; }
  .o-selectpager .page-item .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn).monthFilter--wide {
    width: auto;
    min-width: 145px; }

.o-selectpager .page-item .bootstrap-select .dropdown-toggle {
  font-size: 12px;
  background: #e6f1fa;
  color: #00769d;
  font-weight: 600;
  border-radius: 2px;
  border-color: #e6f1fa; }
  .o-selectpager .page-item .bootstrap-select .dropdown-toggle:after {
    background-color: transparent;
    font-size: 18px;
    margin-left: 0;
    margin-right: -4px; }

.o-selectpager .page-item .bootstrap-select .dropdown-menu li a {
  font-size: 12px; }
  .o-selectpager .page-item .bootstrap-select .dropdown-menu li a.active:hover {
    background-color: #00769d; }

.o-selectpager.is-white .page-item .page-link {
  background: white;
  border: 1px solid #d3e5f5; }
  .o-selectpager.is-white .page-item .page-link:hover {
    background-color: #00769d; }

.o-selectpager.is-white .page-item .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 140px; }

.o-selectpager.is-white .page-item .bootstrap-select .dropdown-toggle {
  background: white;
  border: 1px solid #d3e5f5; }

.o-selectpager.is-datepicker .bootstrap-select .dropdown-toggle:after {
  content: ""; }

/*crit:start*/
.o-sidemenu li > a {
  display: block;
  padding: 8px 10px;
  color: #97cfe1; }
  .o-sidemenu li > a:hover, .o-sidemenu li > a:focus {
    border-right: 4px solid #00a7e0;
    background: rgba(0, 118, 157, 0.2);
    text-decoration: none;
    font-weight: 700;
    color: white; }

.o-sidemenu li.active > a {
  border-right: 4px solid #00a7e0;
  background: rgba(0, 118, 157, 0.2);
  font-weight: 700;
  color: white; }

.o-sidemenu-tabs li {
  margin-bottom: 10px; }
  .o-sidemenu-tabs li > a {
    text-align: center;
    border-radius: 4px;
    border-bottom: 4px solid transparent;
    max-width: 108px;
    max-height: 94px;
    padding-top: 22px;
    padding-bottom: 22px;
    font-size: 12px;
    font-weight: 600; }
    .o-sidemenu-tabs li > a span {
      display: block;
      font-size: 34px; }
    .o-sidemenu-tabs li > a:hover, .o-sidemenu-tabs li > a:focus {
      border-right: 0;
      border-bottom: 4px solid #00a7e0;
      background: #00769d;
      text-decoration: none; }
  .o-sidemenu-tabs li.active > a {
    border-right: 0;
    border-bottom: 4px solid #00a7e0;
    background: #00769d;
    font-weight: normal; }

.o-sidemenu .g-icon-mobile {
  font-size: 1.6rem;
  margin-bottom: 0.5rem; }

/*crit:end*/
.o-step {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /*crit*/ }
  .o-step__item span {
    background: #d3e5f5;
    color: #00769d;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    line-height: 32px;
    display: inline-block;
    position: relative;
    z-index: 1;
    /*crit*/ }
  .o-step__item:not(:last-child) {
    margin-right: 90px;
    /*crit*/ }
    .o-step__item:not(:last-child):after {
      content: "";
      display: inline-block;
      width: 100px;
      height: 4px;
      background-color: #d3e5f5;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      /*crit*/
      z-index: 1; }
  .o-step__item.is-active span {
    background-color: #00a7e0;
    color: white; }

.o-table__row {
  border-bottom: 1px solid #e6f1fa; }
  .o-table__row-head {
    border-bottom: 1px solid #e6f1fa; }
    .o-table__row-head .u-dtc {
      padding: 12px 8px; }
  .o-table__row select {
    padding: 4px 1.2rem 4px .75rem;
    min-height: 32px; }
  .o-table__row .form-control {
    padding: 0 12px;
    min-height: 32px;
    height: calc(2.25rem - 4px); }
  .o-table__row .o-btn {
    padding: 6px 24px; }
  .o-table__row .u-dtc {
    padding: 8px; }
  .o-table__row-role {
    width: 136px; }
  .o-table__row-action {
    width: 135px; }

.o-tablelist {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: #e6f1fa;
  margin-top: 1rem;
  padding: 0.6rem;
  display: none; }
  .o-tablelist.is-active {
    display: block; }

.o-tablelist__item {
  background-color: #FFFFFF;
  padding: 5px 8px;
  display: flex;
  align-items: center; }
  .o-tablelist__item + .o-tablelist__item {
    border-top: 1px solid #e6f1fa; }

.o-tablelist__item__col {
  line-height: 1; }
  .o-tablelist__item__col:first-of-type {
    flex-basis: 75%; }
  .o-tablelist__item__col:last-of-type {
    margin-left: auto;
    font-size: 1rem; }

.o-tabs__nav-link {
  padding: 15px 18px;
  /* crit */
  transition: background-color .3s; }
  .o-tabs__nav-link.active {
    border-bottom-color: transparent;
    background-color: #eee; }
    .o-tabs__nav-link.active :after {
      content: none;
      /* crit */ }
    .o-tabs__nav-link.active:before {
      content: '';
      position: absolute;
      top: -1px;
      right: 0;
      left: 0;
      height: 3px;
      background-color: #000;
      display: block;
      /* crit */ }
    .o-tabs__nav-link.active:first-child:before {
      left: -1px;
      /* crit */ }
    .o-tabs__nav-link.active:first-child {
      border-left-color: #eee; }
    .o-tabs__nav-link.active:after {
      content: none; }
    .o-tabs__nav-link.active:last-child:after {
      content: '';
      position: absolute 0 -1px 0 auto;
      width: 1px;
      background-color: #eee; }
  @media (max-width: 767.98px) {
    .o-tabs__nav-link {
      padding-left: 20px;
      padding-right: 20px; } }

.o-tabs__pane:not(.active) {
  display: none; }

.o-tabs__media {
  width: 356px; }
  @media (max-width: 991.98px) {
    .o-tabs__media {
      width: auto;
      display: block;
      margin-bottom: 24px; } }

@media (max-width: 991.98px) {
  .o-tabs__text {
    display: block; } }

@media (min-width: 992px) {
  .o-tabs__media + .o-tabs__text {
    padding-left: 10%; } }

.o-tabs__img {
  padding-bottom: 66.9833729216%; }

.o-tabs__content {
  border-top: 0;
  background-color: #eee;
  /* crit */ }
  @media (max-width: 767.98px) {
    .o-tabs__content {
      padding-left: 20px;
      padding-right: 20px; } }
  @media (max-width: 991.98px) {
    .o-tabs__content-table {
      display: block; } }

.o-tabs__btn + .o-tabs__btn {
  margin-top: 24px; }

.o-tabs .nav-tabs {
  border-bottom: 2px solid #d3e5f5; }
  .o-tabs .nav-tabs .nav-link {
    border-color: #d3e5f5 #d3e5f5 #fff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: #e6f1fa;
    color: #00465e;
    font-weight: 700;
    font-size: 15px;
    padding-left: 23px;
    padding-right: 23px;
    margin-bottom: -3px;
    position: relative;
    padding-top: 12px;
    /*crit*/ }
    .o-tabs .nav-tabs .nav-link.active {
      background: white;
      /*crit*/ }
      .o-tabs .nav-tabs .nav-link.active:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: -1px;
        width: calc(100% + 2px);
        height: 4px;
        background-color: #00a7e0; }

/* ON/OFF Switch */
/* crit:start */
.onoffswitch {
  position: relative;
  width: 60px;
  display: inline-block;
  font-weight: 700; }
  .onoffswitch .onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
    border-radius: 30px;
    margin: 0; }
  .onoffswitch .onoffswitch-inner {
    width: 200%;
    margin-left: -100%;
    -webkit-transition: margin 0.15s ease-in-out;
    -o-transition: margin 0.15s ease-in-out;
    -moz-transition: margin 0.15s ease-in-out;
    transition: margin 0.15s ease-in-out; }
    .onoffswitch .onoffswitch-inner:before, .onoffswitch .onoffswitch-inner:after {
      float: left;
      width: 50%;
      height: 32px;
      padding: 0;
      line-height: 32px;
      font-size: 80%;
      color: #fff;
      font-weight: normal;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box; }
    .onoffswitch .onoffswitch-inner:before {
      font-family: 'g-icons' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      display: inline-block;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      padding-left: 10px;
      background-color: #00a7e0;
      color: #fff;
      font-weight: 700;
      font-size: 18px;
      line-height: 1.7; }
    .onoffswitch .onoffswitch-inner:after {
      font-family: 'g-icons' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      display: inline-block;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      padding-right: 8px;
      background-color: #e6f1fa;
      color: rgba(0, 118, 157, 0.5);
      text-align: right;
      font-weight: 700;
      font-size: 18px;
      line-height: 1.7; }
  .onoffswitch .onoffswitch-switch {
    width: 24px;
    height: 24px;
    margin: 0;
    background: #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    top: 4px;
    bottom: 0;
    right: 32px;
    -webkit-transition: right 0.15s ease-in-out;
    -o-transition: right 0.15s ease-in-out;
    -moz-transition: right 0.15s ease-in-out;
    transition: right 0.15s ease-in-out; }

.toggle-group {
  position: relative;
  height: 32px;
  top: 50%;
  left: 0;
  display: inline-block; }
  .toggle-group input[type=checkbox] {
    position: absolute;
    left: 20px; }
    .toggle-group input[type=checkbox]:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-inner {
      margin-left: 0; }
    .toggle-group input[type=checkbox]:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-switch {
      right: 5px;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.5); }
    .toggle-group input[type=checkbox]:focus ~ .onoffswitch {
      outline: thin dotted #333;
      outline: 0; }
  .toggle-group label {
    position: absolute;
    cursor: pointer;
    padding-left: 70px;
    display: inline-block;
    text-align: left;
    line-height: 32px;
    width: 100%;
    z-index: 1;
    height: 32px;
    font-weight: 200;
    margin-bottom: 0; }
  .toggle-group.is-small {
    height: 24px; }
    .toggle-group.is-small label {
      padding-left: 70px;
      line-height: 32px;
      height: 24px; }
    .toggle-group.is-small input[type=checkbox]:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-switch {
      right: 3px; }
    .toggle-group.is-small .onoffswitch {
      width: 48px; }
      .toggle-group.is-small .onoffswitch .onoffswitch-switch {
        width: 18px;
        height: 18px;
        top: 3px;
        right: 27px; }
      .toggle-group.is-small .onoffswitch .onoffswitch-inner:before, .toggle-group.is-small .onoffswitch .onoffswitch-inner:after {
        height: 24px;
        line-height: 24px; }
      .toggle-group.is-small .onoffswitch .onoffswitch-inner:before {
        padding-left: 8px;
        font-size: 12px;
        line-height: 2; }
      .toggle-group.is-small .onoffswitch .onoffswitch-inner:after {
        padding-right: 8px;
        font-size: 12px;
        line-height: 2; }

/* crit:end */
/* ==== Accessibility ===== */
.aural {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px; }
  .aural:focus {
    clip: rect(0, 0, 0, 0);
    font-size: 1em;
    height: auto;
    outline: thin dotted;
    position: static !important;
    width: auto;
    overflow: visible; }

.c-dynamicfield__collapse {
  margin-bottom: 15px; }

.c-dynamicfield .hide {
  display: none !important; }

.c-dynamicfield__item {
  background: white;
  padding: 3px 8px;
  border-bottom: 1px solid #e6f1fa; }
  .c-dynamicfield__item-table-data .u-dtc {
    padding: 4px 0; }
  .c-dynamicfield__item-table-data .u-dtc:first-child {
    width: 20%; }
  .c-dynamicfield__item-table-data .u-dtc:last-child {
    width: 10%;
    font-size: 16px; }
  .c-dynamicfield__item-table-edit {
    padding-top: 2px;
    padding-bottom: 2px; }
    .c-dynamicfield__item-table-edit .o-btn {
      margin-left: 5px; }
      .c-dynamicfield__item-table-edit .o-btn:first-of-type {
        margin-left: 15px; }
    .c-dynamicfield__item-table-edit input[type=text] {
      min-height: 32px;
      padding: 0 12px; }
    .c-dynamicfield__item-table-edit .o-btn {
      min-height: 32px; }
      .c-dynamicfield__item-table-edit .o-btn--primary {
        border-color: #00a7e0;
        background: #00a7e0;
        padding: 4px 24px; }
        .c-dynamicfield__item-table-edit .o-btn--primary:hover, .c-dynamicfield__item-table-edit .o-btn--primary:focus {
          background: #00769d;
          border-color: #00769d;
          color: #fff; }
      .c-dynamicfield__item-table-edit .o-btn--secondary {
        border-color: #d3e5f5;
        padding: 4px 24px; }
        .c-dynamicfield__item-table-edit .o-btn--secondary:hover, .c-dynamicfield__item-table-edit .o-btn--secondary:focus {
          background: #00a7e0; }

.c-styled-input__input {
  border: 1px solid #00769d !important;
  border-radius: 4px !important; }

.form-control option {
  background-color: #f4f8fb; }

.form-control[disabled], .form-control[readonly] {
  background-color: #f4f8fb;
  cursor: not-allowed; }
  .form-control[disabled]:focus, .form-control[readonly]:focus {
    box-shadow: inset 4px 4px 2px #e0ebf5; }

.form-control.input-validation-error + .field-validation-error {
  display: inline; }

.form-group input + .text-danger, .form-group select + .text-danger {
  display: none;
  /*crit*/ }

.form-group input.invalid + .text-danger, .form-group select.invalid + .text-danger {
  display: inline-block; }

.c-global-alert {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffe6d0;
  z-index: 10;
  align-items: center;
  /* crit */ }
  .c-global-alert.is-active {
    display: flex;
    height: 56px; }
    .c-global-alert.is-active ~ .c-section__top {
      top: 56px; }
    .c-global-alert.is-active ~ .c-section__wrapper {
      margin-top: 56px; }
      .c-global-alert.is-active ~ .c-section__wrapper .c-section__side {
        top: 56px; }
  .c-global-alert__icon {
    font-size: 1.4rem;
    margin-left: 0.4rem;
    margin-right: 0.8rem;
    line-height: 1;
    color: #ff8200; }
  .c-global-alert__container {
    padding: 0.5rem 0.8rem;
    display: flex;
    width: 100%; }
  .c-global-alert__description {
    margin-bottom: 0;
    flex: 1;
    display: inline-block;
    font-size: calc(15/16)rem;
    line-height: 1.7; }
  .c-global-alert__close {
    color: #ff8200; }
    .c-global-alert__close .g-icon-close {
      font-size: 1.4rem; }

.c-nav-main__indicator {
  height: 4px;
  transform: scaleY(0);
  /* crit */
  transform-origin: top center;
  transition: .3s; }
  .is-active .c-nav-main__indicator {
    transform: none; }
  .c-nav-main__link:hover ~ .c-nav-main__indicator,
  .c-nav-main__link:focus ~ .c-nav-main__indicator {
    transform: none; }

.c-notification {
  padding: 15px 20px;
  border-radius: 4px;
  margin-bottom: 1rem;
  min-height: 38px;
  position: relative;
  transform: translateY(-100%);
  transition: transform 0.1s linear;
  display: none; }
  .c-notification.is-active {
    transform: translateY(0);
    display: block; }
  .c-notification__message {
    font-size: 14px;
    display: block;
    margin-right: 24px; }
  .c-notification__close {
    position: absolute;
    top: 12px;
    right: 15px;
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    font-size: 1.4rem; }
  .c-notification__wrapper {
    overflow: hidden; }

.c-panel__upload, .c-panel__blue {
  background-color: #e6f1fa;
  padding: 15px 17px;
  /*crit*/ }

.c-panel__pagination {
  padding: 15px 17px;
  /*crit*/ }
  .c-panel__pagination .pagination {
    margin-bottom: 0;
    /*crit*/ }
  .c-panel__pagination .o-btn {
    min-width: 90px;
    text-align: left; }
    .c-panel__pagination .o-btn span {
      font-size: 20px; }
  .c-panel__pagination-prev {
    padding: 6px 15px 6px 6px; }
  .c-panel__pagination-next {
    padding: 6px 6px 6px 12px; }
    .c-panel__pagination-next span {
      float: right; }

.c-panel__head .bootstrap-select .dropdown-toggle {
  border: 1px solid #00769d;
  color: #00769d;
  font-size: 15px;
  background: #fff;
  border-radius: 4px;
  min-height: 40px; }

.c-panel__head .bootstrap-select .dropdown-item.active:hover,
.c-panel__head .bootstrap-select .dropdown-item:active:hover {
  background-color: #00769d; }

.c-panel__head .o-selectpager .bootstrap-select .dropdown-toggle {
  min-height: inherit; }

.c-panel__head .o-selectpager .pagination {
  margin-bottom: 0; }

.c-panel__search {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px; }
  .c-panel__search .input-group-search {
    min-width: 590px; }
    .c-panel__search .input-group-search input[type=text] {
      font-size: 16px;
      padding: 16px 23px;
      background: white; }
    .c-panel__search .input-group-search span {
      background: #00769d;
      color: white;
      font-size: 12px;
      border-radius: 20px;
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 24px;
      right: 16px;
      transition: .3s; }
      .c-panel__search .input-group-search span:hover {
        background-color: #00a7e0; }

.c-panel__sidenav {
  position: relative;
  min-height: 650px; }
  .c-panel__sidenav:before {
    border-right: 1px solid #d3e5f5;
    content: "";
    display: inline-block;
    position: absolute;
    right: 15px;
    height: 100%;
    top: 0; }

.pagination > li {
  font-size: 12px; }

.page-item {
  margin-right: 2px;
  margin-left: 2px;
  font-size: 12px;
  /*crit*/ }
  .page-item .page-link {
    border-radius: 2px;
    border: 0;
    color: #00769d;
    font-weight: 700;
    /*crit*/ }
    .page-item .page-link:hover, .page-item .page-link:focus {
      background-color: #00769d;
      color: white; }
    .page-item .page-link.is-disabled {
      cursor: default; }
      .page-item .page-link.is-disabled:hover, .page-item .page-link.is-disabled:focus {
        background-color: #e6f1fa;
        color: #00769d; }
  .page-item:first-child .page-link,
  .page-item:last-child .page-link {
    border-radius: 2px;
    /*crit*/ }
  .page-item.active .page-link {
    background-color: #00769d;
    /*crit*/ }

.c-section__login {
  /*crit*/ }
  .c-section__login-wrapper {
    background: #e6f1fa;
    max-width: 924px;
    margin: 50px auto;
    padding: 32px;
    border-radius: 4px;
    position: relative;
    /*crit*/ }
  .c-section__login-body {
    background: #fff; }
  .c-section__login-btnnav {
    position: absolute;
    top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    /*crit*/ }
    .c-section__login-btnnav.is-left {
      left: 32px; }
    .c-section__login-btnnav.is-right {
      right: 32px; }
  .c-section__login form .o-btn__spinner {
    display: none; }
  .c-section__login form.is-submitting .o-btn {
    opacity: 0.2; }
  .c-section__login form.is-submitting .o-btn__spinner {
    display: inline-block; }

.c-section__top {
  background-color: #00769d;
  color: white;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: space-between;
  /*crit*/
  z-index: 1001; }
  .c-section__top-country .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 184px;
    /*crit*/ }
  .c-section__top-country .btn-light:not(:disabled):not(.disabled).active, .c-section__top-country .btn-light:not(:disabled):not(.disabled):active, .c-section__top-country .show > .btn-light.dropdown-toggle {
    background: rgba(0, 70, 94, 0.5);
    /*crit*/ }
  .c-section__top-country .dropdown-toggle {
    border: 0;
    background: rgba(0, 70, 94, 0.5);
    /*crit*/ }
    .c-section__top-country .dropdown-toggle:after {
      color: white;
      opacity: 0.5;
      /*crit*/ }
  .c-section__top-country .selectpicker-label {
    color: white;
    font-weight: 700;
    /*crit*/ }
  .c-section__top-country .dropdown-menu .selectpicker-label {
    color: #5b5b5b;
    /*crit*/ }
  .c-section__top-country .dropdown-menu li {
    margin: 5px 0;
    /*crit*/ }
  .c-section__top-country .dropdown-menu li.selected .selectpicker-label {
    color: white; }
  .c-section__top-user .dropdown-toggle {
    color: white;
    font-size: 12px;
    font-weight: 700;
    /*crit*/ }
    .c-section__top-user .dropdown-toggle:after {
      vertical-align: middle;
      opacity: 0.5;
      /*crit*/ }
    .c-section__top-user .dropdown-toggle:focus {
      box-shadow: none; }
  .c-section__top-user .dropdown-item {
    font-size: 13px;
    /*crit*/ }
  .c-section__top-search .input-group-search {
    min-width: 574px;
    /*crit*/ }
    .c-section__top-search .input-group-search input[type=text] {
      background: rgba(0, 70, 94, 0.5);
      box-shadow: none;
      border: 0;
      color: #97cfe1;
      font-weight: 700;
      /*crit*/ }
      .c-section__top-search .input-group-search input[type=text]::placeholder {
        color: #97cfe1; }
    .c-section__top-search .input-group-search span {
      color: white;
      /*crit*/ }
  .c-section__top-sites {
    margin-left: -16px; }
    .c-section__top-sites .dropdown-toggle {
      color: white;
      font-size: 18px;
      font-weight: 700;
      text-align: left;
      padding-left: 16px;
      width: 235px;
      /*crit*/ }
      .c-section__top-sites .dropdown-toggle:after {
        vertical-align: middle;
        opacity: 0.5;
        /*crit*/ }
      .c-section__top-sites .dropdown-toggle:focus {
        box-shadow: none; }
    .c-section__top-sites .dropdown-wrapper {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
      width: calc(100% - 22px); }
    .c-section__top-sites .dropdown-item {
      font-size: 15px;
      min-width: 305px;
      /*crit*/ }
    .c-section__top-sites.show .dropdown-toggle {
      background: rgba(0, 70, 94, 0.5); }
  .c-section__top-right-item:not(:last-child) {
    border-right: 1px solid #00465e;
    padding-right: 16px; }

.c-section__wrapper {
  /*position: relative;
		z-index: 1;*/ }
  .c-section__wrapper.is-sidemenu-small .c-section__side {
    width: 130px;
    /*crit*/ }
  .c-section__wrapper.is-sidemenu-small .c-section__main {
    margin-left: 130px;
    /*crit*/ }
  .c-section__wrapper.is-sidemenu-small .c-section__footer {
    margin-left: 130px;
    width: calc(100% - 130px); }

.c-section__side {
  background: #00465e;
  color: white;
  width: 240px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  padding-top: 58px;
  overflow-y: auto;
  /*crit*/ }
  .c-section__side-centered {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 57px); }

.c-section__main {
  padding: 34px;
  margin-left: 240px;
  margin-top: 58px;
  padding-bottom: 80px;
  min-height: calc(100vh - 56px - 58px);
  height: auto;
  font-size: 12px;
  /*crit*/ }

.c-section__footer {
  margin-left: 240px;
  min-height: 56px;
  width: calc(100% - 240px);
  position: relative;
  bottom: 0;
  background-color: #fff;
  z-index: 2;
  /*crit*/ }
  .c-section__footer-main {
    padding: 8px 24px;
    border-top: 1px solid rgba(109, 110, 113, 0.3);
    justify-content: space-between;
    align-items: center;
    min-height: 56px;
    /*crit*/ }
  .c-section__footer-brand img {
    height: 32px;
    /*crit*/ }
  .c-section__footer-copy {
    color: #00465e; }
    .c-section__footer-copy > div {
      display: inline-block;
      font-size: 0.8rem;
      margin-left: 10px;
      color: #5b5b5b; }
      .c-section__footer-copy > div span {
        display: inline-block;
        margin-left: 3px; }

.c-section__login + .c-section__footer {
  margin-left: 0;
  width: 100%; }

@media print {
  * {
    -webkit-print-color-adjust: exact !important;
    /* Chrome, Safari */
    color-adjust: exact !important;
    /*Firefox*/ }
  .c-section__top {
    display: none; }
  .c-section__side {
    display: none; }
  .c-section__footer {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important; }
  .c-section__main {
    margin-top: 0 !important;
    margin-left: 0 !important; }
  #procedures-stats-app #nav-tab2 > .row, #trophons-stats-app #nav-tab2 > .row, #probes-stats-app #nav-tab2 > .row {
    display: block; }
    #procedures-stats-app #nav-tab2 > .row .col-7, #trophons-stats-app #nav-tab2 > .row .col-7, #probes-stats-app #nav-tab2 > .row .col-7 {
      flex: 1 0 auto;
      max-width: 100%;
      margin-top: 2rem; }
    #procedures-stats-app #nav-tab2 > .row .col-5, #trophons-stats-app #nav-tab2 > .row .col-5, #probes-stats-app #nav-tab2 > .row .col-5 {
      flex: 1 0 auto;
      max-width: 100%; }
      #procedures-stats-app #nav-tab2 > .row .col-5::after, #trophons-stats-app #nav-tab2 > .row .col-5::after, #probes-stats-app #nav-tab2 > .row .col-5::after {
        content: '';
        break-after: left;
        display: block; }
  #procedures-stats-app #nav-tab2 canvas, #trophons-stats-app #nav-tab2 canvas, #probes-stats-app #nav-tab2 canvas {
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    height: auto !important;
    width: 100% !important; }
  #operators-graph-app .chart-styler {
    min-height: 0 !important; }
  #operators-graph-app canvas {
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    height: auto !important;
    width: 100% !important; }
  #settings-app {
    margin-left: 0;
    margin-top: 0; }
    #settings-app > h2 {
      display: none; }
    #settings-app .c-panel__sidenav {
      display: none; }
      #settings-app .c-panel__sidenav + div {
        max-width: 100%;
        flex: 1; }
    #settings-app #tab-qr .col-md-7 {
      max-width: 100%;
      flex: 1; }
    #settings-app #tab-qr .o-btn {
      display: none; }
    #settings-app #tab-qr .u-tac > img {
      width: 280px; }
      #settings-app #tab-qr .u-tac > img:not(:first-of-type) {
        display: none; } }
