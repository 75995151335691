.o-lang{
	&-select{
		display: flex;
		align-items: center;
		padding:18px;
		border-radius: 4px;
		background: $blue-100;
		margin-bottom: 20px;
		color: $black;
		/*crit*/
		transition: .3s;
		&-flag{
			width: 48px;
			margin-right: 16px;
			/*crit*/
			&img{
				width: 48px;
				height: 48px;
				overflow: hidden;
				border-radius: 50px;
				/*crit*/
			}
		}
		&-label{
			font-weight: 600;
		}
		&.is-disabled{
			border:1px solid $blue-100;
			background: white;
			cursor: default;
			/*crit*/
			&:hover{
				border:1px solid $blue-100;
				background: white;
			}
		}
		&:hover{
			background: $blue-200;
			text-decoration: none;
		}
	}
}