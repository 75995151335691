.c-dynamicfield{
	&__addfield{
		
	}

	&__collapse{
		margin-bottom: 15px;
	}
	.hide{
		display: none !important;
	}

	&__item{
		background: white;
		padding: 3px 8px;
		border-bottom: 1px solid #e6f1fa;
		&-table{
			&-data{
				.u-dtc{
					padding: 4px 0;
				}
				.u-dtc:first-child{
					width: 20%;
				}
				.u-dtc:last-child{
					width: 10%;
					font-size: 16px;
				}
			}
			&-edit{
				padding-top: 2px;
				padding-bottom: 2px;
				.o-btn{
					margin-left: 5px;
					&:first-of-type {
						margin-left: 15px;
					}
				}
				
				input[type=text]{
					min-height: 32px;
					padding: 0 12px;
				}
				.o-btn{
					min-height: 32px;
					&--primary{
						border-color: $blue-500;
						background: $blue-500;
						padding:4px 24px;
						&:hover,
						&:focus{
							background:  $blue-700;
							border-color: $blue-700;
							color: $white;
						}
					}
					&--secondary{
						border-color: $blue-200;
						padding:4px 24px;
						&:hover,
						&:focus{
							background: $blue-500;
						}
					}
				}
			}
		}
	}

}
