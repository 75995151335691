// .o-table{
// 	tr>th{
// 		font-size: 13px;
// 		font-weight: 700;
// 		color: $black;
// 	}
// 	tr>td{
// 		border:1px solid $blue-100;
// 		padding: 8px;
// 		font-size: 12px;
// 	}

// 	&-lastright{

// 	}
// }

.o-table{
	&__row{
		border-bottom: 1px solid $blue-100;
		&-head{
			border-bottom: 1px solid $blue-100;
			.u-dtc{
				padding:12px 8px;
			}
		}
		select{
			padding: 4px 1.2rem 4px .75rem;
			min-height: 32px;
		}
		.form-control{
			padding: 0 12px;
			min-height: 32px;
			height: calc(2.25rem - 4px);
		}
		.o-btn{
			padding:6px 24px;
		}
		.u-dtc{
			padding:8px;
		}
		&-name{}
		&-role{
			width: 136px;
		}
		&-action{
			width: 135px;
		}
	}
}